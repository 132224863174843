<template>
    <table class="table tbl-condensed bordered stripped" style="font-size:12px">
        <thead>
            <tr>
                <th style="width:35%"></th>
                <th style="width:35%"></th>
                <th style="width:10%">Matrícula</th>
                <th style="width:10%">Cuota</th>
                <th style="width:10%"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="costo in costos" v-bind:key="costo">
                <td>{{costo.carrera}}</td>
                <td>{{costo.descripcion}}</td>
                <td style="text-align:center">${{costo.matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                <td style="text-align:center">${{costo.cuota.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import M from 'materialize-css'
export default {
    name: 'CostosFacturacion',
    data() {
        return {
            costos: []
        }
    },
    methods: {
        async init(tipo, codcil, codcar, codper)
        {
            this.costos = [];
            if(tipo == 'M')
            {
                await this.axios.get('/Maestria/Costos/' + codcil)
                .then(response => {
                    this.costos = response.data;
                });
            }
            else
            if(tipo == 'P')
            {
                await this.axios.get('/Preespecializacion/Costos/' + codcar + '/' + codper + '/' + codcil)
                .then(response => {
                    this.costos = response.data;
                });
            }
            else
            if(tipo == 'D')
            {
                await this.axios.get('/Diplomados/Costos')
                .then(response => {
                    this.costos = response.data;
                });
            }
            if(this.costos.length == 0)
            {
                M.toast({html: 'No existen costos definidos en el periodo actual.'});
            }
        }
    }
}
</script>