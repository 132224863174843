<template>
    <div class="row card-panel blue-grey lighten-4">
        <div class="col m12">
            <div class="col m3">
                <label>Buscar</label>
                <input type="text" v-model="search_term">
            </div>
            <div class="col m2">
                <label>Ciclo</label>
                <select class="browser-default" v-model="maut_codcil" @change="getPlanes()" :disabled="ciclos_filtro.length == 0">
                    <option v-for="ciclo in ciclos_filtro" :key="ciclo" :value="ciclo.cil_codigo">{{ ciclo.cic_nombre }} - {{ ciclo.cil_anio }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row card-panel blue-grey lighten-4">
        <div class="col m12" style="height: 300px; overflow-y: scroll;">
            <table class="table tbl-condensed tbl-bordered white" style="font-size: 12px;">
                <thead>
                    <tr>
                        <th style="width: 5%">ID</th>
                        <th style="width: 8%">Ciclo</th>
                        <th style="width: 8%">Carnet</th>
                        <th style="width: 25%">Estudiante</th>
                        <th style="width: 21%">Arancel</th>
                        <th style="width: 5%">Cuotas</th>
                        <th style="width: 5%">Activo</th>
                        <th style="width: 6%">Fecha de Creación</th>
                        <th style="width: 6%">Inactivado El</th>
                        <th style="width: 5%">Asociado Con</th>
                        <th style="width: 3%"></th>
                        <th style="width: 3%"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="_plan in planes_filter" :key="_plan">
                        <tr v-bind:class="{'red lighten-3': _plan.maut_codigo == plan.maut_codigo, 'red lighten-5': _plan.maut_codigo_asociado == plan.maut_codigo && update_grupo}">
                            <td class="center">{{ _plan.maut_codigo }}</td>
                            <td class="center">{{ _plan.cic_nombre }} - {{ _plan.cil_anio }}</td>
                            <td class="center">{{ _plan.per_carnet }}</td>
                            <td>{{ _plan.per_apellidos_nombres }}</td>
                            <td>{{ _plan.tmo_descripcion }}</td>
                            <td class="center">{{ _plan.maut_cuotas }}</td>
                            <td class="center"><label><input type="checkbox" class="filled-in" :checked="_plan.maut_estado"><span></span></label></td>
                            <td class="center">{{ _plan.maut_fecha }}</td>
                            <td class="center">{{ _plan.maut_fecha_inactivacion }}</td>
                            <td class="center">
                                <a v-if="_plan.maut_codigo_asociado != 0" href="#!" @click="editarGrupo(_plan.maut_codigo_asociado)">{{ _plan.maut_codigo_asociado }}</a>
                            </td>
                            <td class="center"><a href="#!" @click="editar(_plan.maut_codigo, _plan.maut_codigo_asociado != 0)"><i class="material-icons">edit</i></a></td>
                            <td class="center"><a href="#!" @click="eliminar(_plan.maut_codigo)"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row m0 p0">
        <div class="col m12">
            <button class="btn blue-ugb btn-small" @click="reset()">NUEVO PLAN DE PAGOS <i class="material-icons right">add_circle</i></button>
        </div>
    </div>
    <div class="row card-panel blue-grey lighten-5">
        <div class="col m12 white" style="padding: 10px">
            <div class="col m5" style="background-color: antiquewhite; border-radius: 5px;">
                <div style="margin: 10px;">
                    <div class="row">
                        <form method="post" @submit.prevent="buscarEstudiantes()">
                            <div class="col m3">
                                <label>Buscar</label>
                                <input type="text" v-model="buscar_estudiante" id="txt_buscar_estudiante">
                            </div>
                            <div class="col m7">
                                <label>Estudiantes</label>
                                <select class="browser-default" :disabled="estudiantes.length == 0" v-model="estudiante.per_codigo">
                                    <option v-for="estudiante in estudiantes" :key="estudiante" :value="estudiante.per_codigo">{{ estudiante.per_apellidos_nombres }} - {{estudiante.per_carnet}}</option>
                                </select>
                            </div>
                            <div class="col m2">
                                <label style="visibility: hidden;">null</label>
                                <button type="button" class="btn blue-ugb btn-small" @click="agregarEstudiante()">Agregar <i class="material-icons right"></i></button>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <small class="right">{{ estudiantes_seleccionados.length }} ESTUDIANTES AGREGADOS</small>
                        </div>
                        <div class="col m12" style="height: 200px; overflow-y: auto;">
                            <table class="table tbl-condensed tbl-bordered white" style="font-size: 12px;">
                                <tbody>
                                    <template  v-for="(estudiante, index) in estudiantes_seleccionados" :key="estudiante">
                                        <tr v-bind:class="{'red lighten-4': estudiante.per_activo && estudiante.per_error}">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ estudiante.per_carnet }}</td>
                                            <td>{{ estudiante.per_apellidos_nombres }}</td>
                                            <td class="center"><label><input type="checkbox" class="filled-in" v-model="estudiante.per_activo" /><span></span></label></td>
                                            <!-- <td>{{ estudiante.per_error }}</td> -->
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col m7">
                <div style="background-color: antiquewhite; padding: 10px; border-radius: 5px;">
                    <div class="row">
                        <form method="post">
                            <div class="col m2">
                                <label>ID</label>
                                <input type="text" disabled v-model="plan.maut_codigo">
                            </div>
                            <div class="col m2">
                                <label>Ciclo</label>
                                <select class="browser-default" v-model="plan.maut_codcil">
                                    <option value="0">Seleccione</option>
                                    <option v-for="ciclo in ciclos" :key="ciclo" :value="ciclo.cil_codigo">{{ ciclo.cic_nombre }} - {{ ciclo.cil_anio }}</option>
                                </select>
                            </div>
                            <div class="col m4">
                                <label>Arancel</label>
                                <select class="browser-default" v-model="plan.maut_codtmo">
                                    <option value="0">Seleccione</option>
                                    <option v-for="arancel in aranceles" :key="arancel" :value="arancel.tmo_codigo">{{ arancel.tmo_descripcion }}</option>
                                </select>
                            </div>
                            <div class="col m2">
                                <label>Valor Total</label>
                                <input type="text" v-model="plan.maut_valor" @keyup="setCuotas($event)">
                            </div>
                            <div class="col m2">
                                <label>Cuotas</label>
                                <input type="number" min="1" max="20" v-model="plan.maut_cuotas" @change="setCuotas($event)">
                            </div>
                        </form>
                    </div>

                    <div class="row">
                        <div class="col m5">
                            <table class="table tbl-condensed white" style="font-size: 12px">
                                <thead>
                                    <tr>
                                        <th style="width: 25%">Cuota</th>
                                        <th style="width: 20%">Monto</th>
                                        <th style="width: 45%">Fecha Límite</th>
                                        <th style="width: 10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cuota, index) in cuotas" :key="cuota">
                                        <td>Cuota {{ cuota.dmau_cuota }}</td>
                                        <td style="text-align: right"><input style="text-align: right;" type="text" v-model="cuota.dmau_monto"></td>
                                        <td><!-- <input type="text" style="text-align: right;" v-model="cuota.dmau_fecha_limite"> -->
                                            <datepicker v-model="cuota.dmau_fecha_limite" inputFormat="dd/MM/yyyy" />
                                        </td>
                                        <td><a v-if="index + 1 == cuotas.length" href="#!" @click="eliminarCuota(index)"><i class="material-icons">delete</i></a></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <th style="text-align: right">${{ dmau_total.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>DIFERENCIA</th>
                                        <th style="text-align: right">${{ dmau_diferencia.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col m12">
                            <button class="btn blue-ugb" v-show="permitirGuardar()" v-if="plan.maut_codigo == 0" @click="setPlan()">Guardar</button>
                            <button class="btn blue-ugb" v-show="permitirGuardar()" v-if="plan.maut_codigo != 0" @click="setPlan()">Actualizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import M from 'materialize-css'
    import Datepicker from 'vue3-datepicker'
    export default {
        components: {
            Datepicker
        },
        data()
        {
            return {
                planes: [],
                plan: {},
                estudiantes: [],
                estudiante: {},
                codper: 0,
                buscar_estudiante: '',
                maut_codper: 0,
                estudiantes_seleccionados: [],
                ciclos: [],
                aranceles: [],
                maut_cuotas: 0,
                cuotas: [],
                update_grupo: false,
                maut_codcil: 0,
                ciclos_filtro: [],
                selected: false,
                search_term: ''
            }
        },
        async mounted()
        {
            await this.getCiclosPlanes();
            await this.getPlanes();
            await this.getCiclos();
            await this.getAranceles();            
            this.reset();
        },
        methods: {
            reset()
            {
                this.plan = {};
                this.plan.maut_codcil = 0;
                this.plan.maut_codtmo = 0;
                this.plan.maut_valor = 0;
                this.plan.maut_cuotas = 1;
                this.plan.maut_codigo = 0;
                this.estudiantes_seleccionados = [];
                this.cuotas = [];
            },
            async getPlanes()
            {
                this.$root.startLoading();
                await this.axios.get('/PlanesPago/Ciclo/' + this.maut_codcil)
                .then(response => {
                    this.planes = response.data;
                });
                this.$root.stopLoading();
            },
            async buscarEstudiantes()
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes?search=' + this.buscar_estudiante)
                .then(response => {
                    this.estudiantes = response.data.records.filter(x=>x.pla_codigo > 0);
                    if(this.estudiantes.length > 0)
                    {
                        this.estudiante = this.estudiantes[0];
                    }
                });
                this.$root.stopLoading();
            },
            async getCiclosPlanes()
            {
                this.$root.startLoading();
                await this.axios.get('/PlanesPago/Ciclos')
                .then(response => {
                    this.ciclos_filtro = response.data;
                    if(this.ciclos_filtro.length > 0)
                    {
                        this.maut_codcil = this.ciclos_filtro[0].cil_codigo;
                    }
                });
                this.$root.stopLoading();
            },
            async getCiclos()
            {
                this.$root.startLoading();
                await this.axios.get('/Ciclos')
                .then(response => {
                    this.ciclos = response.data.filter(x=>(x.cic_codigo_int == 1 || x.cic_codigo_int == 2) && x.cil_codigo >= 382);
                });
                this.$root.stopLoading();
            },
            async getAranceles()
            {
                this.$root.startLoading();
                await this.axios.get('/Aranceles/Facturacion')
                .then(response => {
                    this.aranceles = response.data;
                });
                this.$root.stopLoading();
            },
            agregarEstudiante()
            {
                if(this.estudiantes.length == 0) return false;
                if(this.estudiantes_seleccionados.find(x=>x.per_codigo == this.estudiante.per_codigo) == undefined)
                {
                    var index = this.estudiantes.findIndex(x=>x.per_codigo == this.estudiante.per_codigo);
                    this.estudiantes_seleccionados.push({
                        per_codigo: this.estudiantes[index].per_codigo,
                        per_carnet: this.estudiantes[index].per_carnet,
                        per_apellidos_nombres: this.estudiantes[index].per_apellidos_nombres,
                        pla_codigo: this.estudiantes[index].pla_codigo,
                        per_activo: true,
                        per_error: false
                    });
                }
                else
                {
                    M.toast({html: 'El estudiante ya existe.'});
                }
                this.buscar_estudiante = '';
                document.querySelector('#txt_buscar_estudiante').focus();
            },
            setCuotas(event)
            {
                this.maut_cuotas = this.plan.maut_cuotas;
                if(this.maut_cuotas < this.cuotas.length)
                {
                    M.toast({html: 'Para disminuir las cuotas elimina elementos de la lista.'});
                    event.preventDefault();
                    this.maut_cuotas = this.cuotas.length;
                    this.plan.maut_cuotas = this.cuotas.length;
                    return;
                }
                var dmau_monto = Math.round(this.plan.maut_valor / this.maut_cuotas * 100) / 100;
                for(let i = 0; i <= this.maut_cuotas - 1; i++)
                {
                    if(this.cuotas.find(x=>x.dmau_cuota == i+1) == undefined)
                    {
                        this.cuotas.push({
                            dmau_cuota: i+1,
                            dmau_fecha_limite: new Date(),
                            dmau_monto: dmau_monto,
                            dmau_estado: true
                        });
                    }
                    else
                    {
                        this.cuotas[i].dmau_monto = dmau_monto;
                    }
                }
            },
            eliminarCuota(index)
            {
                this.cuotas.splice(index, 1);
                this.plan.maut_cuotas = this.cuotas.length;
                this.setCuotas();
            },
            permitirGuardar()
            {
                return this.cuotas.length > 0 && this.dmau_diferencia == 0 && this.estudiantes_seleccionados.filter(x=>x.per_activo).length > 0;
            },
            async setPlan()
            {
                var payload = {
                    estudiantes: this.estudiantes_seleccionados,
                    plan: this.plan,
                    cuotas: this.cuotas
                };
                this.$root.startLoading();
                if(this.estudiantes_seleccionados.filter(x=>x.per_activo).length > 1)
                {
                    this.update_grupo = true;
                }
                await this.axios.post('/PlanesPago?update_grupo=' + this.update_grupo, payload)
                .then(async response => {
                    this.plan.maut_codigo = response.data.maut_codigo;
                    await this.getPlanes();
                    M.toast({html: 'Los registros han sido actualizados.'});
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                    var estudiantes = error.response.data.data;
                    if(estudiantes != null)
                    {
                        this.estudiantes_seleccionados.forEach(x=>x.per_error = false);
                        for(let i = 0; i <= estudiantes.length - 1; i++)
                        {
                            var index_estudiante = this.estudiantes_seleccionados.findIndex(x=>x.per_codigo == estudiantes[i].per_codigo);
                            if(index_estudiante != -1)
                            {
                                this.estudiantes_seleccionados[index_estudiante].per_error = true;
                            }
                        }
                    }
                });
                this.$root.stopLoading();
            },
            async editar(id, asociado)
            {
                if(asociado)
                {
                    if(!confirm('Este registro se encuentra asociado con otros planes de pago, si lo edita de manera individual perderá la asociación ¿desea continuar?')) return false;
                }
                this.$root.startLoading();
                this.update_grupo = false;
                await this.axios.get('/PlanesPago/' + id)
                .then(response => {
                    this.plan = response.data.plan;
                    for(let i = 0; i <= response.data.cuotas.length - 1; i++)
                    {
                        response.data.cuotas[i].dmau_fecha_limite = this.$toDateFormat(response.data.cuotas[i].dmau_fecha_limite_string);
                    }
                    this.estudiantes_seleccionados = response.data.estudiantes;
                    this.cuotas = response.data.cuotas;
                });
                this.$root.stopLoading();
            },
            async editarGrupo(id)
            {
                this.update_grupo = true;
                this.$root.startLoading();
                await this.axios.get('/PlanesPago/' + id + '/Grupo')
                .then(response => {
                    this.plan = response.data.plan;
                    for(let i = 0; i <= response.data.cuotas.length - 1; i++)
                    {
                        response.data.cuotas[i].dmau_fecha_limite = this.$toDateFormat(response.data.cuotas[i].dmau_fecha_limite_string);
                    }
                    this.estudiantes_seleccionados = response.data.estudiantes;
                    this.cuotas = response.data.cuotas;
                });
                this.$root.stopLoading();
            },
            async eliminar(id)
            {
                if(!confirm('¿Desea eliminar este registro?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/PlanesPago/' + id)
                .then(async () => {
                    var index = this.planes.findIndex(x=>x.maut_codigo == id);
                    if(index != -1)
                    {
                        this.planes.splice(index, 1);
                    }
                    await this.getPlanes();
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            dmau_total()
            {
                var total = 0;
                for(let i = 0; i <= this.cuotas.length - 1; i++)
                {
                    total += parseFloat(this.cuotas[i].dmau_monto);
                }
                
                return Math.round(total * 100) / 100;
            },
            dmau_diferencia()
            {
                return isNaN(this.plan.maut_valor) ? 0:Math.round((this.plan.maut_valor - this.dmau_total) * 100) / 100;
            },
            planes_filter()
            {
                return this.planes.filter(x=>
                    x.per_carnet.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1 ||
                    x.per_apellidos_nombres.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1 ||
                    x.tmo_descripcion.toLowerCase().indexOf(this.search_term.toLowerCase()) != -1
                );
            }
        }
    }
</script>