<template>
    <div class="row">
        <div class="col m12 card-panel">
            <h5>Estudiantes con Aranceles a Cambiar</h5>
            <blockquote>
                Los siguientes son estudiantes que, por ejemplo, son de carrera virtual y pagaron aranceles presenciales y viceversa para su respectiva modificación del pago realizado.
            </blockquote>
            <div class="row card-panel">
                <div class="col m4">
                    <label>Buscar</label>
                    <input type="text" v-model="search" placeholder="Escriba un término de búsqueda." />
                </div>
            </div>
            <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px" v-show="estudiantes.length > 0">
                <thead>
                    <tr>
                        <th>Carnet</th>
                        <th>Estudiante</th>
                        <th>Modalidad</th>
                        <th>Carrera</th>                        
                        <th>Ciclo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(_estudiante, index) in estudiantes_filter" v-bind:key="_estudiante">
                        <td class="center">{{_estudiante.per_carnet}}</td>
                        <td>{{_estudiante.per_apellidos_nombres}}</td>
                        <td class="center">{{(_estudiante.car_virtual == 'P' ? 'Presencial':'Virtual')}}</td>
                        <td>{{_estudiante.car_nombre}}</td>
                        <td class="center">{{_estudiante.cic_nombre}} - {{_estudiante.cil_anio}}</td>
                        <td class="center"><a href="#!" @click="modalFactura(index, _estudiante.per_codigo)"><i class="material-icons">list</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-factura-corregir" style="min-width:75%">
        <div class="modal-content blue-ugb">
            <div class="row m0 p0">
                <div class="col m12 card-panel" style="font-size:12px">
                    <div class="row m0 p0">
                        <div class="col m2">
                            <label>Carnet</label>
                            <p>{{estudiante.per_carnet}}</p>
                        </div>
                        <div class="col m3">
                            <label>Estudiante</label>
                            <p>{{estudiante.per_apellidos_nombres}}</p>
                        </div>
                        <div class="col m3">
                            <label>Carrera</label>
                            <p>{{estudiante.car_nombre}}</p>
                        </div>
                        <div class="col m2">
                            <label>Ciclo</label>
                            <p>{{estudiante.cic_nombre}} - {{estudiante.cil_anio}}</p>
                        </div>
                        <div class="col m2">
                            <label>Modalidad</label>
                            <p>{{(estudiante.car_virtual == 'P' ? 'Presencial':'Virtual')}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th># Factura</th>
                                    <th>Ciclo</th>
                                    <th>Fecha</th>
                                    <th>Impreso</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(factura, index_factura) in facturas" v-bind:key="factura">
                                    <tr v-bind:class="{'blue-ugb white-text':(index_factura == index_factura_selected)}">
                                        <td class="center">{{factura.mov_tipo}}</td>
                                        <td class="center"><a href="#!" v-bind:class="{'white-text':(index_factura == index_factura_selected)}" @click="getFactura(index_factura, factura.mov_codigo)">{{factura.mov_recibo}}</a></td>
                                        <td class="center">{{factura.cic_nombre}} - {{factura.cil_anio}}</td>
                                        <td class="center">{{$dateFormat(new Date(factura.mov_fecha), 'dd/MM/yyyy')}}</td>
                                        <td class="center">{{factura.mov_impreso}}</td>
                                        <td class="center">{{factura.mov_estado}}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row blue-ugb m0 p0" v-if="factura.detalle != undefined">
                <div class="col m12 card-panel">
                    <div class="row">
                        <div class="col m6 green lighten-4" style="min-height:275px; border: dotted 1px black; padding:10px">
                            <table class="white table tbl-condensed tbl-bordered striped" style="font-size:12px" v-show="factura.detalle.length > 0">
                                <caption class="black darken-4 white-text">DETALLE ORIGINAL</caption>
                                <thead>
                                    <tr>
                                        <th style="width:55%">Arancel</th>
                                        <th style="width:15%">Valor</th>
                                        <th style="width:15%">IVA</th>
                                        <th style="width:15%">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="detalle in factura.detalle" v-bind:key="detalle">
                                        <td>{{detalle.tmo_descripcion}}</td>
                                        <td class="text-right">${{detalle.dmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                        <td class="text-right">${{detalle.dmo_iva.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                        <td class="text-right">${{(detalle.dmo_valor + detalle.dmo_iva).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                    </tr>
                                </tbody>
                                <tfoot class="black white-text">
                                    <tr>
                                        <th class="center">TOTAL</th>
                                        <th class="text-right">${{total_dmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                        <th class="text-right">${{total_dmo_iva.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                        <th class="text-right">${{(total_dmo_valor + total_dmo_iva).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="col m6 blue lighten-5" style="min-height:275px; border: dotted 1px black; padding:10px">
                            <div class="row m0 p0">
                                <div class="col m12">
                                    <form method="post" @submit.prevent="agregarDetalle()">
                                        <div class="col m6">
                                            <label>Arancel</label>
                                            <select v-model="dmo_codtmo" class="browser-default" @change="setValor()">
                                                <option value="0">Seleccione</option>
                                                <option v-show="arancel_visible(arancel)" v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                                            </select>
                                            <span class="helper-text">{{$validation(validations, 'dmo_codtmo')}}</span>
                                        </div>
                                        <div class="col m3">
                                            <label>Valor</label>
                                            <input type="text" v-model="dmo_valor">
                                            <span class="helper-text">{{$validation(validations, 'dmo_valor')}}</span>
                                        </div>
                                        <div class="col m3">
                                            <button type="submit" class="btn blue-ugb">AGREGAR</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <br>
                            <div class="row m0 p0">
                                <div class="col m12 m0 p0">
                                    <table class="white table tbl-condensed tbl-bordered striped" style="font-size:12px" v-show="detalle_nuevo.length > 0">
                                        <thead>
                                            <tr>                                                
                                                <th style="width:55%">Arancel</th>
                                                <th style="width:15%">Valor</th>
                                                <th style="width:15%">IVA</th>
                                                <th style="width:15%">Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(detalle, index) in detalle_nuevo" v-bind:key="detalle">
                                                <td>{{detalle.tmo_descripcion}}</td>
                                                <td class="text-right">${{detalle.dmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                                <td class="text-right">${{detalle.dmo_iva.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                                <td class="text-right">${{(detalle.dmo_valor + detalle.dmo_iva).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                                <td class="center"><a href="#!" @click="detalle_nuevo.splice(index, 1)"><i class="material-icons">delete</i></a></td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="black white-text">
                                            <tr>
                                                <th class="center">TOTAL</th>
                                                <th class="text-right">${{total_nuevo_dmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                                <th class="text-right">${{total_nuevo_dmo_iva.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                                <th class="text-right">${{(total_nuevo_dmo_valor + total_nuevo_dmo_iva).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                                <th></th>
                                            </tr>
                                        </tfoot>                  
                                    </table>
                                </div>                                                                 
                            </div>
                            <br>
                            <div class="row m0 p0">
                                <div class="col m12">
                                    <button type="button" @click="actualizarDetalle()" style="width: 100%" class="btn blue-ugb" :disabled="!allow_update">ACTUALIZAR <i class="material-icons right">save</i></button>
                                    <small>*El botón se habilitará hasta que el monto antiguo y el nuevo sean iguales</small>
                                </div>
                            </div>                        
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M  from 'materialize-css'
    export default {
        data()
        {
            return {
                estudiantes: [],
                estudiante: {},
                facturas: [],
                factura: {},
                modales: [],
                modal_factura: {},
                index_factura_selected: -1,
                detalle_nuevo: [],
                aranceles: [],
                dmo_codtmo: 0,
                dmo_valor: 0,
                iva: 0.13,
                validations: [],
                search: ''
            }
        },
        async mounted()
        {
            this.$root.startLoading();
            this.modales = M.Modal.init(document.querySelectorAll('.modal'), null);
            await this.axios.get('/Estudiantes/CorreccionArancel')
            .then(response => {
               this.estudiantes = response.data; 
            });
            this.$root.stopLoading();
        },
        methods: {
            async getFacturas(codper)
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/CorreccionArancel/' + codper)
                .then(response => {
                    this.facturas = response.data;
                });
                this.$root.stopLoading();
            },
            async getFactura(index, codmov)
            {
                this.$root.startLoading();
                this.index_factura_selected = index;
                await this.axios.get('/Movimientos/' + codmov)
                .then(response => {
                    this.factura = response.data;
                });
                this.$root.stopLoading();
            },
            async modalFactura(index, codper)
            {
                this.estudiante = this.estudiantes_filter[index];
                this.index_factura_selected = -1;
                this.detalle_nuevo = [];
                this.factura = {};
                this.reset();
                await this.getFacturas(codper);
                await this.getAranceles();
                this.modal_factura = M.Modal.getInstance(document.querySelector('#modal-factura-corregir'));
                this.modal_factura.open();
            },
            async getAranceles()
            {
                this.$root.startLoading();
                if(this.aranceles.length == 0)
                {
                    await this.axios.get('/Aranceles/Facturacion')
                    .then(response => {
                        this.aranceles = response.data.filter(x=>x.tmo_estado == 'A');
                    });
                }
                this.$root.stopLoading();
            },
            async agregarDetalle()
            {
                this.validations = [];
                var arancel = this.aranceles.find(x=>x.tmo_codigo == this.dmo_codtmo);
                if(arancel == undefined) return false;
                if(this.dmo_valor <= 0) return false;                

                var dmo_valor = parseFloat(arancel.tmo_exento == 'S' ? Math.round((this.dmo_valor / (1 + this.iva)) * 100) / 100:this.dmo_valor);
                var dmo_iva = parseFloat(this.dmo_valor - dmo_valor);
                var data = {
                    dmo_cantidad: 1,
                    dmo_codigo: 0,
                    dmo_codtmo: this.dmo_codtmo,
                    dmo_descuento: 0,
                    dmo_iva: dmo_iva || 0,
                    dmo_valor: dmo_valor || 0,
                    mat_codigo: '',
                    mat_nombre: '',
                    mes_nombre: '',
                    tmo_codigo: this.dmo_codtmo,
                    tmo_descripcion: arancel.tmo_descripcion
                };

                await this.axios.post('/Movimientos/ValidarDetalleMovimiento', data)
                .then(() => {
                    this.detalle_nuevo.push(data);
                    this.reset();
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                });                
            },
            reset()
            {
                this.dmo_valor = 0;
                this.dmo_codtmo = 0;
            },
            arancel_visible(arancel) {
                if(arancel.tmo_tipo_mov == 'N') return true;
                if((arancel.tmo_tipo_mov == 'M' || arancel.tmo_tipo_mov == 'C') && arancel.tmo_virtual == 'S' && this.estudiante.car_virtual == 'V') return true;
                if((arancel.tmo_tipo_mov == 'M' || arancel.tmo_tipo_mov == 'C') && arancel.tmo_virtual == 'N' && this.estudiante.car_virtual == 'P') return true;
            },
            async actualizarDetalle()
            {
                if(!confirm('¿Desea modificar el detalle de este documento?\nUna vez modificado no podrá revertir los cambios.'))
                {
                    return false;
                }
                this.$root.startLoading();
                await this.axios.put('/Movimientos/' + this.factura.mov_codigo + '/Detalle', this.detalle_nuevo)
                .then(async () => {
                    var index_factura = this.facturas.findIndex(x=>x.mov_codigo == this.factura.mov_codigo);
                    if(index_factura != -1)
                    {
                        this.facturas.splice(index_factura, 1);
                        this.factura.detalle = [];
                        if(this.facturas.length == 0)
                        {
                            var index_estudiante = this.estudiantes.findIndex(x=>x.per_codigo == this.factura.mov_codper);
                            if(index_estudiante != -1)
                            {
                                this.estudiantes.splice(index_estudiante, 1);
                                this.modal_factura.close();
                                M.toast({html: 'Todas las facturas corregidas.'});
                            }
                        }
                    }
                    this.detalle_nuevo = [];
                    //await this.getFactura(this.index_factura_selected, this.factura.mov_codigo);
                });
                this.$root.stopLoading();
            },
            setValor()
            {
                var detalle = this.factura.detalle.filter(x=>x.dmo_codtmo == this.dmo_codtmo);
                if(detalle.length == 0)
                {
                    this.dmo_valor = 0;
                }
                else
                {
                    this.dmo_valor = detalle[0].dmo_valor + detalle[0].dmo_iva;
                }
            }
        },
        computed: {
            total_dmo_iva()
            {
                if(this.factura.detalle == undefined) return 0;
                return this.factura.detalle.reduce((a, b) => a + b.dmo_iva, 0);
            },
            total_dmo_valor()
            {
                if(this.factura.detalle == undefined) return 0;
                return this.factura.detalle.reduce((a, b) => a + b.dmo_valor, 0);
            },
            total_nuevo_dmo_iva()
            {
                if(this.detalle_nuevo == undefined) return 0;
                return this.detalle_nuevo.reduce((a, b) => a + b.dmo_iva, 0);
            },
            total_nuevo_dmo_valor()
            {
                if(this.detalle_nuevo == undefined) return 0;
                return this.detalle_nuevo.reduce((a, b) => a + b.dmo_valor, 0);
            },
            allow_update()
            {
                return (Math.round((this.total_dmo_iva + this.total_dmo_valor) * 100) / 100) ==
               (Math.round((this.total_nuevo_dmo_valor + this.total_nuevo_dmo_iva) * 100) / 100)
            },
            estudiantes_filter()
            {
                return this.estudiantes.filter(x=>x.per_carnet.toLowerCase().indexOf(this.search.toLowerCase()) != -1 || x.per_apellidos_nombres.toLowerCase().indexOf(this.search.toLowerCase()) != -1);
            }
        }
    }
</script>