<template>
    <div class="row m0 p0" style="min-height:400px">
        <h5 class="card-panel white-text" style="background-color: #1c21d1;">Aranceles</h5>
        <div class="col m12">
            <div class="row m0 p0">
                <div class="col m12 card-panel">
                    <a href="#!" class="right" @click="nuevoArancel"><i style="font-size:26px" class="material-icons">add_circle</i></a>
                    <div class="col m4">
                        <label>Buscar</label> <input type="text" @keyup="buscar" v-model="search_term">
                    </div>
                    <div class="col m2">
                        <label>Estado</label>
                        <select class="browser-default" v-model="tmo_estado" @change="paginar(1)">
                            <option value="X">Todos</option>
                            <option value="A">Activos</option>
                            <option value="R">Inactivos</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <select class="browser-default" v-model="tmo_tipo" @change="paginar(1)">
                            <option value="X">Todos</option>
                            <option value="F">Facturación Estudiantes</option>
                            <option value="C">Facturación Clientes</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Grupo de Artículos</label>
                        <select class="browser-default" v-model="ItmsGrpCod" @change="paginar(1)">
                            <option value="-1">Todos</option>
                            <option value="0">Sin Grupo de Artículos</option>
                            <option value="R">Con Grupo de Artículos</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="col m12">
                    <button class="btn green darken-2" @click="actualizarTodo()">Actualizar Todo</button>
                </div>
            </div>
            <div class="row m0 p0" v-show="aranceles.length > 0">
                <div class="col m12 card-panel" style="overflow:auto">
                    <table class="tbl-condensed table bordered striped tbl-bordered" style="font-size:12px">
                        <thead>
                            <th>ID</th>
                            <th>Tipo</th>
                            <th>Descripción</th>
                            <th>Valor</th>
                            <th>IVA</th>
                            <th>Afecta Mat.</th>
                            <th>Es Colegiatura</th>
                            <th>Aplica Beca</th>
                            <th>Arancel Virtual</th>
                            <th>Genera Mora</th>
                            <th>Único</th>
                            <!-- <th>Tipo</th> -->
                            <!-- <th>Arncl. Req.</th>
                            <th>Arncl. Req.</th> -->
                            <!-- <th>Comp. de Cuota</th>
                            <th>Comp. de Matr.</th> -->
                            <!-- <th>Cant. Selec.</th> -->
                            <th>Cuenta</th>
                            <th>Cuenta<br>Facultad</th>
                            <th style="width: 5%"></th>
                            <!-- <th>Grupo</th> -->
                            <th>Estado</th>
                        </thead>
                        <tbody>
                            <tr v-for="arancel in aranceles_view" v-bind:key="arancel">
                                <td class="center">{{arancel.tmo_codigo}}</td>
                                <td>{{ arancel.tmo_tipo == "F" ? 'Estudiantes':'Clientes' }}</td>
                                <td><a href="#!" @click="editar(arancel.tmo_codigo)">{{arancel.tmo_descripcion}}</a></td>
                                <td class="center">${{arancel.tmo_valor}}</td>
                                <td class="center">{{arancel.tmo_exento}}</td>
                                <td class="center">{{arancel.tmo_afecta_materia}}</td>
                                <td class="center">{{arancel.tmo_talonarios}}</td>
                                <td class="center">{{arancel.tmo_beca}}</td>
                                <td class="center">{{arancel.tmo_virtual}}</td>
                                <td class="center">{{arancel.tmo_genera_mora}}</td>
                                <td class="center">{{arancel.tmo_unico}}</td>
                                <!-- <td class="center">{{arancel.tmo_tipo_mov}}</td> -->
                                <!-- <td class="center">{{arancel.tmo_codtmo_req}}</td>
                                <td class="center">{{arancel.tmo_codtmo_req_v}}</td> -->
                                <!-- <td class="center">{{arancel.tmo_comp_cuota}}</td>
                                <td class="center">{{arancel.tmo_comp_matricula}}</td> -->
                                <!-- <td class="center">{{arancel.tmo_cantidad}}</td> -->
                                <td class="center">{{arancel.tmo_cuenta}}</td>
                                <td class="center"><a href="#!" @click="cuentasFacultad(arancel.tmo_codigo)"><i class="material-icons">account_balance</i></a></td>
                                <td class="center">
                                    {{arancel.ecar_tipo == 'N' ? '':arancel.ecar_tipo == 'E' ? 'Evento':'Curso'}}
                                </td>
                                <!-- <td class="center">{{arancel.itmsGrpCod}}</td> -->
                                <td class="center">
                                    <select class="browser-default" v-model="arancel.tmo_estado" @change="cambiarEstado($event, arancel.tmo_codigo)">
                                        <option value="A">Activo</option>
                                        <option value="R">Inactivo</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col m12">
                        <ul class="pagination">
                            <li v-if="current_page > 1"><a href="#!" @click="paginar(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
                            <template v-for="page in total_pages" v-bind:key="page">
                                <li v-if="page < current_page + 10 && page > current_page - 10" v-bind:class="{active: page == current_page}"><a @click="paginar(page)" href="#!">{{page}}</a></li>
                            </template>
                            <li v-if="current_page < total_pages"><a href="#!" @click="paginar(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="modal-editar-arancel" style="width:90%">
        <div class="modal-content">
            <EditarArancel ref="editarArancel" v-bind:aranceles="aranceles" />
        </div>
    </div>

    <div class="modal" id="modal-editar-movimientos-cuenta">
        <div class="modal-content">
            <div class="row m0 p0">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px;">
                    <thead>
                        <th style="width: 5%">Código</th>
                        <th style="width: 35%">Arancel</th>
                        <th style="width: 40%">Facultad</th>
                        <th style="width: 15%">Cuenta</th>
                        <th style="width: 5%"></th>
                    </thead>
                    <tbody>
                        <tr v-for="(cuenta, index) in cuentas_facultad" :key="cuenta">
                            <td>{{ cuenta.moc_codigo }}</td>
                            <td>{{ cuenta.tmo_codigo }} - {{ cuenta.tmo_descripcion }}</td>
                            <td>{{ cuenta.fac_nombre }}</td>
                            <td>
                                <span v-show="!cuenta.moc_editable">{{ cuenta.moc_cuenta }}</span>
                                <input type="text" v-show="cuenta.moc_editable" class="browser-default" v-model="cuenta.moc_cuenta">
                            </td>
                            <td>  
                                <a href="#!" v-show="cuenta.moc_editable" @click="guardarCuentaFacultad(index)"><i class="material-icons">save</i></a>    
                                <a href="#!" v-show="!cuenta.moc_editable" @click="editarCuentaFacultad(index)"><i class="material-icons">edit</i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row m0 p0">
                <br>
                <button class="btn btn-small right blue-ugb red darken-3 modal-close">Cerrar</button>
            </div>
        </div>
    </div>
</template>

<script>
import EditarArancel from '../components/EditarArancel'
import M from 'materialize-css'
    export default {
        name: 'Aranceles',
        components: {
            EditarArancel
        },
        data() {
            return {
                aranceles: [],
                aranceles_view: [],
                arancel: {},
                modal_instances: [],
                modal_arancel: {},
                modal_cuentas_facultades: {},
                records: 15,
                total_pages: 0,
                current_page: 1,
                search_term: '',
                tmo_estado: 'A',
                ItmsGrpCod: -1,
                tmo_tipo: 'X',
                cuentas_facultad: []
            }
        },
        async mounted()
        {
            this.$root.startLoading();
            await this.getAranceles();
            this.$root.stopLoading();
            this.modal_instances = M.Modal.init(document.querySelectorAll('.modal'), null);
        },
        methods: {
            async getAranceles()
            {
                await this.axios.get('/Aranceles')
                .then(response => {
                    this.aranceles = response.data;
                    this.aranceles_view = response.data;
                });
                this.paginar(1);
            },
            async cambiarEstado(event, codtmo)
            {
                await this.axios.patch('/Aranceles/' + codtmo + '/' + event.target.value)
                .then(() => {
                    
                });
            },
            async editar(codtmo)
            {
                this.$root.startLoading();
                await this.$refs.editarArancel.init(codtmo);
                this.modal_arancel = M.Modal.getInstance(document.querySelector('#modal-editar-arancel'));
                this.modal_arancel.open();
                this.$root.stopLoading();
            },
            paginar(page)
            {
                this.current_page = page;
                var result = [];
                if(this.ItmsGrpCod == -1)
                {
                    result = this.aranceles.filter(x=>x.tmo_tipo == (this.tmo_tipo == 'X' ? x.tmo_tipo:this.tmo_tipo) && x.tmo_descripcion.toLowerCase().indexOf(this.search_term.toLowerCase()) > -1 && x.tmo_estado == (this.tmo_estado == 'X' ? x.tmo_estado:this.tmo_estado));
                }
                else
                {
                    if(this.ItmsGrpCod == 0)
                    {
                        result = this.aranceles.filter(x=>x.tmo_tipo == (this.tmo_tipo == 'X' ? x.tmo_tipo:this.tmo_tipo) && x.itmsGrpCod == 0 && x.tmo_descripcion.toLowerCase().indexOf(this.search_term.toLowerCase()) > -1 && x.tmo_estado == (this.tmo_estado == 'X' ? x.tmo_estado:this.tmo_estado));
                    }
                    else
                    {
                        result = this.aranceles.filter(x=>x.tmo_tipo == (this.tmo_tipo == 'X' ? x.tmo_tipo:this.tmo_tipo) && x.itmsGrpCod > 0 && x.tmo_descripcion.toLowerCase().indexOf(this.search_term.toLowerCase()) > -1 && x.tmo_estado == (this.tmo_estado == 'X' ? x.tmo_estado:this.tmo_estado));
                    }
                }
                this.total_pages = Math.ceil(result.length / this.records);
                var start = (page - 1) * this.records;
                
                this.aranceles_view = result.slice(start, start + this.records);
            },
            async eliminar(codtmo)
            {
                var index = this.aranceles.findIndex(x=>x.tmo_codigo == codtmo);
                this.aranceles.splice(index, 1);
                this.paginar(this.current_page);
                this.modal_arancel.close();
            },
            pushArancel(arancel)
            {
                var index = this.aranceles.findIndex(x=>x.tmo_codigo == arancel.tmo_codigo);
                if(index == -1)
                {
                    this.aranceles.push(arancel);
                }
                else
                {
                    this.aranceles[index] = arancel;
                }
                this.paginar(this.current_page);
            },
            async nuevoArancel()
            {
                this.$root.startLoading();
                await this.$refs.editarArancel.init(0);
                this.modal_arancel = M.Modal.getInstance(document.querySelector('#modal-editar-arancel'));
                this.modal_arancel.open();
                this.$root.stopLoading();
            },
            buscar()
            {
                //this.aranceles_view = this.aranceles.filter(x=>x.tmo_descripcion.indexOf(this.search_term) > -1);  
                this.paginar(1);            
            },
            async actualizarTodo()
            {
                if(!confirm('¿Está seguro de actualizar todos los aranceles en SAP?\nSolo se actualizarán los aranceles que tengan un grupo de artículos asignado.')) return false;
                await this.axios.post('/Aranceles/SyncAll')
                .then(response => {
                    console.log(response);
                });
            },
            async cuentasFacultad(id)
            {
                this.cuentas_facultad = [];
                this.$root.startLoading();
                await this.axios.get('/MovimientosCuenta/' + id)
                .then(response => {
                    this.cuentas_facultad = response.data;
                    this.modal_cuentas_facultades = M.Modal.getInstance(document.querySelector('#modal-editar-movimientos-cuenta'));
                    this.modal_cuentas_facultades.open();
                });
                this.$root.stopLoading();
            },
            editarCuentaFacultad(index)
            {
                this.cuentas_facultad[index].moc_editable = true;
            },
            async guardarCuentaFacultad(index)
            {
                var payload = {
                    moc_codigo: this.cuentas_facultad[index].moc_codigo,
                    moc_codtmo: this.cuentas_facultad[index].moc_codtmo,
                    moc_codfac: this.cuentas_facultad[index].moc_codfac,
                    moc_cuenta: this.cuentas_facultad[index].moc_cuenta
                };
                this.$root.startLoading();
                await this.axios.post('/MovimientosCuenta', payload)
                .then(() => {
                    this.cuentas_facultad[index].moc_editable = false;
                    M.toast({html: 'La cuenta ha sido guardada.'});
                });
                this.$root.stopLoading();
            }
        }
    }
</script>