<template>
  <div class="row">
    <h5 class="card-panel white-text" style="background-color: #1c21d1;">Aranceles por Carrera</h5>
    <div class="col m12">
        <div class="col m5 card-panel">
            <div class="row">
                <div class="col m12">
                    <div class="col m6">
                        <label>Buscar</label>
                        <input type="text" v-model="search_carrera">
                    </div>
                    <div class="col m3">
                        <label>Modalidad</label>
                        <select class="browser-default" @change="filtrarCarreras()" v-model="filtro_modalidad">
                            <option value="">Todas</option>
                            <option value="P">Presencial</option>
                            <option value="V">Virtual</option>
                        </select>
                    </div>
                    <div class="col m3">
                        <label>Estado</label>
                        <select class="browser-default" v-model="con_arancel" @change="filtrarCarreras()">
                            <option value="X">Todos</option>
                            <option value="1">Con Arancel</option>
                            <option value="0">Sin Arancel</option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="max-height:350px; overflow:auto">
                <table class="table tbl-condensed stripped tbl-bordered" style="font-size:12px">
                    <tbody>
                        <tr v-for="carrera in carreras_view" v-bind:key="carrera" v-bind:class="{'blue-ugb': carrera.car_codigo == codcar}">
                            <td v-bind:class="{'white-text': carrera.car_codigo == codcar}">{{ carrera.car_codigo }}</td>
                            <td style="width: 90%"><a href="#!" v-bind:class="{'white-text': carrera.car_codigo == codcar}" @click="getArancelesCarrera(carrera.car_codigo)">{{carrera.car_nombre}}</a></td>
                            <td style="width: 10%" class="center blue-ugb white-text">{{carrera.caa_cantidad}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col m1"></div>
        <div class="col m7 card-panel" v-show="codcar > 0">
            <a href="#!" class="right" @click="getArancel(0)"><i class="material-icons">add_circle</i></a>

            <h5>{{carrera.car_nombre}}</h5>
            <div class="row">
                <div class="col m4">
                    <label>Carrera</label>
                    <select class="browser-default" v-model="codcar_origen">
                        <option v-for="carrera in carreras.filter(x=>x.car_codigo != codcar)" :key="carrera" :value="carrera.car_codigo">{{ carrera.car_nombre }}</option>
                    </select>
                </div>
                <div class="col m2">
                    <br>
                    <button class="btn blue-ugb btn-small" @click="copiarAranceles()">Copiar</button>
                </div>
            </div>
            <table class="table tbl-condensed stripped tbl-bordered" v-show="aranceles_carrera.length > 0" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Arancel</th>
                        <th>Monto</th>
                        <th>Veces por Ciclo</th>
                        <th>Se Paga Con</th>
                        <th>Tipo</th>
                        <th>Pago por Mat.</th>
                        <th>Estado</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="arancel in aranceles_carrera" v-bind:key="arancel">
                        <td>{{arancel.tmo_descripcion_ad}}</td>
                        <td class="center">${{arancel.arc_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                        <td class="center">{{arancel.arc_cantidad}}</td>
                        <td>{{arancel.tmo_descripcion}}</td>
                        <td>{{arancel.arc_tipo}}</td>
                        <td class="center"><label><input type="checkbox" @click="setPorMateria($event, arancel.arc_codigo)" class="filled-in" :checked="arancel.arc_por_materia"><span></span></label></td>
                        <td class="center"><label><input type="checkbox" @click="setEstado($event, arancel.arc_codigo)" class="filled-in" :checked="arancel.arc_estado"><span></span></label></td>
                        <td class="center"><a href="#!" @click="getArancel(arancel.arc_codigo)"><i class="material-icons">edit</i></a></td>
                        <td class="center"><a href="#!" @click="eliminarArancel(arancel.arc_codigo)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>

  <div class="modal blue-ugb" id="modal-arancel" style="border: solid 1px white; width:80%">
    <div class="modal-content">
    <div class="row">
        <div class="col m7 card-panel">
            <form @submit.prevent="setArancel">
                    <div class="row">
                        <div class="col m12">
                            <label>Arancel</label>
                            <select class="browser-default" v-model="arancel_carrera.arc_codtmo_ad">
                                <option value="0">Seleccione</option>
                                <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'arc_codtmo_ad')}}</span>
                        </div>                
                    </div>
                    <div class="row">
                        <div class="col m4">
                            <label>Veces por Ciclo</label>
                            <input type="number" min="1" max="5" v-model="arancel_carrera.arc_cantidad">
                            <span class="helper-text">{{$validation(validations, 'arc_cantidad')}}</span>
                        </div>
                        <div class="col m4">
                            <label>Monto</label>
                            <input type="text" v-model="arancel_carrera.arc_valor">
                            <span class="helper-text">{{$validation(validations, 'arc_valor')}}</span>
                        </div>
                        <div class="col m4">
                            <label>Tipo</label>
                            <select class="browser-default">
                                <option v-for="tipo in tipo_arancel_carrera" v-bind:key="tipo" v-bind:value="tipo.tac_codigo">{{tipo.tac_descripcion}}</option>
                                <option value="N">Normal</option>
                                <option value="L">Uso de Laboratorios</option>
                                <option value="P">Prácticas de Enfermería</option>
                                <option value="E">Laboratorios de Enfermería</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'arc_tipo')}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <label>Enlazar Con</label>
                            <select class="browser-default" v-model="arancel_carrera.arc_codtmo">
                                <option value="0">Ninguno</option>
                                <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'arc_codtmo')}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m4">
                            <label><input type="checkbox" class="filled-in" v-model="arancel_carrera.arc_por_materia" :checked="arancel_carrera.arc_por_materia"><span>Pago por Materia</span></label>
                        </div>
                        <div class="col m4">
                            <label><input type="checkbox" class="filled-in" v-model="arancel_carrera.arc_estado" :checked="arancel_carrera.arc_estado"><span>Activo</span></label>
                        </div>                
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button type="submit" :disabled="arancel_carrera.arc_codtmo_ad == 0" class="btn blue-ugb right">{{arancel_carrera.arc_codigo > 0 ? 'ACTUALIZAR':'GUARDAR'}} <i class="material-icons right">{{arancel_carrera.arc_codigo > 0 ? 'update':'save'}}</i></button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col m1"></div>
            <div class="col m4">
                <div class="row">
                    <div class="col m12 card-panel">
                        <div class="row">
                            <div class="col m4">
                                <label>Regional</label>
                                <select class="browser-default" v-model="eac_codreg" @change="getCiclos()">
                                    <option value="0">Seleccione</option>
                                    <option v-for="regional in regionales" v-bind:key="regional" v-bind:value="regional.reg_codigo">{{regional.reg_nombre}}</option>
                                </select>
                            </div>
                            <div class="col m4">
                                <label>Ciclo</label>
                                <select class="browser-default" v-model="eac_codcil">
                                    <option value="0">Seleccione</option>
                                    <option v-for="ciclo in ciclos" v-bind:key="ciclo" v-bind:value="ciclo.cil_codigo">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}}</option>
                                </select>
                            </div>
                            <div class="col m4">
                                <button @click="setExcepcion()" :disabled="eac_codreg == 0 || eac_codcil == 0" class="btn blue-ugb btn-small" style="margin-top:20px"><i class="material-icons">add_circle</i></button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col m12">
                                <table class="table tbl-thinner" style="font-size:12px">
                                    <caption>NO SE COBRARÁ EN LAS REGIONALES Y CICLOS SIGUIENTES</caption>
                                    <thead>
                                        <tr>
                                            <th style="width:40%">Regional</th>
                                            <th style="width:40%">Ciclo</th>
                                            <th style="width:10%">Estado</th>
                                            <th style="width:10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="excepcion in excepciones_aranceles" v-bind:key="excepcion">
                                            <td class="center">{{excepcion.reg_nombre}}</td>
                                            <td class="center">Ciclo {{excepcion.cil_codcic}} - {{excepcion.cil_anio}}</td>
                                            <td class="center"><label><input type="checkbox" @change="setEstadoExcepcion($event, excepcion.eac_codarc, excepcion.cil_codigo)" class="filled-in" :checked="excepcion.eac_estado"><span></span></label></td>
                                            <td class="center"><a @click="eliminarExcepcion(excepcion.eac_codarc, excepcion.cil_codigo)" href="#!"><i class="material-icons">delete</i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
export default
{
    name: 'ArancelesCarrera',
    data(){
        return {
            carreras: [],
            carreras_view: [],
            carrera: {},
            codcar: 0,
            codcar_origen: 0,
            aranceles_carrera: [],
            arancel_carrera: {},
            aranceles: [],
            validations: [],
            tipo_arancel_carrera: [],

            arc_codigo: 0,
            arc_codcar: 0,
            arc_codtmo: 0,
            arc_codtmo_ad: 0,
            arc_cantidad: 1,
            arc_valor: 0,

            modal_instances: [],
            modal_aranceles: {},

            filtro_modalidad: '',
            search_carrera: '',
            con_arancel: 'X',

            excepciones_aranceles: [],
            eac_codcil: 0,
            eac_codreg: 0,
            ciclos: [],
            regionales: []
        }
    },
    async mounted()
    {
        this.modal_instances = M.Modal.init(document.querySelectorAll('.modal'), null);
        this.$root.startLoading();
        await this.axios.get('/ArancelesCarrera/Carreras')
        .then(response => {
            this.carreras = response.data;
            this.carreras_view = response.data;
        });
        this.$root.stopLoading();
    },
    methods: {
        async getArancelesCarrera(codcar)
        {
            this.$root.startLoading();
            this.carrera = this.carreras.find(x=>x.car_codigo == codcar);
            this.codcar = codcar;
            await this.axios.get('/ArancelesCarrera/Carreras/' + codcar)
            .then(response => {
                this.aranceles_carrera = response.data;
            });
            this.$root.stopLoading();
        },
        async getArancel(codarc)
        {
            this.reset();
            await this.getAranceles();
            this.$root.startLoading();
            if(codarc > 0)
            {
                await this.axios.get('/ArancelesCarrera/' + codarc)
                .then(response => {
                    this.arancel_carrera = {
                        arc_codigo: response.data.arc_codigo,
                        arc_codcar: response.data.car_codigo,
                        arc_codtmo: response.data.tmo_codigo,
                        arc_codtmo_ad: response.data.tmo_codigo_ad,
                        arc_cantidad: response.data.arc_cantidad,
                        arc_valor: response.data.arc_valor,
                        arc_estado: response.data.arc_estado,
                        arc_por_materia: response.data.arc_por_materia,
                        arc_tipo: response.data.arc_tipo
                    };
                });
            }
            this.$root.stopLoading();
            await this.getExcepciones(codarc);
            await this.getRegionales();
            this.modal_aranceles = M.Modal.getInstance(document.querySelector('#modal-arancel'));
            this.modal_aranceles.open();            
        },
        async getAranceles()
        {
            this.$root.startLoading();
            if(this.aranceles.length == 0)
            {
                await this.axios.get('/Aranceles')
                .then(response => {
                    this.aranceles = response.data.filter(x=>x.tmo_tipo == 'F');
                });
            }
            this.$root.stopLoading();
        },
        async getTipoArancelCarrera()
        {
            await this.axios.get('/TipoArancelCarrera')
            .then(response => {
                this.tipo_arancel_carrera = response.data;
            });
        },
        reset()
        {
            this.arancel_carrera = {
                arc_codigo: 0,
                arc_codcar: 0,
                arc_codtmo: 0,
                arc_codtmo_ad: 0,
                arc_cantidad: 1,
                arc_valor: 0,
                arc_estado: true,
                arc_por_materia: false,
                arc_tipo: 'N'
            };
        },
        async setArancel()
        {
            this.$root.startLoading();
            this.arancel_carrera.arc_codcar = this.codcar;
            this.arancel_carrera.arc_valor = parseFloat(this.arancel_carrera.arc_valor);
            this.arancel_carrera.arc_valor_2024 = parseFloat(this.arancel_carrera.arc_valor);
            if(this.arancel_carrera.arc_codigo == 0)
            {
                await this.axios.post('/ArancelesCarrera/', this.arancel_carrera)
                .then(async () => {
                    await this.getArancelesCarrera(this.codcar);
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                });
            }
            else
            {
                await this.axios.put('/ArancelesCarrera/' + this.arancel_carrera.arc_codigo, this.arancel_carrera)
                .then(async () => {
                    await this.getArancelesCarrera(this.codcar);
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                });
            }
            var carrera_index = this.carreras.findIndex(x=>x.car_codigo == this.codcar);
            if(carrera_index != -1)
            {
                this.carreras[carrera_index].caa_cantidad = this.aranceles_carrera.length;
            }
            this.$root.stopLoading();
        },
        async eliminarArancel(codarc)
        {
            if(!confirm('¿Desea eliminar este arancel?')) return false;
            this.$root.startLoading();
            await this.axios.delete('/ArancelesCarrera/' + codarc)
            .then(() => {
                var index = this.aranceles_carrera.findIndex(x=>x.arc_codigo == codarc);
                if(index != -1)
                {
                    this.aranceles_carrera.splice(index, 1);
                }

                var carrera_index = this.carreras.findIndex(x=>x.car_codigo == this.codcar);
                if(carrera_index != -1)
                {
                    this.carreras[carrera_index].caa_cantidad = this.aranceles_carrera.length;
                }
            })
            .catch(error => {
                if(error.response.status == 404)
                {
                    M.toast({html: 'Arancel no encontrado.'});
                }
                if(error.response.status == 400)
                {
                    M.toast({html: error.response.data.message});
                }
            });
            this.$root.stopLoading();
        },
        filtrarCarreras()
        {
            if(this.filtro_modalidad == '')
            {
                this.carreras_view = this.carreras.filter(x=>x.caa_con_arancel == (this.con_arancel == 'X' ? x.caa_con_arancel:this.con_arancel == '0' ? false:true));
            }
            else
            {
                this.carreras_view = this.carreras.filter(x=>x.car_virtual == this.filtro_modalidad && x.caa_con_arancel == (this.con_arancel == 'X' ? x.caa_con_arancel:this.con_arancel == '0' ? false:true));
            }
        },
        async setPorMateria(event, codarc)
        {
            await this.axios.patch('/ArancelesCarrera/' + codarc + '/PorMateria?por_materia=' + event.target.checked)
            .then(() => {});
        },
        async setEstado(event, codarc)
        {
            await this.axios.patch('/ArancelesCarrera/' + codarc + '/Estado?estado=' + event.target.checked)
            .then(() => {});
        },
        async getExcepciones(codarc)
        {
            this.$root.startLoading();
            await this.axios.get('/ExcepcionAranceles/' + codarc)
            .then(response => {
                this.excepciones_aranceles = response.data;
            });
            this.$root.stopLoading();
        },
        async eliminarExcepcion(codarc, codcil)
        {
            if(!confirm('¿Desea eliminar esta excepción?')) return false;
            this.$root.startLoading();
            await this.axios.delete('/ExcepcionAranceles/' + codarc + '/' + codcil)
            .then(async () => {
                await this.getExcepciones(codarc);
            });
            this.$root.stopLoading();
        },
        async getRegionales()
        {
            if(this.regionales.length == 0)
            {
                this.$root.startLoading();
                await this.axios.get('/Regionales')
                .then(response => {
                    this.regionales = response.data;
                });
                this.$root.stopLoading();
            }
        },
        async getCiclos()
        {
            if(this.eac_codreg == 0)
            {
                this.ciclos = [];
                this.eac_codcil = 0;
                return;
            }
            this.$root.startLoading();
            await this.axios.get('/Regionales/' + this.eac_codreg + '/Ciclos')
            .then(response => {
                this.ciclos = response.data;
            });
            this.$root.stopLoading();
        },
        async setExcepcion()
        {
            this.$root.startLoading();
            var data = {
                eac_codarc: this.arancel_carrera.arc_codigo,
                eac_codcil: this.eac_codcil,
                eac_estado: true
            };
            await this.axios.post('/ExcepcionAranceles/', data)
            .then(async () => {
                await this.getExcepciones(this.arancel_carrera.arc_codigo);
            })
            .catch(error => {
                var data = error.response.data;
                M.toast({html: data.message});
            });
            this.$root.stopLoading();
        },
        async setEstadoExcepcion(event, codarc, codcil)
        {
            await this.axios.patch('/ExcepcionAranceles/' + codarc + '/' + codcil + '?estado=' + event.target.checked)
            .then(() => {

            });
        },
        async copiarAranceles()
        {
            if(!confirm('¿Está seguro que desea copiar estos aranceles?')) return false;
            await this.axios.post('/ArancelesCarrera/' + this.codcar_origen + '/Copiar/' + this.codcar)
            .then(async () => {
                await this.getArancelesCarrera(this.codcar);
            });
        }
    }
}
</script>