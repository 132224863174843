<template>
 <div class="row m0 p0">
     <h5 class="card-panel white-text" style="background-color: #1c21d1;">Fechas Límites</h5>
     <div class="col m12 card-panel">
         <form @submit.prevent="codfel > 0 ? actualizar():guardar()">
             {{$validation([], '')}}
            <div class="col m2">
                <label>Arancel</label>
                <select class="browser-default" v-model="codtmo">
                    <option value="0">Seleccione</option>
                    <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                </select>
                <span class="helper-text">{{$validation(validations, 'fel_codtmo')}}</span>
            </div>
            <div class="col m2">
                <label>Ciclo</label>
                <select class="browser-default" v-model="codcil">
                    <option value="0">Seleccione</option>
                    <option v-for="ciclo in ciclos" v-bind:key="ciclo" v-bind:value="ciclo.cil_codigo">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}}</option>
                </select>
                <span class="helper-text">{{$validation(validations, 'fel_codcil')}}</span>
            </div>
            <div class="col m2">
                <label>Tipo</label>
                <select class="browser-default" v-model="tipo">
                    <option value="">Seleccione</option>
                    <option value="N">Normal</option>
                    <option value="P">Preespecialización</option>
                    <option value="V">Virtual</option>
                    <option value="M">Maestría</option>
                </select>
                <span class="helper-text">{{$validation(validations, 'fel_tipo')}}</span>
            </div>
            <div class="col m2">
                <label>Mes</label>
                <select class="browser-default" v-model="mes">
                    <option value="0">Seleccione</option>
                    <option v-for="mes in meses" v-bind:value="mes.mes_codigo" v-bind:key="mes">{{mes.mes_nombre}}</option>
                </select>
                <span class="helper-text">{{$validation(validations, 'fel_mes')}}</span>
            </div>
            <div class="col m1">
                <label>Año</label>
                <input type="text" v-model="anio" readonly>
                <span class="helper-text">{{$validation(validations, 'fel_anio')}}</span>
            </div>
            <div class="col m1">
                <label>Fecha</label>
                <InputMask type="text" maxlength="10" minlength="10" v-model="fecha" mask="99/99/9999"/>
                <span class="helper-text">{{$validation(validations, 'fel_fecha')}}</span>
            </div>
            <div class="col m1">
                <button type="submit" style="width:100%" class="btn blue-ugb"><i class="material-icons">{{codfel > 0 ? 'update':'save'}}</i></button>
            </div>
            <div class="col m1" v-if="codfel > 0">
                <button type="button" @click="reset" style="width:100%" class="btn red darken-4"><i class="material-icons">cancel</i></button>
            </div>
        </form>
     </div>
 </div>
 <div class="row m0 p0">
     <div class="col m12 card-panel">
         <div class="row">
             <div class="col m12">
                 <div class="col m2">
                     <label>Arancel</label>
                     <select class="browser-default" v-model="filter_arancel" @change="paginar(1)">
                         <option value="0">Todos</option>
                         <option v-for="arancel in aranceles_filter" v-bind:value="arancel.tmo_codigo" v-bind:key="arancel">{{arancel.tmo_descripcion}}</option>
                     </select>
                 </div>
                 <div class="col m2">
                     <label>Ciclo</label>
                     <select class="browser-default" v-model="filter_ciclo" @change="paginar(1)">
                         <option value="0">Todos</option>
                         <option v-for="ciclo in ciclos_filter" v-bind:value="ciclo.cil_codigo" v-bind:key="ciclo">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}}</option>
                     </select>
                 </div>
                 <div class="col m2">
                    <label>Tipo</label>
                    <select class="browser-default" v-model="filter_tipo" @change="paginar(1)">
                        <option value="">Seleccione</option>
                        <option value="N">Normal</option>
                        <option value="P">Preespecialización</option>
                        <option value="V">Virtual</option>
                        <option value="M">Maestría</option>
                    </select>
                </div>
             </div>
         </div>
         <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
             <thead>
                 <tr>
                     <th>Arancel</th>
                     <th>Ciclo</th>
                     <th>Tipo</th>
                     <th>Mes</th>
                     <th>Año</th>
                     <th>Fecha</th>
                     <th colspan="2"></th>
                 </tr>
             </thead>
             <tbody>
                 <tr v-for="fecha in fechas_view" v-bind:key="fecha" v-bind:class="{'grey lighten-2': (fecha.fel_codigo == codfel)}">
                     <td>{{fecha.tmo_descripcion}}</td>
                     <td class="center">{{fecha.cic_nombre}} - {{fecha.cil_anio}}</td>
                     <td class="center">{{fel_tipo(fecha.fel_tipo)}}</td>
                     <td class="center">{{fecha.mes_nombre}}</td>
                     <td class="center">{{fecha.fel_anio}}</td>
                     <td class="center">{{$dateFormat(new Date(fecha.fel_fecha), 'dd/MM/yyyy')}}</td>
                     <td class="center"><a href="#!" @click="editar(fecha.fel_codigo)"><i class="material-icons">edit</i></a></td>
                     <td class="center"><a href="#!" @click="eliminar(fecha.fel_codigo)"><i class="material-icons">delete</i></a></td>
                 </tr>
             </tbody>
         </table>
         <ul class="pagination">
             <li v-if="current_page > 1"><a href="#!" @click="paginar(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
             <template v-for="page in total_pages" v-bind:key="page">                
                <li v-if="(page + 4) > current_page && (page - 4) < current_page" v-bind:class="{active: (page == current_page)}"><a href="#!" @click="paginar(page)">{{page}}</a></li>
             </template>
             <li v-if="current_page < total_pages"><a href="#!" @click="paginar(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
         </ul>
     </div>
 </div>
</template>

<script>
import M from 'materialize-css'
import InputMask from 'primevue/inputmask';
    export default
    {
        components: {
            InputMask
        },
        data(){
            return {
                fechas: [],
                fechas_view: [],
                ciclos: [],
                codcil: 0,
                meses: [],
                aranceles: [],
                codtmo: 0,
                tipo: '',
                mes: 0,
                validations: [],
                fecha: '',
                codfel: 0,

                records: 10,
                total_pages: 1,
                current_page: 1,

                filter_arancel: 0,
                filter_ciclo: 0,
                filter_tipo: ''
            }
        },
        async mounted()
        {
            this.$root.startLoading();
            await this.axios.get('/Ciclos')
            .then(response => {
                this.ciclos = response.data.filter(x=>(x.cic_codigo_int == 1 || x.cic_codigo_int == 2) && x.cil_codigo >= 361);
            });
            await this.axios.get('/Meses')
            .then(response => {
                this.meses = response.data;
            });
            await this.axios.get('/Aranceles/Facturacion')
            .then(response => {
                this.aranceles = response.data;
            });
            await this.axios.get('/FechasLimites')
            .then(response => {
                this.fechas = response.data;
                this.fechas_view = response.data;
            });
            this.paginar(1);
            this.$root.stopLoading();
        },
        methods: {
            async guardar()
            {
                var data = {
                    fel_codcil: this.codcil,
                    fel_mes: this.mes,
                    fel_anio: this.anio,
                    fel_fecha: this.$dateFormat(this.fecha, 'yyyy-MM-dd'),
                    fel_codtmo: this.codtmo,
                    fel_tipo: this.tipo
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/FechasLimites', data)
                .then(async response => {
                    await this.getFecha(response.data.fel_codigo);
                    this.reset();                                     
                })
                .catch(error => {                 
                    if(error.response.data.type == 'M')
                        this.validations = error.response.data.modelErrors;
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async actualizar()
            {
                var payload = {
                    fel_codigo: this.codfel,
                    fel_codcil: this.codcil,
                    fel_mes: this.mes,
                    fel_anio: this.anio,
                    fel_fecha: this.$dateFormat(this.fecha, 'yyyy-MM-dd'),
                    fel_codtmo: this.codtmo,
                    fel_tipo: this.tipo,
                    fel_codreg: 1,
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.put('/FechasLimites/' + this.codfel, payload)
                .then(async () => {
                    await this.getFecha(this.codfel);
                    this.reset();            
                })
                .catch(error => {                 
                    if(error.response.data.type == 'M')
                        this.validations = error.response.data.modelErrors;
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            paginar(page)
            {
                var result = this.fechas.filter(x=>
                                                    x.tmo_codigo == (this.filter_arancel == 0 ? x.tmo_codigo:this.filter_arancel)
                                                    &&
                                                    x.cil_codigo == (this.filter_ciclo == 0 ? x.cil_codigo:this.filter_ciclo)
                                                    &&
                                                    x.fel_tipo == (this.filter_tipo == '' ? x.fel_tipo:this.filter_tipo)
                                                );
                this.total_pages = Math.ceil(result.length / this.records);
                this.fechas_view = result.slice((page - 1) * this.records, (page - 1) * this.records + this.records);
                this.current_page = page;
            },
            async getFecha(codfel)
            {
                await this.axios.get('/FechasLimites/' + codfel)
                .then(response => {
                    var index = this.fechas.findIndex(x=>x.fel_codigo == codfel);
                    if(index != -1)
                    {
                        this.fechas[index] = response.data;
                    }
                    else
                    {
                        this.fechas.unshift(response.data);
                    }
                    this.paginar(1);
                });
            },
            async eliminar(codfel)
            {
                if(!confirm('¿Desea eliminar esta fecha?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/FechasLimites/' + codfel)
                .then(() => {
                    var index = this.fechas.findIndex(x=>x.fel_codigo == codfel);
                    if(index != -1)
                    {
                        this.fechas.splice(index, 1);
                        this.paginar(this.current_page);
                    }
                });
                this.$root.stopLoading();
            },
            editar(codfel)
            {
                var index = this.fechas.findIndex(x=>x.fel_codigo == codfel);
                if(index != -1)
                {
                    this.codfel = this.fechas[index].fel_codigo;
                    this.codtmo = this.fechas[index].tmo_codigo;
                    this.codcil = this.fechas[index].cil_codigo;
                    this.tipo = this.fechas[index].fel_tipo;
                    this.mes = this.fechas[index].mes_codigo;
                    this.fecha = this.$dateFormat(new Date(this.fechas[index].fel_fecha), 'dd/MM/yyyy')
                }
            },
            fel_tipo(tipo)
            {
                switch(tipo)
                {
                    case 'N':
                        return 'Normal';
                    case 'M':
                        return 'Maestría';
                    case 'P':
                        return 'Preespecialización';
                    case 'V':
                        return 'Virtual';
                    default:
                        return 'Normal';
                }
            },
            reset()
            {
                this.codfel = 0;
                this.codcil = 0;
                this.mes = 0;
                this.fecha = '';
                this.codtmo = 0;
                this.tipo = 'N';   
            }
        },
        computed: {
            anio()
            {
                var result = this.ciclos.find(x=>x.cil_codigo == this.codcil);
                if(result == undefined) return new Date().getFullYear();
                return result.cil_anio;
            },
            aranceles_filter()
            {                
                var response = [];
                for(let i = 0; i <= this.fechas.length - 1; i++)
                {
                    if(response.find(x=>x.tmo_codigo == this.fechas[i].tmo_codigo) == undefined)
                    {
                        response.push(this.fechas[i]);
                    }
                }
                return response.sort((a, b)=> a.tmo_codigo > b.tmo_codigo ? 0: a.tmo_codigo < b.tmo_codigo ? -1:0);
            },
            ciclos_filter()
            {                
                var response = [];
                for(let i = 0; i <= this.fechas.length - 1; i++)
                {
                    if(response.find(x=>x.cil_codigo == this.fechas[i].cil_codigo) == undefined)
                    {
                        response.push(this.fechas[i]);
                    }
                }
                return response.sort((a, b)=> a.cil_codigo > b.cil_codigo ? 1: a.cil_codigo < b.cil_codigo ? 0:-1);
            }
        }
    }
</script>
