export default {
    install: (app) =>
    {
        app.config.globalProperties.$dateFormat = function(date, format)
        {
            if(date == null || date == undefined) return;
            if(format == 'dd/MM/yyyy')
            {
                if(typeof date.getMonth != 'function')
                {
                    date = new Date(date);
                }
                return (date.getDate() < 10 ? '0' + date.getDate():date.getDate()) + '/' +
                      ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1):(date.getMonth() + 1)) + '/' +
                      date.getFullYear();
            }
            if(format == 'yyyy-MM-dd')
            {
                if(date == '') return '0000-00-00';
                return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0,2) + 'T00:00:00';
            }
            return date;
        },
        app.config.globalProperties.$toDateFormat = function(date)
        {           
            var day = parseInt(date.substring(0, 2));
            var month = parseInt(date.substring(3,5)) - 1;
            var year = parseInt(date.substring(6,10));
            return new Date(year, month, day);
        }
    }
}