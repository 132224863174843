<template>
    <div class="row m0 p0">
        <h5 class="card-panel white-text" style="background-color: #1c21d1;">Estudiantes</h5>
        <div class="col m12 card-panel">
            <form @submit.prevent="buscar()">
                <div class="col m4">
                    <label>Buscar</label>
                    <input type="text" v-model="txt_search">
                </div>
                <div class="col m2">
                    <button type="submit" class="btn blue-ugb"><i class="material-icons">search</i></button>
                </div>
            </form>
        </div>
    </div>
    <div class="row m0 p0">
        <div class="col m12 card-panel">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Carnet</th>
                        <th>Nombre</th>
                        <th>Carrera</th>
                        <th>Estado</th>
                        <th>Facturación<br>Electrónica</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="estudiante in estudiantes" v-bind:key="estudiante">
                        <td class="center">{{estudiante.per_carnet}}</td>
                        <td>{{estudiante.per_apellidos_nombres}}</td>
                        <td>{{estudiante.car_nombre}}</td>
                        <td class="center">{{estudiante.tie_descripcion}}</td>
                        <td class="center"><a target="_blank" v-bind:href="'/EstudiantesAdmin/' + estudiante.per_codigo"><i class="material-icons">phonelink_setup</i></a></td>
                        <td class="center"><a target="_blank" v-bind:href="'/Estudiante/' + estudiante.per_codigo"><i class="material-icons">open_in_new</i></a></td>
                    </tr>
                </tbody>
            </table>
            <ul class="pagination">
                <li v-if="current_page > 1">
                    <a href="#!" @click="getEstudiantes(1)"><i class="material-icons">first_page</i></a>
                </li>
                <li v-if="current_page > 1">
                    <a href="#!" @click="getEstudiantes(current_page - 1)"><i class="material-icons">chevron_left</i></a>
                </li>
                <template v-for="page in total_pages" v-bind:key="page">
                    <li v-if="(page + 4) > current_page && (page - 4) < current_page" v-bind:class="{active: (page == current_page)}">
                        <a href="#!" @click="getEstudiantes(page)">{{page}}</a>
                    </li>
                </template>
                <li v-if="current_page < total_pages">
                    <a href="#!" @click="getEstudiantes(current_page + 1)"><i class="material-icons">chevron_right</i></a>
                </li>
                <li v-if="current_page < total_pages">
                    <a href="#!" @click="getEstudiantes(total_pages)"><i class="material-icons">last_page</i></a>
                </li>
            </ul>
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-exoneraciones" style="min-width:80%">
        <div class="modal-content">
            <div class="card-panel col m12">
                <div class="row m0 p0">
                    <div class="col m12 card-panel">
                        <div class="col m1">
                            <label>Carnet</label>
                            <p>{{per_carnet}}</p>
                        </div>
                        <div class="col m4">
                            <label>Nombre</label>
                            <p>{{per_apellidos_nombres}}</p>
                        </div>
                        <div class="col m6">
                            <label>Carrera</label>
                            <p>{{car_nombre}}</p>
                        </div>
                        <div class="col m1">
                            <label>Estado</label>
                            <p>{{tie_descripcion}}</p>
                        </div>
                    </div>
                </div>
                <div class="row m0 p0">
                    <ul class="tabs">
                        <li class="tab col s3 active"><a href="#exoneraciones">Exoneraciones</a></li>
                        <li class="tab col s3"><a href="#descuentos" @click="getDescuentos()">Descuentos</a></li>
                        <li class="tab col s3"><a href="#aranceles" @click="getAranceles()">Aranceles</a></li>
                    </ul>
                </div>

                <div class="row m0 p0">
                    <div id="exoneraciones">
                        <ExoneracionesComponent ref="exoneracionesComponent" />
                    </div>
                    <div id="descuentos">
                        <DescuentosComponent ref="descuentosComponent" />
                    </div>
                    <div id="aranceles">
                        <ArancelesComponent ref="arancelesComponent" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import M from 'materialize-css'
    import ExoneracionesComponent from '../components/ExoneracionesComponent.vue';
    import DescuentosComponent from '../components/DescuentosComponent.vue';
    import ArancelesComponent from '../components/ArancelesEstudianteComponent.vue';
    
    export default {
        components: {
            ExoneracionesComponent,
            DescuentosComponent,
            ArancelesComponent
        },
        data() {
            return {
                modales: [],
                modal_exoneracion: {},
                estudiantes: [],
                aranceles: [],
                total_pages: 0,
                current_page: 1,
                records: 15,
                search: '',
                txt_search: '',
                
                per_carnet: '',
                per_apellidos_nombres: '',
                car_nombre: '',
                tie_descripcion: '',

                estudiante: {},

                tabs: []
            }
        },
        async mounted() {
            await this.getEstudiantes();
            this.modales = M.Modal.init(document.querySelectorAll('.modal'));
            this.tabs = M.Tabs.init(document.querySelectorAll('.tabs'), null);
        },
        methods: {
            async openModal(codper)
            {
                this.tabs[0].select('exoneraciones');                
                this.$root.startLoading();
                this.estudiante = this.estudiantes.find(x=>x.per_codigo == codper);
                this.codper = this.estudiante.per_codigo;
                this.per_carnet = this.estudiante.per_carnet;
                this.per_apellidos_nombres = this.estudiante.per_apellidos_nombres;
                this.car_nombre = this.estudiante.car_nombre;
                this.tie_descripcion = this.estudiante.tie_descripcion;
                await this.$refs.exoneracionesComponent.init(this.estudiante);
                this.modal_exoneracion = M.Modal.getInstance(document.querySelector('#modal-exoneraciones'));
                this.modal_exoneracion.open();
                this.tabs[0].updateTabIndicator();
                this.$root.stopLoading();
            },
            async getEstudiantes(page)
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes?page=' + (page || this.current_page) + '&records=' + this.records + '&search=' + this.search)
                .then(response => {
                    this.estudiantes = response.data.records;
                    this.total_pages = response.data.total_pages;
                    this.current_page = response.data.current_page;
                });
                this.$root.stopLoading();
            },
            async getDescuentos()
            {
                await this.$refs.descuentosComponent.init(this.estudiante);
            },
            async buscar()
            {
                this.search = this.txt_search;
                await this.getEstudiantes(1);
            },
            async getAranceles()
            {
                await this.$refs.arancelesComponent.init(this.estudiante);                
            }
        }
    }
</script>
