<template>
    <div class="row">
        <!-- <button @click="visible = !visible">Mostrar</button>
        
        <keep-alive>
            <Test v-if="visible" />
        </keep-alive> -->

        <div class="col m12 center" style="min-height:400px">
            <img class="center" src="https://repositorio.ugb.edu.sv/resources/img/ugb/logos_nuevos/UGB_160x160_azul.png">
        </div>
    </div>
</template>

<script>
/* import Test from '../components/TestComponent' */
    export default
    {
        name: 'Home',
        data(){
            return {
                visible: false
            }
        }/* ,
        components: {
            Test
        } */,
        async created()
        {
            this.$root.startLoading();
            await this.axios.head('/Login/Status')
            .then(() => {})
            .catch(() => {});
            this.$root.stopLoading();
        }
    }
</script>