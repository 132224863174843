<template>
    <div class="row">
        <div class="col m12 card-panel" style="position:relative; background-color: #1c21d1;">
            <a href="#!" @click="getFactura(0)" title="Nuevo"><i class="material-icons right white-text" style="font-size:40px">add_circle</i></a>
            <form @submit.prevent="buscarMovimiento()">
                <div class="col m4">
                    <label class="white-text">Buscar</label>
                    <input type="text" id="txt_buscar_movimiento" class="white-text">
                </div>
                <div class="col m1">
                    <button type="submit" class="btn white black-text"><i class="material-icons">search</i></button>
                </div>
            </form>
            <div class="col m2">
                <label class="white-text">Tipo de Documento</label>
                <select class="browser-default" @change="getFacturas(1)" v-model="type">
                    <option value="">Todos</option>
                    <option v-for="tipo in tipo_documento" v-bind:key="tipo" v-bind:value="tipo.tdf_codigo">{{tipo.tdf_nombre}}</option>
                </select>
            </div>
            <div class="col m2">
                <label class="white-text">Usuario</label>
                <select class="browser-default" @change="getFacturas(1)" v-model="usuario">
                    <option value="">Todos</option>
                    <option v-for="usuario in usuarios" v-bind:key="usuario" v-bind:value="usuario">{{usuario}}</option>
                </select>
            </div>            
        </div>
    </div>
    <div class="row card-panel">
        <div class="col m12">
            <div class="row">
            <table class="table tbl-condensed tbl-bordered white" style="font-size:12px">
                <thead>
                    <tr>
                        <th style="width:18%"># Doc.</th>
                        <th style="width:7%">Tipo</th>
                        <th style="width:36%">Cliente</th>
                        <th style="width:8%">Total</th>
                        <th style="width:6%">Fecha</th>
                        <th style="width:6%">Estado</th>
                        <th style="width:5%">Impreso</th>
                        <th v-if="isAdmin()"></th>
                        <th style="width:8%">Usuario</th>
                        <th style="width:6%">Fecha de Registro</th>
                        <!-- <th>FE</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="factura in facturas_view" :key="factura.fac_codigo">
                        <td class="center">
                            <a href="#!" @click="getFactura(factura.fac_codigo)">{{factura.fac_factura}}</a>
                            <br>
                            <span style="font-size: 10px">{{ factura.fac_codigo_generacion }}</span>
                        </td>
                        <td class="center">{{factura.tdf_nombre}}</td>
                        <td>{{factura.fac_cliente}}</td>
                        <td style="text-align: right;">${{factura.fac_total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        <td class="center">{{factura.fac_fecha}}</td>
                        <td class="center">{{factura.ted_nombre}}</td>
                        <td class="center"><label><input @click.prevent="" type="checkbox" class="filled-in" :checked="factura.fac_impreso == 'S'"><span></span></label></td>
                        <td class="center" v-if="isAdmin()">
                            <button type="button" class="btn green darken-2 btn-small" @click="verificarFactura(factura.fac_codigo_generacion)" style="width: 100%"><i class="material-icons">check</i></button>
                            <button type="button" class="btn red darken-4 btn-small" @click="enviarFactura(factura.fac_codigo)" v-bind:disabled="factura.fac_procesada" style="width: 100%"><i class="material-icons">send</i></button>
                        </td>
                        <td class="center">{{ factura.fac_usuario }}</td>
                        <td>{{factura.fac_fecha_registro_string}}</td>
                        <!-- <td><button class="btn blue-ugb" @click="facturaElectronica(factura.fac_codigo)">FE</button></td> -->
                    </tr>
                </tbody>
            </table>
            </div>
            <div class="row">
                 <ul class="pagination card-panel white">
                    <li v-if="current_page != 1"><a href="#!" @click="getFacturas(1)"><i class="material-icons">first_page</i></a></li>
                    <li v-if="current_page > 1"><a href="#!" @click="getFacturas(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
                    <template v-for="n in total_pages" v-bind:key="n">
                        <li v-if="n > starting_page && n < ending_page" @click="getFacturas(n)" v-bind:class="{active: n == current_page}"><a href="#!">{{n}}</a></li>
                    </template>
                    <li v-if="current_page < total_pages"><a href="#!" @click="getFacturas(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
                    <li v-if="current_page != total_pages && facturas.length > 0"><a href="#!" @click="getFacturas(total_pages)"><i class="material-icons">last_page</i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal blue-ugb" id="modal-facturacion-cliente" style="min-width: 80%; max-height:575px; top:5%; border: solid 1px white">
        <div class="modal-content blue-ugb">            
            <div class="row m0 p0">                
                <div class="col m12 card-panel" style="position:relative">
                    <span class="mov_estado badge new" v-bind:class="{'red darken-2': factura.fac_estado == 'A', 'green darken-2':factura.fac_estado == 'R'}" data-badge-caption="" v-show="factura.fac_codigo > 0" style="position:absolute; right: 1px; top: 1px">{{factura.ted_nombre}}</span>
                    <div class="row m0 p0">
                        <div class="col m2">
                            <label>Tipo</label>
                            <select class="browser-default" :disabled="!editable()" v-model="factura.fac_tipo" @change="getNumeroRecibo()">
                                <option v-for="tipo in tipo_documento" v-bind:key="tipo" v-bind:value="tipo.tdf_codigo">{{tipo.tdf_nombre}}</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, "fac_tipo")}}</span>
                        </div>
                        <div class="col m1">
                            <label>Serie</label>
                            <input type="text" v-model="factura.fac_serie" disabled>
                            <span class="helper-text">{{$validation(validations, "fac_serie")}}</span>
                        </div>
                        <div class="col m1">
                            <label>Número</label>
                            <input type="text" v-model="factura.fac_factura" disabled>
                            <span class="helper-text">{{$validation(validations, "fac_factura")}}</span>
                        </div>
                        <div class="col m2">
                            <label>Fecha de Facturación</label>
                            <!-- <input type="text" v-model="factura.fac_fecha" :disabled="!editable"> -->
                            <datepicker v-model="factura.fac_fecha" :disabled="factura.fac_tipo_pago != 'D'" inputFormat="dd/MM/yyyy" />
                            <span class="helper-text">{{$validation(validations, "fac_fecha")}}</span>
                        </div>
                        <div class="col m2">
                            <label>Fecha de Registro</label>
                            <!-- <input type="text" v-model="factura.fac_fecha" :disabled="!editable"> -->
                            <datepicker v-model="factura.fac_fecha_registro" disabled inputFormat="dd/MM/yyyy" />
                        </div>
                        <div class="col m4" v-if="factura.fac_codigo > 0">
                            <label>Factura Electrónica <span v-show="factura.fac_procesada">(Registrada)</span> <span v-show="!factura.fac_procesada">(Pendiente)</span></label>
                            <br>
                            <span class="new badge left green darken-2" style="margin: 0px" data-badge-caption="">{{ factura.fac_codigo_generacion }}</span>
                        </div>
                    </div>
                    <div class="row m0 p0">
                        <div class="col m2">
                            <label>Forma de Pago</label>
                            <select class="browser-default" :change="setBanco()" v-model="factura.fac_tipo_pago" :disabled="!editable()">
                                <option value="E">Efectivo</option>
                                <option value="D">Depósito</option>
                                <option value="C">Cheque</option>
                                <option value="T">Tarjeta</option>
                                <option value="S">Especie</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, "fac_tipo_pago")}}</span>
                        </div>
                        <div class="col m2" v-show="factura.fac_tipo_pago == 'C'">
                            <label># de Cheque</label>
                            <input type="text" v-model="factura.fac_cheque" :disabled="!editable()">
                            <span class="helper-text">{{$validation(validations, "fac_cheque")}}</span>
                        </div>
                        <div class="col m1" v-show="factura.fac_tipo_pago == 'T'">
                            <label># Tar.</label>
                            <InputMask :disabled="!editable()" type="text" maxlength="4" minlength="4" v-model="factura.fac_tarjeta" mask="9999"/>
                            <span class="helper-text">{{$validation(validations, "fac_tarjeta")}}</span>
                        </div>
                        <div class="col m1" v-show="factura.fac_tipo_pago == 'T'">
                            <label>XX/XX</label>
                             <InputMask :disabled="!editable()" type="text" v-model="factura.fac_fecha_tarjeta" mask="99/99"/>
                             <span class="helper-text">{{$validation(validations, "fac_fecha_tarjeta")}}</span>
                        </div>
                        <div class="col m1 p0" v-show="factura.fac_tipo_pago == 'T'">
                            <label><input :disabled="!editable()" type="radio" class="browser-default" name="mov_pos" value="P" v-model="factura.fac_pos" /><span style="font-size:12px; padding: 0px 10px 0px 25px; height:0px">Prome.</span></label><br/>
                            <label><input :disabled="!editable()" type="radio" class="browser-default" name="mov_pos" value="C" v-model="factura.fac_pos" /><span style="font-size:12px; padding: 0px 10px 0px 25px; height:0px">Credo.</span></label><br/>
                            <label><input :disabled="!editable()" type="radio" class="browser-default" name="mov_pos" value="A" v-model="factura.fac_pos" /><span style="font-size:12px; padding: 0px 10px 0px 25px; height:0px">Agríc.</span></label><br/>
                            <span class="helper-text">{{$validation(validations, "fac_pos")}}</span>
                        </div>
                        <div class="col m1" v-show="factura.fac_tipo_pago == 'T'">
                            <label>Voucher</label>
                            <input type="text" v-model="factura.fac_voucher" :disabled="!editable()">
                            <span class="helper-text">{{$validation(validations, "fac_voucher")}}</span>
                        </div>
                        <div class="col m2" v-show="factura.fac_tipo_pago == 'D'">
                            <label>Banco</label>
                            <select class="browser-default" v-model="factura.fac_codban">
                                <option v-for="banco in bancos" :value="banco.ban_codigo" :key="banco.ban_codigo">{{ banco.ban_nombre }}</option>
                            </select>
                        </div>
                        <div class="col m2">
                            <label>Tipo de Pago</label>
                            <select class="browser-default" :disabled="!editable()" v-model="factura.fac_tipo_credito">
                                <option value="T">Contado</option>
                                <option value="C">Credito</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, "fac_tipo_credito")}}</span>
                        </div>
                    </div>
                    
                    <div class="row m0 p0">
                        <div class="col m12">
                            <label>Cliente</label>
                            <a href="#!" v-if="editable() && !sin_correlativo()"><i class="material-icons right" @click="buscarCliente()" style="font-size:16px">search</i></a>
                            <input type="text" v-model="factura.fac_cliente" readonly >
                            <small class="right">NIT: {{ factura.cli_mh_nit }} | DUI: {{ factura.cli_mh_dui }} | NRC: {{ factura.cli_mh_nrc }}</small>
                            <span class="helper-text">{{$validation(validations, "fac_codcli")}}</span>
                        </div>
                    </div>
                    <div class="row m0 p0">
                        <div class="col m12">
                            <label>Concepto</label>
                            <textarea class="materialize-textarea" v-model="factura.fac_descripcion" :readonly="!editable()"></textarea>
                        </div>
                    </div>
                    <div class="row m0 p0" v-show="sin_correlativo()">
                        <div class="col m12 darken-4 card-panel red white-text">
                            <span>No puede facturar sin un numero de serie y correlativo.</span>
                            <i class="material-icons right">warning</i>
                        </div>
                    </div>
                </div>

                 <div class="row m0 p0">        
                    <div class="col m12 center blue-ugb m0 p0">
                        <div style="margin:5px">
                            <button type="button" @click="resetForm(true)" class="btn green darken-2 btn-small" style="margin-right:10px">NUEVO <i class="material-icons right">add_circle</i></button>
                            <button type="button" v-show="anulable()" class="btn orange darken-4 btn-small" @click="modalAutorizar()" style="margin-right:10px">ANULAR <i class="material-icons right">cancel</i></button>
                            <button type="button" class="btn blue btn-small" style="margin-right:10px" @click="guardar()" v-show="factura.fac_codigo == 0 && factura.col_dfa_det_fac.length > 0">GUARDAR <i class="material-icons right">save</i></button>
                            <button type="button" class="btn blue btn-small" style="margin-right:10px" @click="actualizar()" v-show="actualizable() && factura.col_dfa_det_fac.length > 0">ACTUALIZAR <i class="material-icons right">update</i></button>
                            <button type="button" class="btn teal darken-2 btn-small" @click="imprimir()" style="margin-right:10px" v-show="imprimible()">IMPRIMIR <i class="material-icons right">print</i></button>
                            <button type="button" class="btn teal darken-2 btn-small" @click="imprimirFE()" style="margin-right:10px" v-show="factura.fac_codigo_generacion != null">IMPRIMIR FE <i class="material-icons right">print</i></button>
                            <button type="button" class="btn red darken-3 btn-small" v-show="eliminable" @click="eliminar" style="margin-right:10px">ELIMINAR <i class="material-icons right">delete</i></button>
                            <button type="button" @click="cerrarModal()" class="btn red darken-3 btn-small" style="margin-right:10px">CERRAR <i class="material-icons right">close</i></button>
                        </div>
                    </div>
                    <div class="right white-text m0 p0" v-show="imprimible">
                        <small>OPCIONES DE IMPRESIÓN &nbsp;</small>
                        <label class="white-text"><input type="checkbox" v-model="concepto" class="filled-in"><span style="font-size:10px">Concepto </span></label> &nbsp;
                        <label class="white-text"><input type="checkbox" v-model="version" class="filled-in"><span style="font-size:10px">Ver. 2 </span></label>
                        <label class="white-text"><input type="checkbox" v-model="iva_excluido" class="filled-in"><span style="font-size:10px">IVA Exc. </span></label>
                    </div>
                </div>

                <div class="col m12 card-panel" v-show="factura.fac_codcli != 0">
                    <div class="row" v-if="editable()">
                        <form @submit.prevent="setDetalle()" v-show="factura.col_dfa_det_fac.length <= 8">
                            <div class="col m5">
                                <label>Movimiento</label> <a href="#!" class="right" @click="buscarArancel()"><i class="material-icons" style="font-size:15px">search</i></a>
                                <select class="browser-default" v-model="dfa_codtmo" @change="setValor()">
                                    <option value="0">Seleccione</option>
                                    <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                                </select>
                                <span class="helper-text">{{$validation(validations, "dfa_codtmo")}}</span>
                            </div>
                            <div class="col m2">
                                <label>Mes</label>
                                <select class="browser-default" v-model="dfa_mes">
                                    <option value="0">Ninguno</option>
                                    <option v-for="mes in meses" v-bind:value="mes.mes_codigo" v-bind:key="mes">{{mes.mes_nombre}}</option>
                                </select>
                                <span class="helper-text">{{$validation(validations, "dfa_mes")}}</span>
                            </div>
                            <div class="col m1">
                                <label>Cantidad</label>
                                <input type="number" class="text-right" v-model="dfa_cantidad">
                                <span class="helper-text">{{$validation(validations, "dfa_cantidad")}}</span>
                            </div>
                            <div class="col m2">
                                <label>Valor</label>
                                <input type="text" class="text-right" v-model="dfa_valor">
                                <span class="helper-text">{{$validation(validations, "dfa_valor")}}</span>
                            </div>
                            <div class="col m2">
                                <button type="submit" :disabled="dfa_codtmo == 0" class="btn blue-ugb" style="margin-top: 10px;"><i class="material-icons">add_circle</i></button>
                            </div>
                        </form>
                    </div>
                    <div class="row" v-show="factura.col_dfa_det_fac.length > 0">
                        <div class="col m12">
                            <table class="table tbl-thinner tbl-bordered striped" style="font-size:12px">
                                <thead>
                                    <tr>
                                        <th style="width:5%"></th>
                                        <th style="width:45%">Movimiento</th>
                                        <th style="width:10%">Mes</th>
                                        <th style="width:10%">Cantidad</th>
                                        <th style="width:10%">IVA</th>
                                        <th style="width:10%">Valor</th>
                                        <th style="width:10%">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(detalle, index) in factura.col_dfa_det_fac" v-bind:key="detalle">
                                        <tr v-if="detalle != undefined">
                                            <td class="center"><a href="#!" v-if="editable()" @click="factura.col_dfa_det_fac.splice(index, 1)"><i class="material-icons">delete</i></a></td>
                                            <td>
                                                <form @submit.prevent="guardarEditarDetalle(index)">
                                                    <span v-show="index_editando != index">{{detalle.dfa_descripcion}}</span> <span v-show="index_editando != index && detalle.tmo_descripcion != detalle.dfa_descripcion">(Edit.: {{ detalle.tmo_descripcion }})</span>
                                                    <input maxlength="2000" minlength="1" v-show="index_editando == index" type="text" v-model="detalle.dfa_descripcion" style="width: 90%">
                                                    <a href="#!" v-show="index_editando == -1 && editable()" @click="editarDetalle(index)"><i class="material-icons right" style="font-size: 18px;">edit</i></a>
                                                    <a href="#!" type="submit" v-show="index_editando == index" @click="guardarEditarDetalle(index)"><i class="material-icons right" style="font-size: 18px;">save</i></a>
                                                </form>
                                            </td>
                                            <td class="center">{{detalle.mes_nombre}}</td>
                                            <td class="center">{{detalle.dfa_cantidad}}</td>
                                            <td class="text-right">${{detalle.dfa_iva.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                            <td class="text-right">${{detalle.dfa_valor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                            <td class="text-right">${{((detalle.dfa_valor) + (detalle.dfa_iva)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5"></td>
                                        <th class="center">IVA</th>
                                        <td class="text-right">${{total_dfa_iva.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5"></td>
                                        <th class="center">EXENTAS</th>
                                        <td class="text-right">${{total_dfa_valor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="5"></td>
                                        <th class="center">TOTAL</th>
                                        <td class="text-right">${{(total_dfa_iva + total_dfa_valor).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="motivo-anulacion" v-if="factura.fac_estado == 'A'">
                        <label class="white-text">
                            <b>Motivo:</b> {{ factura.fac_motivo_anulacion }}
                        </label>
                        <br>
                        <label class="white-text">
                            <b>Fecha:</b> {{ factura.fac_fecha_anula }}  <b>Usuario: </b> {{ factura.fac_usuario_anula }}
                        </label>
                    </div>
                </div>                
            </div>
        </div>
    </div>

    <div class="modal" id="modal-seleccionar-cliente-facturacion" style="min-height: 275px; width: 60%">
        <div class="modal-content m0 p0" >
            <div class="row">
                <div class="col m8 input-field">
                    <input type="text" placeholder="Buscar..." v-model="search_cliente" id="txt_search_cliente" />
                </div>
                <div class="col m4 input field">
                    <button class="btn red darken-2 btn-small"  style="margin-top:12px;" @click="modal_cliente.close()">Cerrar</button> &nbsp;
                    <button class="btn blue-ugb darken-2 btn-small"  style="margin-top:12px;" @click="getClientes(true)">Recargar</button>
                </div>
                <div class="row m0 p0">
                    <div class="col m12">
                        <a href="/ClientesAdmin" class="btn green darken-2 btn-small right" target="_blank">Nuevo Cliente <i class="material-icons right">open_in_new</i></a>
                    </div>
                </div>
                <div class="col m12" style="height: 175px; overflow:auto">
                    <table class="table tbl-condensed" style="font-size: 12px">
                        <thead>
                            <tr>
                                <th style="width: 5%">ID</th>
                                <th style="width: 40%">Nombre</th>
                                <th style="width: 15%">DUI</th>
                                <th style="width: 20%">NIT</th>
                                <th style="width: 10%">NRC</th>
                                <th style="width: 5%"></th>
                                <th style="width: 5%">Act.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cliente in clientes_filter" :key="cliente">
                                <td class="center"><a v-bind:href="'/ClientesAdmin/' + cliente.cardCode" target="_blank">{{ cliente.cardCode }}</a></td>
                                <td><a v-bind:href="'/ClientesAdmin/' + cliente.cardCode" target="_blank">{{ cliente.cardName }} <i class="material-icons right">edit</i></a></td>
                                <td class="center">{{ cliente.u_NDUI }}</td>
                                <td class="center">{{ cliente.cli_mh_nit }}</td>
                                <td class="center">{{ cliente.cli_mh_nrc }}</td>
                                <td class="center"><a href="#!" @click="seleccionarCliente(cliente.cardCode, cliente.cardName, cliente.u_NDUI, cliente.cli_mh_nit, cliente.cli_mh_nrc)"><i class="material-icons">check_circle</i></a></td>
                                <td><label><input type="checkbox" class="filled-in" @click.prevent="" :checked="cliente.cli_mh_actualizado"><span></span></label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-contrasena-clientes" style="width: 600x">
        <div class="modal-content m0 p0">
            <div class="row m0 p0">
                <form method="post" v-on:submit.prevent="anular()" autocomplete="off">
                    <div class="row">
                        <div class="col m12">
                            <label><b>{{factura.clientCardCode }}: </b>{{factura.clientCardName}}</label>
                        </div>
                        <div class="col m12">
                            <label><b>N° DOC. INTERNO:</b> {{ factura.fac_factura }}</label>
                        </div>
                        <div class="col m12">
                            <label><b>CÓDIGO DE GENERACIÓN:</b> {{ factura.fac_codigo_generacion }}</label>
                        </div>
                        <div class="col m12">
                            <label><b>NÚMERO DE CONTROL:</b> {{ factura.fac_numero_control }}</label>
                        </div>
                        <div class="col m12">
                            <label><b>SELLO DE RECIBIDO:</b> {{ factura.fac_sello_recibido }}</label>
                        </div>
                    </div>
                    <div class="row p0 m0">
                        <div class="col m12">
                            <label>Motivo (Máx. 255 caracteres.)</label>
                            <textarea maxlength="255" v-model="factura.fac_motivo_anulacion" class="materialize-textarea" id="txt_motivo"></textarea>
                            <span class="helper-text">{{$validation(validations_cancel, "fac_motivo_anulacion")}}</span>
                        </div>
                    </div>
                    <div class="row p0">
                        <div class="col m4">
                            <label>Contraseña</label>
                            <input type="password" v-model="contrasena" id="txt_contrasena" />
                            <span class="helper-text">{{$validation(validations_cancel, "contrasena")}}</span>
                        </div>
                    </div>
                    <div class="row m0 p0">
                        <div class="col m6">
                            <button type="submit" class="btn blue-ugb" style="width: 100%">Anular</button>
                        </div>
                        <div class="col m6">
                            <button type="button" class="btn red darken-2" @click="modal_autorizar.close()" style="width: 100%">Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div id="modal-buscar-arancel-cliente" class="modal" style="overflow:hidden">
        <div class="modal-content p0">
            <div class="row">
                <div class="col m12">
                    <label>Buscar</label>
                    <input type="text" v-model="search_arancel" id="txt_search_arancel" />
                </div>
            </div>

            <div class="row">
                <div class="col m12" style="height: 200px; overflow: auto">
                    <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                        <thead>
                            <tr>
                                <th style="width: 70%">Arancel</th>
                                <th style="width: 10%">Exento</th>
                                <th style="width: 10%">Valor Base</th>
                                <th style="width: 10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="arancel in aranceles_filter" :key="arancel">
                                <td>{{ arancel.tmo_descripcion }}</td>
                                <td class="center"><label><input type="checkbox" class="filled-in" :checked="arancel.tmo_exento == 'N'" @click.prevent=""/><span></span></label></td>
                                <td style="text-align:right">${{ arancel.tmo_valor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
                                <td class="center"><a href="#!" @click="seleccionarArancel(arancel.tmo_codigo)"><i class="material-icons">check_circle</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputMask from 'primevue/inputmask'
import Datepicker from 'vue3-datepicker'
import M from 'materialize-css'
    export default {
        components: {
            InputMask,
            Datepicker
        },
       data() {
           return {
               facturas: [],
               facturas_view: [],
               factura: {},
               clientes: [],
               usuarios: [],
               usuario: '',
               type: '',
               search_cliente: '',
               search_arancel: '',
               search: '',
               total_pages: 0,
               current_page: 1,
               starting_page: 0,
                ending_page: 0,
                eliminable: false,
                validations: [],
                clientName: '',
                tipo_documento: '',
                aranceles: [],
                meses: [],

                dfa_codtmo: 0,
                dfa_cantidad: 0,
                dfa_mes: 0,
                dfa_valor: 0,
                dfa_iva: 0,

                modales: [],
                modal_facturacion: {},
                modal_cliente: {},
                modal_buscar_arancel: {},

                id_reporte: 1,
                concepto: false,
                version: false,
                iva_excluido: false,
                contrasena: '',
                validations_cancel: [],
                decimal_100: 10000,
                index_editando: -1,
                bancos: []
           }
       },
       async created()
       {
            this.resetForm();
       },
       async mounted()
       {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems, null);
            this.resetForm();
            await this.getFacturas();
            await this.getTipoDocumento();
            await this.getAranceles();
            await this.getClientes();
            await this.getMeses();
            await this.getUsuarios();
            await this.getBancos();
            this.factura.detalle = [];
       },
       methods: {
           async getFacturas(page)
           {
               this.current_page = page ?? 1;
               this.$root.startLoading();
               await this.axios.get('/FacturacionClientes?page=' + this.current_page + '&tipo=' + this.type + '&search=' + this.search + '&user=' + this.usuario)
               .then(response => {
                   this.facturas = response.data.records;
                   this.facturas_view = response.data.records;
                   this.total_pages = response.data.total_pages;
                   this.current_page = response.data.current_page;
                   this.ending_page = this.current_page + 5;
                   this.starting_page = this.current_page - 5;
               });
               this.$root.stopLoading();
           },
           async getFactura(codfac)
           {
               if(codfac == 0)
               {
                   await this.resetForm();
               }
               else
               {
                    this.$root.startLoading();
                   await this.axios.get('/FacturacionClientes/' + codfac)
                   .then(response => {
                        let data = response.data;
                        data.fac_fecha = this.toDateFormat(data.fac_fecha);
                        data.fac_fecha_registro = this.toDateFormat(data.fac_fecha_registro);
                        this.factura = data;
                   });
                   this.$root.stopLoading();
               }
               this.modal_facturacion = M.Modal.getInstance(document.querySelector('#modal-facturacion-cliente'));
               this.modal_facturacion.open();
           },
           toDateFormat(date)
            {
                var day = parseInt(date.substring(0, 2));
                var month = parseInt(date.substring(3,5)) - 1;
                var year = parseInt(date.substring(6,10));
                return new Date(year, month, day);
            },
           async getMeses()
            {
                this.$root.startLoading();
                await this.axios.get('/Meses')
                .then(response => {
                    this.meses = response.data;
                });
                this.$root.stopLoading();
            },
            async getAranceles()
            {
                this.$root.startLoading();
                await this.axios.get('/Aranceles/Clientes')
                .then(response => {
                    this.aranceles = response.data.filter(x=>x.tmo_estado == 'A');
                });
                this.$root.stopLoading();
            },
            async getTipoDocumento()
            {
                this.$root.startLoading();
                await this.axios.get('/TipoDocumento/Cliente')
                .then(response => {
                    this.tipo_documento = response.data;
                });
                this.$root.stopLoading();
            },
            async getTipoEstado()
            {
                this.$root.startLoading();
                await this.axios.get('/TipoEstadoDocumento/Cliente')
                .then(response => {
                    this.tipo_estado = response.data;
                });
                this.$root.stopLoading();
            },
            async getNumeroRecibo()
            {
                this.$root.startLoading();
                await this.axios.get('/Facturacion/Recibo/' + this.factura.fac_tipo)
                .then(response => {
                    this.factura.fac_serie = response.data.serie;
                    this.factura.fac_factura = response.data.recibo;
                })
                .catch(() => {
                    this.factura.fac_serie = '0';
                    this.factura.fac_factura = '0';
                });
                this.$root.stopLoading();          
            },
            async getUsuarios()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionClientes/Usuarios')
                .then(response => {
                    this.usuarios = response.data;
                });
                this.$root.stopLoading();
            },
            async resetForm(valid)
            {
                var _valid = valid || false;
                if(_valid)
                {
                    if(this.factura.fac_codigo == 0 && this.factura.col_dfa_det_fac.length > 0)
                    {
                        if(!confirm('El documento no ha sido guardado y ha agregado aranceles ¿está seguro que desea crear un documento nuevo?')) return false;
                    }
                }
                this.factura = {};
                this.factura.fac_codigo = 0;
                this.factura.fac_tipo = 'F';
                this.factura.fac_serie = '';
                this.factura.fac_fecha = new Date();
                this.factura.fac_fecha_registro = new Date();
                this.factura.fac_tipo_pago = 'E';
                this.factura.fac_tipo_credito = 'T';
                this.factura.fac_estado = 'R';
                this.factura.col_dfa_det_fac = [];
                this.factura.fac_fecha_tarjeta = "";
                this.factura.fac_voucher = "";
                this.factura.fac_impreso = "N";
                this.factura.fac_descripcion = "";
                this.factura.fac_codcli = 0;
                this.factura.fac_pos = '';
                this.factura.fac_codban = 0;

                this.resetDetalle();
                await this.getNumeroRecibo();
            },
            async resetDetalle()
            {
                this.dfa_codtmo = 0;
                this.dfa_mes = new Date().getMonth() + 1;
                this.dfa_cantidad = 1;
                this.dfa_valor = 0;
            },
            async getClientes()
            {
                this.$root.startLoading();
                await this.axios.get('/Clientes')
                .then(response => {
                    this.clientes = response.data;
                })
                this.$root.stopLoading();
            },
            async buscarCliente()
            {
                this.modal_cliente = M.Modal.getInstance(document.querySelector('#modal-seleccionar-cliente-facturacion'));
                this.modal_cliente.open();
                document.querySelector('#txt_search_cliente').focus();
            },
            async seleccionarCliente(cardCode, cardName, cli_mh_dui, cli_mh_nit, cli_mh_nrc)
            {
                var updated = false;
                this.$root.startLoading();
                await this.axios.get('/Clientes/' + cardCode + '/Actualizado')
                .then(response => {
                    if(response.status == 204)
                    {
                        M.toast({html: 'Actualice los datos del cliente.'});
                        return;
                    }
                    else
                    {
                        updated = true;
                    }
                });
                this.$root.stopLoading();
                if(!updated) return false;
                this.factura.fac_codcli = cardCode;
                this.factura.clientCardName = cardName;
                this.factura.clientCardCode = cardCode;
                this.factura.fac_cliente = cardName;
                this.factura.cli_mh_dui = cli_mh_dui || '';
                this.factura.cli_mh_nit = cli_mh_nit || '';
                this.factura.cli_mh_nrc = cli_mh_nrc || '';
                this.modal_cliente.close();
            },
            setValor()
            {
                var result = this.aranceles.find(x=>x.tmo_codigo == this.dfa_codtmo);
                this.dfa_valor = result.tmo_valor;
            },
            async setDetalle()
            {
                var arancel = this.aranceles.find(x=>x.tmo_codigo == this.dfa_codtmo);
                var iva = arancel.tmo_exento == 'S' ? Math.round((this.dfa_valor / 113 * 13) * this.decimal_100)/this.decimal_100:0;

                var monto = this.dfa_valor - iva;
                var detalle = {
                    dfa_codfac: 0,
                    dfa_codigo: 0,
                    dfa_codtmo: this.dfa_codtmo,
                    dfa_cantidad: this.dfa_cantidad,
                    dfa_valor: this.dfa_cantidad * monto,
                    dfa_valor_d: this.dfa_cantidad * monto, //parseFloat(this.dfa_cantidad * monto),
                    dfa_iva: this.dfa_cantidad * iva,
                    dfa_iva_d: this.dfa_cantidad * iva, //parseFloat(this.dfa_cantidad * iva),
                    dfa_fecha_registro: new Date(),
                    dfa_mes: this.dfa_mes,
                    mes_nombre: this.dfa_mes == 0 ? '':this.meses.find(x=>x.mes_codigo == this.dfa_mes).mes_nombre,
                    tmo_descripcion: arancel.tmo_descripcion,
                    dfa_descripcion: arancel.tmo_descripcion
                };
                
               this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/FacturacionClientes/' + this.factura.fac_codigo + '/Detalle', detalle)
                .then(() => {
                    if(this.factura.col_dfa_det_fac == undefined) this.factura.col_dfa_det_fac = [];
                    this.factura.col_dfa_det_fac.push(detalle);
                    this.resetDetalle();
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                });
                this.$root.stopLoading();
            },
            async guardar()
            {
                this.validations = [];
                if(!confirm('¿Desea guardar la factura?\nUna vez guardada no podrá realizar cambios.')) return;
                this.$root.startLoading();
                await this.axios.post('/FacturacionClientes', this.factura)
                .then(async response => {
                    this.factura.fac_codigo = response.data.fac_codigo;
                    await this.setFacturacionElectronica();
                    await this.getFacturas(1);
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async setFacturacionElectronica()
            {
                this.$root.startLoading();
                await this.axios.post('/FacturacionElectronicaAPI/Cliente/' + this.factura.fac_codigo)
                .then(async response => {
                    if(response.status == 200)
                    {
                        this.factura.fac_procesada = true;
                        this.factura.fac_codigo_generacion = response.data.content.transaction.codigoGeneracion;
                        this.factura.fac_numero_control = response.data.content.transaction.numeroControl;
                        this.factura.fac_sello_recibido = response.data.content.transaction.selloRecibido;
                        await this.imprimirFE();
                    }
                })
                .catch((error) => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async verificarFactura(id)
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Verificar/' + id + '/C')
                .then(response => {
                    alert(JSON.stringify(response.data));
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async enviarFactura(codfac)
            {
                this.$root.startLoading();
                await this.axios.post('/FacturacionElectronicaAPI/Cliente/' + codfac)
                .then(response => {
                    alert(JSON.stringify(response.data));
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async actualizar()
            {
                if(!confirm('¿Desea actualizar el documento?')) return;
               
                var payload = {
                    fac_codigo: this.factura.fac_codigo,
                    fac_fecha: this.factura.fac_fecha,
                    fac_codban: this.factura.fac_codban
                };
                this.$root.startLoading();
                await this.axios.put('/FacturacionClientes/' + this.factura.fac_codigo, payload)
                .then(async () => {
                    M.toast({html: 'El documento ha sido actualizado.'});
                    await this.getFacturas(this.current_page);
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            editable()
            {
                /* var es_admin = this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if(es_admin || es_coordinador) return true; */

                if(this.factura.fac_codigo == 0) return true;
                return false;                
            },
            isAdmin()
            {
                return this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
            },
            actualizable()
            {
                if(this.factura.fac_codigo == 0) return false;
                var es_admin = this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if((es_admin || es_coordinador) && this.factura.fac_tipo_pago == 'D') return true;
                return false;
            },
            imprimible()
            {
                if(this.factura.fac_fecha < new Date(2024, 0, 1))
                {
                    return true;
                }
                if(this.factura.fac_tipo != 'D') return false;
                if(this.factura.fac_tipo == 'D' && this.factura.fac_codigo == 0) return false;
                
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if(es_coordinador) return true;
                return this.factura.fac_codigo > 0 && this.factura.fac_impreso == "N" && this.factura.fac_estado == "R" && this.factura.fac_tipo == "D";
            },
            anulable()
            {
                var es_admin = this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
                var es_coordinador = this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
                if(!es_admin && !es_coordinador) return false;
                return this.factura.fac_codigo > 0 && this.factura.fac_estado == 'R';
            },
            async imprimir()
            {
                if(this.iva_excluido == false)
                {
                    if(this.factura.fac_tipo == 'F')
                    {
                        if(!this.version)
                        {
                            this.id_reporte = this.concepto ? 4:3;
                        }
                        if(this.version)
                        {
                            this.id_reporte = this.concepto ? 5:6;
                        }
                    }

                    if(this.factura.fac_tipo == 'C')
                    {
                        this.id_reporte = 1;
                    }

                    if(this.factura.fac_tipo == 'D')
                    {
                        this.id_reporte = 2;
                    }
                }
                else
                {
                    this.id_reporte = 7;
                }
                
                var url_api = '/FacturacionClientes/' + this.factura.fac_codigo + '/Reporte/' + this.id_reporte;
                this.$root.startLoading();
                await this.axios.get(url_api, {
                    responseType: 'blob'
                })
                .then(async response => {

                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    
                    await this.axios.patch('/FacturacionClientes/' + this.factura.fac_codigo + '/Impreso')
                    .then(async () => {
                        window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                        await this.getFacturas(this.current_page);
                    })
                    .catch(error => {
                        M.toast({html: error.response.data.message});
                    });
                });
                this.$root.stopLoading();

            },
            async imprimirFE()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Imprimir/' + this.factura.fac_codigo_generacion, {
                    responseType: 'blob'
                })
                .then(async response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    await this.axios.patch('/FacturacionClientes/' + this.factura.fac_codigo + '/Impreso')
                    .then(async () => {
                        window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');     
                        await this.getFacturas(this.current_page);
                    })
                    .catch(error => {
                        M.toast({html: error.response.data.message});
                    });                           
                })
                .catch(() => {
                    M.toast({html: 'Ha ocurrido un error, intente nuevamente.'});
                });
                this.$root.stopLoading();
            },
            async anular()
            {
                if(!confirm('¿Desea anular este documento?\nUna vez anulado no podrá revertir el cambio.')) return false;
                var data = {
                    fac_codigo: this.factura.fac_codigo,
                    contrasena: this.contrasena,
                    fac_motivo_anulacion: this.factura.fac_motivo_anulacion
                };
                this.validations_cancel = [];
                this.$root.startLoading();
                await this.axios.patch('/FacturacionClientes/' + this.factura.fac_codigo + '/Anular', data)
                .then(async (response) => {
                    this.factura.fac_estado = 'A';
                    this.modal_autorizar.close();
                    M.toast({html: response.data.message});
                    await this.getFacturas(this.current_page);
                })
                .catch(error => {
                    if(error.response.status == 400 && error.response.data.type == 'M')
                    {
                        this.validations_cancel = error.response.data.modelErrors;
                    }
                    if(error.response.status == 400 && error.response.data.type == 'C')
                    {
                        this.contrasena = '';
                        M.toast({html: error.response.data.message});
                    }
                    if(error.response.status == 404)
                    {
                        M.toast({html: 'El documento no fué encontrado.'});
                    }
                });
                this.$root.stopLoading();
            },
            modalAutorizar()
            {
                this.contrasena = '';
                this.factura.fac_motivo_anulacion = '';
                this.validations_cancel = [];
                this.modal_autorizar = M.Modal.getInstance(document.querySelector('#modal-contrasena-clientes'));
                this.modal_autorizar.open();
                document.querySelector('#txt_motivo').focus();
                return;
            },
            buscarArancel()
            {
                this.modal_buscar_arancel = M.Modal.getInstance(document.querySelector('#modal-buscar-arancel-cliente'));
                this.modal_buscar_arancel.open();
                document.querySelector('#txt_search_arancel').focus();
            },
            seleccionarArancel(codtmo)
            {
                this.dfa_codtmo = codtmo;
                this.setValor();
                this.modal_buscar_arancel.close();
            },
            async buscarMovimiento()
            {
                this.current_page = 1;
                this.search = document.querySelector('#txt_buscar_movimiento').value;
                await this.getFacturas(1);
            },
            sin_correlativo()
            {
                //return false;
                return isNaN(this.factura.fac_factura) || parseInt(this.factura.fac_factura) == 0;
            },
            cerrarModal()
            {
                this.modal_facturacion.close();
            },
            editarDetalle(index)
            {
                this.index_editando = index;
            },
            guardarEditarDetalle(index)
            {
                this.factura.col_dfa_det_fac[index].dfa_descripcion = this.factura.col_dfa_det_fac[index].dfa_descripcion.trim();
                var detalle = this.factura.col_dfa_det_fac[index].dfa_descripcion
                detalle = detalle.replaceAll(' ', '');
                if(detalle.length == 0)
                {
                    this.factura.col_dfa_det_fac[index].dfa_descripcion = this.factura.col_dfa_det_fac[index].tmo_descripcion;
                    this.index_editando = -1
                    return;
                }
                if(this.factura.col_dfa_det_fac[index].dfa_descripcion.length < 5)
                {
                    M.toast({html: 'Debe agregar al menos 5 caracteres.'});
                    return;
                }
                if(this.factura.col_dfa_det_fac[index].dfa_descripcion.length > 2000)
                {
                    M.toast({html: 'Agregue máximo 2000 caracteres. Ha agregado '+this.factura.col_dfa_det_fac[index].dfa_descripcion.length+'.'});
                    return;
                }
                this.index_editando = -1
            },
            async getBancos()
            {
                await this.axios.get('/Bancos')
                .then(response => {
                    this.bancos = response.data;
                });
            },
            setBanco()
            {
                if(this.factura.fac_tipo_pago != 'D')
                {
                    this.factura.fac_codban = 0;
                }
            }
       },
       computed: {
            clientes_filter()
            {
                return this.clientes.filter(x=>
                                                x.cardName.toLowerCase().indexOf(this.search_cliente.toLocaleLowerCase()) != -1 ||
                                                x.u_NDUI.toLowerCase().indexOf(this.search_cliente.toLocaleLowerCase()) != -1 
                                            );
            },
            total_dfa_iva()
            {
                if(this.factura.col_dfa_det_fac == undefined) return 0;
                return this.factura.col_dfa_det_fac.reduce((a, b) => a + b.dfa_iva, 0);
            },
            total_dfa_valor()
            {
                if(this.factura.col_dfa_det_fac == undefined) return 0;
                return this.factura.col_dfa_det_fac.reduce((a, b) => a + b.dfa_valor, 0);
            },
            aranceles_filter()
            {
                return this.aranceles.filter(x=>
                    x.tmo_descripcion.toLowerCase().indexOf(this.search_arancel.toLocaleLowerCase()) != -1
                );
            }
       }
    }
</script>

<style scoped>
    input:disabled, select:disabled
    {
        color: black
    }

    .motivo-anulacion {
        padding: 5px;
        bottom: 0px;
        left: 0px;
        color: white;
        background-color: #b71c1c   
    }
</style>