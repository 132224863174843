<template>
    <div class="row m0 p0">
        <div class="col m3">
            <div class="collection">
                <template v-for="(reporte, index) in reportes" v-bind:key="reporte">
                    <a href="#!" @click="seleccionar(index)" class="collection-item" v-bind:class="{'active blue-ugb': (codrep == reporte.rep_codigo)}">{{reporte.rep_nombre}}</a>
                </template>
            </div>
        </div>
        <div class="col m4 card-panel" v-show="codrep > 0">
            <form @submit.prevent="imprimir()">
                <template v-for="detalle in reporte.sap_der_detalle_reporte" v-bind:key="detalle">
                    <div class="row">
                        <div class="col m12">
                            <label>{{detalle.der_descripcion}}</label>
                            <input :required="detalle.der_obligatorio" v-model="detalle.der_valor" v-if="!detalle.der_select && !detalle.der_fecha" type="text" v-bind:id="'campo_' + detalle.der_codigo">
                            <select style="color:black" :disabled="detalle.datos_select_reporte != undefined && detalle.datos_select_reporte.length == 1" :required="detalle.der_obligatorio" @change="updateSelect($event, detalle.der_codigo + 1)" class="browser-default" v-if="detalle.der_select" v-bind:id="'campo_' + detalle.der_codigo">
                                <option style="color:black" v-for="dato in detalle.datos_select_reporte" v-bind:value="dato.id" v-bind:key="dato">{{dato.nombre}}</option>
                            </select>
                            <datepicker :required="detalle.der_obligatorio" v-if="!detalle.der_select && detalle.der_fecha" v-model="detalle.value" v-bind:id="'campo_' + detalle.der_codigo" inputFormat="dd/MM/yyyy" />
                        </div>
                    </div>
                </template>
                <div class="row">
                    <div class="col m12">
                        <button type="submit" class="btn blue-ugb">Imprimir <i class="material-icons right">print</i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import M from 'materialize-css'
    export default {
        data(){
            return {
                reportes: [],
                reporte: {},
                codrep: 0
            }
        },
        components: {
            //InputMask,
            Datepicker
        },
        async mounted()
        {
            this.$root.startLoading();
            await this.axios.get('/Reportes')
            .then(response => {
                this.reportes = response.data;
            });
            this.$root.stopLoading();
        },
        methods: {
            async seleccionar(index)
            {
                this.codrep = this.reportes[index].rep_codigo;
                this.reporte = this.reportes[index];
                for(let k = 0; k <= this.reporte.sap_der_detalle_reporte.length - 1; k++)
                {
                    if(!this.reporte.sap_der_detalle_reporte[k].der_select && this.reporte.sap_der_detalle_reporte[k].der_fecha)
                    {
                        this.reporte.sap_der_detalle_reporte[k].value = new Date();
                    }
                    await this.updateSelect(null, this.reporte.sap_der_detalle_reporte[k].der_codigo);
                }
            },
            async updateSelect(event, der_codigo)
            {
                var campos_anteriores = this.reporte.sap_der_detalle_reporte.filter(x=>x.der_codigo < der_codigo);
                var index_campo = this.reporte.sap_der_detalle_reporte.findIndex(x=>x.der_codigo == der_codigo);
                if(index_campo == -1) return;

                var campos = [];
                for(let j = 0; j <= campos_anteriores.length - 1; j++)
                {
                    campos.push({
                        key: this.reporte.sap_der_detalle_reporte[j].der_campo,
                        value: document.querySelector('#campo_' + this.reporte.sap_der_detalle_reporte[j].der_codigo).value
                    });
                    /* if(der_codigo <= this.reporte.sap_der_detalle_reporte[j].der_codigo)
                        document.querySelector('#campo_' + this.reporte.sap_der_detalle_reporte[j].der_codigo).selectedIndex = 0 */
                    //console.log(document.querySelector('#campo_' + this.reporte.sap_der_detalle_reporte[j].der_codigo).selectedIndex = 0);
                }

                if(this.reporte.sap_der_detalle_reporte[index_campo].der_select)
                {
                    this.$root.startLoading();
                    await this.axios.post('/Reportes/Datos/' + this.reporte.rep_codigo + '/' + this.reporte.sap_der_detalle_reporte[index_campo].der_codigo, campos)
                    .then(response => {
                        this.reporte.sap_der_detalle_reporte[index_campo].datos_select_reporte = response.data;                        
                    });
                    this.$root.stopLoading();
                }

                if(index_campo < this.reporte.sap_der_detalle_reporte.length - 1)
                {
                    for(let i = index_campo + 1; i <= this.reporte.sap_der_detalle_reporte.length - 1; i++)
                    {
                        await this.updateSelect(null, this.reporte.sap_der_detalle_reporte[i].der_codigo);
                        //document.querySelector('#campo_' + this.reporte.sap_der_detalle_reporte[i].der_codigo).selectedIndex = 0;
                    }
                }
                
                /*if(event != null && campos_anteriores.find(x=> "campo_" + x.der_codigo != event.target.id) != undefined)
                {
                    return false;
                }
                
                console.log(campos_anteriores);                
                if(event != null)
                {
                    console.log(event.target);
                    document.querySelectorAll('select').forEach(x=>{
                        if(event != null && x.id != event.target.id)
                            x.selectedIndex = 0
                    });
                }
                else
                {
                    document.querySelectorAll('select').forEach(x=>{
                            x.selectedIndex = 0
                    });
                }*/
            },
            async imprimir()
            {
                var campos = [];
                for(let j = 0; j <= this.reporte.sap_der_detalle_reporte.length - 1; j++)
                {
                    campos.push({
                        key: this.reporte.sap_der_detalle_reporte[j].der_campo,
                        value: document.querySelector('#campo_' + this.reporte.sap_der_detalle_reporte[j].der_codigo).value
                    });
                }

                var data = {
                    rep_codigo: this.reporte.rep_codigo,
                    parameters: campos
                };

                this.$root.startLoading();
                await this.axios.post('/Reportes/Imprimir/' + data.rep_codigo, data, {
                    responseType: 'blob'
                })
                .then(response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                })
                .catch(() => {
                    M.toast({html: 'Llene los campos correctamente.'});
                });
                this.$root.stopLoading();              
            }
        }
    }
</script>