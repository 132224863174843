<template>
    <div class="row">
        <div class="col m12 card-panel" style="position:relative; background-color: #1c21d1;">
            <a href="#!" @click="getFactura(0)" title="Nuevo"><i class="material-icons right white-text" style="font-size:40px">add_circle</i></a>
            <form @submit.prevent="buscarMovimiento">
                <div class="col m4">
                    <label class="white-text">Buscar</label>
                    <input type="text" id="txt_buscar_movimiento" class="white-text">
                </div>
                <div class="col m1">
                    <button type="submit" class="btn white black-text"><i class="material-icons">search</i></button>
                </div>
            </form>
            <div class="col m2">
                <label class="white-text">Tipo de Documento</label>
                <select class="browser-default" @change="getMovimientos(1)" v-model="type">
                    <option value="">Todos</option>
                    <option v-for="tipo in tipo_documento" v-bind:key="tipo" v-bind:value="tipo.tdo_sigla">{{tipo.tdo_nombre}}</option>
                </select>
            </div>
            <div class="col m2">
                <label class="white-text">Usuario</label>
                <select class="browser-default" @change="getMovimientos(1)" v-model="usuario">
                    <option value="">Todos</option>
                    <option v-for="usuario in usuarios" v-bind:key="usuario" v-bind:value="usuario">{{usuario}}</option>
                </select>
            </div>            
        </div>
        <div class="row m0 p0">
            <div class="col m12 m0 p0" style="width: 100%">
                <div style="overflow: auto; overflow-y:hidden;">
                    <table class="table tbl-condensed bordered striped white tbl-bordered" style="font-size:12px;" v-show="movimientos.length > 0" >
                        <thead>
                            <tr>
                                <th style="width:18%"># Doc.</th>
                                <th style="width:8%">Tipo</TH>
                                <th style="width:7%">Carnet</th>
                                <th style="width:25%">Nombre</th>
                                <th style="width:5%">Total</th>
                                <th style="width:7%">Ciclo</th>
                                <th style="width:6%">Fecha<br>Facturación</th>
                                <th style="width:5%">Estado</th>
                                <th style="width:4%">Impreso</th>
                                <th style="width:5%" v-if="isAdmin() || isCoordinador()" class="center"><label><input @change="buscarMovimiento()" v-model="pending_fe" type="checkbox" class="filled-in"><span></span></label></th>
                                <th style="width:5%">Usuario</th>
                                <th style="width:5%">Fecha<br>Registro</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="movimiento in movimientos" v-bind:key="movimiento">
                                <td class="center">
                                    <a href="#!" @click="getFactura(movimiento.mov_codigo)">{{movimiento.mov_recibo}}</a>
                                    <br>
                                    <span style="font-size: 10px">{{ movimiento.mov_mh_codigo_generacion }}</span>
                                </td>
                                <td class="center">
                                    {{movimiento.tdo_nombre}}
                                </td>
                                <td class="center">{{movimiento.per_carnet}}</td>
                                <td>{{movimiento.per_apellidos_nombres}}</td>
                                <td style="text-align: right;">${{movimiento.mov_total.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td class="center">{{movimiento.cic_nombre}}-{{movimiento.cil_anio}}</td>
                                <td class="center">{{movimiento.mov_fecha}}</td>
                                <td class="center">{{movimiento.ted_nombre}}</td>
                                <td class="center"><label><input type="checkbox" class="filled-in" @click.prevent="" :checked="movimiento.mov_impreso == 'S'" ><span></span></label></td>
                                <td class="center" v-if="isAdmin() || isCoordinador()">
                                    <button type="button" class="btn green darken-2 btn-small" @click="verificarFactura(movimiento.mov_mh_codigo_generacion)" style="width: 100%"><i class="material-icons">check</i></button>
                                    <button type="button" class="btn red darken-4 btn-small" @click="enviarFactura(movimiento.mov_codigo)" v-bind:disabled="movimiento.mov_mh_procesada" style="width: 100%"><i class="material-icons">send</i></button>
                                </td>
                                <td class="center" style="font-size:10px">{{$spliceString(movimiento.mov_usuario, 0, 10)}}</td>
                                <td class="center">{{movimiento.mov_fecha_registro}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <ul class="pagination card-panel white" style="border:solid 1px #bdbdbd">
                    <li v-if="current_page != 1"><a href="#!" @click="getMovimientos(1)"><i class="material-icons">first_page</i></a></li>
                    <li v-if="current_page > 1"><a href="#!" @click="getMovimientos(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
                    <template v-for="n in total_pages" v-bind:key="n">
                        <li v-if="n > starting_page && n < ending_page" @click="getMovimientos(n)" v-bind:class="{active: n == current_page}"><a href="#!">{{n}}</a></li>
                    </template>
                    <li v-if="current_page < total_pages"><a href="#!" @click="getMovimientos(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
                    <li v-if="current_page != total_pages && movimientos.length > 0"><a href="#!" @click="getMovimientos(total_pages)"><i class="material-icons">last_page</i></a></li>
                </ul>
            </div>
        </div>
    </div>
    
    <div class="modal" id="modal-facturacion" style="min-width:95%; max-height:575px; top:5%; border: solid 1px white; background-color: #1c21d1;">
        <div class="modal-content white">
            <FormularioFacturacion ref="formularioFacturacion" />
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-buscar-estudiante" style="border: solid 1px white; width:80%">
        <div class="modal-content white">
            <BuscarEstudiante ref="buscarEstudiante" />
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-costos" style="border: solid 1px white">
        <div class="modal-content white">            
            <div class="row m0 p0">
                <div class="col m12">
                    <CostosFacturacion ref="costosFacturacion"></CostosFacturacion>
                </div>
            </div>
            <br>
            <div class="row m0 p0">
                <div class="col m12">
                    <button class="modal-close btn red darken-2 right btn-small">Cerrar <i class="material-icons right">close</i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="notas-estudiante" v-show="$refs.formularioFacturacion.planes_pago.length > 0 || $refs.formularioFacturacion.autofinanciadas.length > 0" v-if="$refs.formularioFacturacion != undefined">
        <ul class="collection m0 p0" v-show="$refs.formularioFacturacion.planes_pago.length > 0">
            <li class="collection-item red darken-4 white-text center">PLAN DE PAGOS <i class="material-icons right" style="font-size:20px">warning</i></li>
            <template v-for="plan in $refs.formularioFacturacion.planes_pago" v-bind:key="plan">
                <li class="collection-item center"><a href="#!" @click="detallePlan()">{{plan.ciclo}} - {{plan.anio}}</a></li> 
            </template>
        </ul>      
        <ul class="collection m0 p0" v-show="$refs.formularioFacturacion.autofinanciadas.length > 0">
            <li class="collection-item red darken-4 white-text center">AUTOFINANCIADAS <i class="material-icons right" style="font-size:20px">warning</i></li>
            <template v-for="autofinanciada in $refs.formularioFacturacion.autofinanciadas" v-bind:key="autofinanciada">
                <li class="collection-item center"><a href="#!" @click="detalleAutofinanciada()">{{autofinanciada.ciclo}} - {{autofinanciada.anio}}</a></li>
            </template>
        </ul>  
    </div>

    <div class="modal" id="modal-detalle-plan-pago">
        <div class="modal-content">
            <div class="row" v-if="$refs.formularioFacturacion != undefined">
                <div class="col m12">
                    <table class="table striped tbl-condensed tbl-bordered">
                        <caption>PLANES DE PAGO</caption>
                        <tbody>
                            <template v-for="plan in $refs.formularioFacturacion.planes_pago" v-bind:key="plan">
                                <tr>
                                    <th colspan="4" class="center">{{plan.ciclo}} - {{plan.anio}}</th>
                                </tr>
                                <tr>
                                    <th class="center" style="width:50%">Arancel</th>
                                    <th class="center" style="width:15%">Con Recargo</th>                                
                                    <th class="center" style="width:20%">Valor</th>
                                    <th class="center" style="width:15%">Mora Incluida</th>
                                </tr>
                                <tr v-for="cuota in plan.plan" v-bind:key="cuota">
                                    <td>{{cuota.arancel}}</td>
                                    <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.conRecargo"><span></span></label></td>                                
                                    <td class="center">${{cuota.valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                    <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.moraIncluida"><span></span></label></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close btn red darken-4"><i class="material-icons">close</i></a>
        </div>
    </div>

    <div class="modal" id="modal-detalle-autofinanciada">
        <div class="modal-content">
            <div class="row" v-if="$refs.formularioFacturacion != undefined">
                <div class="col m12">
                    <table class="table striped tbl-bordered tbl-condensed" style="font-size:12px">
                        <caption>MATERIAS AUTOFINANCIADAS Y PAGOS EN CUOTAS</caption>
                        <!-- <tbody>
                            <template v-for="(autofinanciada, index_plan) in $refs.formularioFacturacion.autofinanciadas" v-bind:key="autofinanciada">
                                <tr>
                                    <th colspan="4" class="center">{{autofinanciada.ciclo}} - {{autofinanciada.anio}}</th>
                                </tr>
                                <tr>
                                    <th class="center">Arancel/Materia</th>
                                    <th class="center">Con Recargo</th>                               
                                    <th class="center">Valor</th>
                                    <th></th>
                                </tr>
                                <tr v-for="(cuota, index_cuota) in autofinanciada.cuotas" v-bind:key="cuota">
                                    <td>{{cuota.arancel}}</td>                              
                                    <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.conRecargo"><span></span></label></td>
                                    <td class="center">${{cuota.valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                    <td class="center">
                                        <a href="#!" v-if="!cuota.checked" @click="seleccionarCuotaAutofinanciada(index_plan, index_cuota, cuota.codigoArancel, cuota.codigoPago, cuota.codigo, cuota.valor, true)"><i class="material-icons">add_circle</i></a>
                                        <a href="#!" v-if="cuota.checked" @click="seleccionarCuotaAutofinanciada(index_plan, index_cuota, cuota.codigoArancel, cuota.codigoPago, cuota.codigo, cuota.valor, false)"><i class="material-icons">remove_circle</i></a>
                                    </td>
                                </tr>
                            </template>
                        </tbody> -->

                        <tbody>
                            <template v-for="(ciclo, index_ciclo) in $refs.formularioFacturacion.autofinanciadas" v-bind:key="ciclo">
                                <tr>
                                    <th colspan="4" class="center">{{ciclo.ciclo}} - {{ciclo.anio}}</th>
                                </tr>
                                <template v-for="(arancel, index_arancel) in ciclo.aranceles" :key="arancel">
                                    <tr>
                                        <th colspan="4" class="center">Plan #{{arancel.codigoPago}}</th>
                                    </tr>
                                    <tr>
                                        <th class="center">Arancel/Materia</th>
                                        <th class="center">Con Recargo</th>                               
                                        <th class="center">Valor</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(cuota, index_cuota) in arancel.cuotas" v-bind:key="cuota">
                                        <td>{{cuota.arancel}}</td>                              
                                        <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.conRecargo"><span></span></label></td>
                                        <td class="center">${{cuota.valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                        <td class="center">
                                            <a href="#!" v-if="!cuota.checked" @click="seleccionarCuotaAutofinanciada(index_ciclo, index_arancel, index_cuota, arancel.codigoArancel, cuota.codigoPago, cuota.codigo, cuota.valor, true)"><i class="material-icons">add_circle</i></a>
                                            <a href="#!" v-if="cuota.checked" @click="seleccionarCuotaAutofinanciada(index_ciclo, index_arancel, index_cuota, arancel.codigoArancel, cuota.codigoPago, cuota.codigo, cuota.valor, false)"><i class="material-icons">remove_circle</i></a>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close btn red darken-4"><i class="material-icons">close</i></a>
        </div>
    </div>

    <div class="modal" id="modal-datos-factura-electronica">
        <div class="model-content" style="font-size: 12px;">
            <div class="row m0 p0">
                <div class="col m3">
                    <label>Estado</label>
                    <p>{{ estado_factura.message }}</p>
                </div>
            </div>
            <div class="row m0 p0" v-if="estado_factura.transaction != null">
                <div class="col m12 s12">
                    <label>Codigo Generación</label>
                    <p>{{ estado_factura.transaction.codigoGeneracion }}</p>
                </div>
                <div class="col m12 s12">
                    <label>N° de Control</label>
                    <p>{{ estado_factura.transaction.numeroControl }}</p>
                </div>
                <div class="col m12 s12">
                    <label>Sello</label>
                    <p>{{ estado_factura.transaction.selloRecibido }}</p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close btn blue-ugb">Aceptar</a>
        </div>
    </div>
</template>

<script>
    import FormularioFacturacion from '../components/FormularioFacturacion.vue'
    import BuscarEstudiante from '../components/BuscarEstudiante.vue'
    import CostosFacturacion from '../components/CostosFacturacion.vue'
    import M from 'materialize-css'
    export default
    {
        name: 'Facturacion',
        components: {
            FormularioFacturacion,
            BuscarEstudiante,
            CostosFacturacion
        },
        data(){
            return {
                movimientos: [],
                total_pages: 0,
                current_page: 1,
                starting_page: 0,
                ending_page: 0,
                search: '',
                type: '',
                modal_instances: [],
                modal_facturacion: null,
                modal_buscar_estudiante: null,
                modal_costos: null,
                tipo_documento: [],
                estudiante: {},
                modal_opened: false,
                modal_detalle_plan: {},
                modal_detalle_autofinanciada: {},
                usuarios: [],
                usuario: '',
                pending_fe: false,
                decimal_100: 10000,
                modal_estado_factura: {},
                estado_factura: {}
            }
        },
        async created()
        {
            this.$root.startLoading();
            await this.getUsuarios();
            await this.getMovimientos(1);
            await this.axios.get('/TipoDocumento')
            .then(response => {
                this.tipo_documento = response.data;
            });
            this.estado_factura.transaction = {};
            this.$root.stopLoading();
        },
        mounted()
        {
            this.modal_instances = M.Modal.init(document.querySelectorAll('.modal'), {
                dismissible: false,
                onOpenEnd: () => {
                    if(document.querySelector('#txt_search_estudiante') != undefined)
                        document.querySelector('#txt_search_estudiante').focus();
                }
            });
        },
        methods: {
            async getUsuarios()
            {
                await this.axios.get('/Movimientos/Usuarios')
                .then(response => {
                    this.usuarios = response.data;
                });
            },
            async getMovimientos(page) {
                    this.$root.startLoading();
                    await this.axios.get('/Movimientos?page=' + page + '&search=' + this.search + '&type=' + this.type + '&usuario=' + this.usuario + '&pending_fe=' + this.pending_fe)
                    .then(response => {
                        this.movimientos = response.data.records;
                        this.total_pages = response.data.total_pages;
                        this.current_page = response.data.current_page;
                        this.ending_page = this.current_page + 5;
                        this.starting_page = this.current_page - 5;
                    });
                    this.$root.stopLoading();
                },
            async verDocumento(codmov) {
                    this.$root.startLoading();
                    await this.axios.get('/Movimientos/' + codmov)
                    .then(response => {
                        this.movimiento = response.data;
                    });
                    this.$root.stopLoading();
                },
            async getFactura(codmov)
            {
                this.$root.startLoading();
                await this.$refs.formularioFacturacion.init(codmov);
                this.modal_facturacion = M.Modal.getInstance(document.querySelector('#modal-facturacion'));
                this.modal_facturacion.open();
                this.$root.stopLoading();
                document.querySelector('#modal-facturacion').style.top = '5%';
            },
            async buscarEstudiante()
            {
                await this.$refs.buscarEstudiante.init();
                this.modal_buscar_estudiante = M.Modal.getInstance(document.querySelector('#modal-buscar-estudiante'));
                this.modal_buscar_estudiante.open();
            },
            async postBackSeleccionarEstudiante(estudiante)
            {
                this.$refs.formularioFacturacion.setEstudiante(estudiante);
                this.modal_buscar_estudiante.close();
            },
            async buscarMovimiento()
            {
                this.current_page = 1;
                this.search = document.querySelector('#txt_buscar_movimiento').value;
                await this.getMovimientos(1);
            },
            mostrarAnulacion(movimiento)
            {
                return movimiento.mov_estado == 'R' || movimiento.mov_exportado == 'N';
            },
            async getCostos(tipo, codcil, codcar, codper)
            {
                await this.$refs.costosFacturacion.init(tipo, codcil, codcar, codper);
                this.modal_costos = M.Modal.getInstance(document.querySelector('#modal-costos'));
                this.modal_costos.open();
            },
            detallePlan()
            {
                this.modal_detalle_plan = M.Modal.getInstance(document.querySelector('#modal-detalle-plan-pago'));
                this.modal_detalle_plan.open();
            },
            detalleAutofinanciada()
            {
                this.modal_detalle_autofinanciada = M.Modal.getInstance(document.querySelector('#modal-detalle-autofinanciada'));
                this.modal_detalle_autofinanciada.open();
            },
            isAdmin()
            {
                return this.$store.state.roles.find(x=>x.rsa_codigo == 1) != undefined;
            },
            isCoordinador()
            {
                return this.$store.state.roles.find(x=>x.rsa_codigo == 3) != undefined;
            },
            async verificarFactura(id)
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Verificar/' + id + '/E')
                .then(response => {
                    this.estado_factura = response.data.content;
                    this.modal_estado_factura = M.Modal.getInstance(document.querySelector('#modal-datos-factura-electronica'));
                    this.modal_estado_factura.open();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async enviarFactura(codmov)
            {
                this.$root.startLoading();
                await this.axios.post('/FacturacionElectronicaAPI/Portal/' + codmov)
                .then(response => {
                    //alert(JSON.stringify(response.data));
                    this.estado_factura = response.data.content;
                    this.modal_estado_factura = M.Modal.getInstance(document.querySelector('#modal-datos-factura-electronica'));
                    this.modal_estado_factura.open();
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            permitirSeleccionarCuotaAutofinanciada(codcil)
            {
                var mov_codcil = this.$refs.formularioFacturacion.mov_codcil;
                return codcil == mov_codcil;
            },
            seleccionarCuotaAutofinanciada(index_ciclo, index_arancel, index_cuota, codtmo, codmaut, cuota, valor, estado)
            {
                var arancel = this.$refs.formularioFacturacion.aranceles.find(x=>x.tmo_codigo == codtmo);
                if(estado)
                {
                    var maut_codcil = this.$refs.formularioFacturacion.autofinanciadas[index_ciclo].codigoCiclo;
                    var mov_codcil = this.$refs.formularioFacturacion.mov_codcil;
                    if(maut_codcil != mov_codcil)
                    {
                        M.toast({html: 'El plan de pagos no pertenece al ciclo seleccionado.'});
                        return;
                    }

                    if(this.$refs.formularioFacturacion.autofinanciadas[index_ciclo].aranceles[index_arancel].cuotas.findIndex(x=>!x.checked) < index_cuota)
                    {
                        M.toast({html: 'Debe seleccionar la cuota mínima anterior.'});
                        return;
                    }
                    var dmo_valor = arancel.tmo_exento == 'S' ? valor / (1 + this.iva):valor;
                    var dmo_iva = valor - dmo_valor;
                    var data = {
                        "dmo_codigo": 0,
                        "tmo_codigo": arancel.tmo_codigo,
                        "dmo_codtmo": codtmo,
                        "tmo_descripcion": this.$refs.formularioFacturacion.autofinanciadas[index_ciclo].aranceles[index_arancel].cuotas[index_cuota].arancel,
                        "mes_nombre": '',
                        "dmo_codmat": '',
                        "mat_codigo": '',
                        "mat_nombre": '',
                        "dmo_descuento": 0,
                        "dmo_valor": Math.round(dmo_valor * this.decimal_100) / this.decimal_100,
                        "dmo_iva": Math.round(dmo_iva * this.decimal_100) / this.decimal_100,
                        "tmo_codtmo_req": arancel.tmo_codtmo_req,
                        "tmo_codtmo_req_v": arancel.tmo_codtmo_req_v,
                        "dmo_cantidad": 1,
                        "dmo_mes": '',
                        "maut_codigo": codmaut,
                        "dmau_cuota": cuota
                    };
                    this.$refs.formularioFacturacion.detalle_movimiento.push(data);
                }
                else
                {
                    if(this.$refs.formularioFacturacion.autofinanciadas[index_ciclo].aranceles[index_arancel].cuotas.filter(x=>x.checked).length - 1 != index_cuota)
                    {
                        M.toast({html: 'Debe desmarcar la última cuota de manera descendente.'});
                        return;
                    }
                    var index_detalle = this.$refs.formularioFacturacion.detalle_movimiento.findIndex(x=>x.maut_codigo == codmaut && x.dmau_cuota == cuota && x.dmo_codtmo == arancel.tmo_codigo);
                    this.$refs.formularioFacturacion.detalle_movimiento.splice(index_detalle, 1);
                }
                this.$refs.formularioFacturacion.autofinanciadas[index_ciclo].aranceles[index_arancel].cuotas[index_cuota].checked = estado;
            }
        }
    }
</script>

<style scoped>
    .notas-estudiante
    {
        position:fixed;
        bottom: 0px;
        left: 0px;
        margin:0px;
        z-index: 1004;
        padding:0px;
        font-size:12px;
        border:solid 1px grey;
        box-shadow: -1px 9px 33px -14px rgba(0,0,0,0.75);
    }

    .notas-estudiante ul li{
        padding: 0px !important;
        margin: 0px !important
    }
</style>