<template>
    <ul id="dd_configuraciones" class="dropdown-content" style="font-size:12px">
        <li v-if="$store.state.isAuthenticated && showMenu('Aranceles')" v-bind:class="{'black': $route.name == 'Aranceles'}"><router-link to="/Aranceles">Aranceles</router-link></li>
        <li v-if="$store.state.isAuthenticated && showMenu('Tirajes')" v-bind:class="{'black': $route.name == 'Tirajes'}"><router-link to="/Tirajes">Tirajes</router-link></li>
        <li v-if="$store.state.isAuthenticated && showMenu('FechasLimites')" v-bind:class="{'black': $route.name == 'FechasLimites'}"><router-link to="/FechasLimites">Fechas Límites</router-link></li>
        <li v-if="$store.state.isAuthenticated && showMenu('ArancelesCarrera')" v-bind:class="{'black': $route.name == 'ArancelesCarrera'}"><router-link to="/ArancelesCarrera">Aranceles Carrera</router-link></li>
        <li v-if="$store.state.isAuthenticated && showMenu('Clientes')" v-bind:class="{'black': $route.name == 'ClientesAdmin'}"><router-link to="/ClientesAdmin">Clientes</router-link></li>
        <li class="divider" tabindex="-1"></li>
        <li v-if="$store.state.isAuthenticated && showMenu('Estudiantes')" v-bind:class="{'black': $route.name == 'Estudiantes'}"><router-link to="/Estudiantes">Estudiantes</router-link></li>
        <li class="divider" tabindex="-1"></li>
        <li v-if="$store.state.isAuthenticated && showMenu('ConfigurarReportes')" v-bind:class="{'black': $route.name == 'ConfigurarReportes'}"><router-link to="/ConfigurarReportes">Reportes</router-link></li>
        <li v-if="$store.state.isAuthenticated && showMenu('Usuarios')" v-bind:class="{'black': $route.name == 'Usuarios'}"><router-link to="/Usuarios">Usuarios</router-link></li>
    </ul>

    <ul id="dd_planes_pago" class="dropdown-content" style="font-size:12px">
        <li v-if="$store.state.isAuthenticated" v-bind:class="{'black': $route.name == 'PagosCuotas'}"><router-link to="/PagosCuotas">Pagos en Cuotas</router-link></li>
        <li v-if="$store.state.isAuthenticated" v-bind:class="{'black': $route.name == 'MateriaSolicitadas'}"><router-link to="/MateriaSolicitadas">Mat. Solicitadas</router-link></li>
    </ul>
    
    <nav>
        <div class="nav-wrapper" style="padding: 0px 50px 0px 50px; background-color: #1c21d1;">
            <router-link to="/" class="brand-logo"><img src="https://repositorio.ugb.edu.sv/resources/img/ugb/logos_nuevos/UGB_300x100_blanco.png" style="height: 60px;"></router-link>
            <ul id="nav-mobile" class="right hide-on-med-and-down">
                <li v-if="$store.state.isAuthenticated && showMenu('Home')" v-bind:class="{'black': $route.name == 'Home'}"><router-link to="/">Inicio</router-link></li>
                <li v-if="$store.state.isAuthenticated && showMenu('Facturacion')" v-bind:class="{'black': $route.name == 'Facturacion'}"><router-link to="/Facturacion">Facturacion</router-link></li>
                <li v-if="$store.state.isAuthenticated && showMenu('FacturacionClientes')" v-bind:class="{'black': $route.name == 'FacturacionClientes'}"><router-link to="/FacturacionClientes">Clientes</router-link></li>
                <!-- <li v-if="$store.state.isAuthenticated && showMenu('Modificar')" v-bind:class="{'black': $route.name == 'Modificar'}"><router-link to="/Modificar">Modificar</router-link></li> -->
                <!-- <li v-if="$store.state.isAuthenticated && showMenu('FacturaDiaria')" v-bind:class="{'black': $route.name == 'FacturaDiaria'}"><router-link to="/FacturaDiaria">Factura Diaria</router-link></li> -->
                <li v-if="$store.state.isAuthenticated && showMenu('Comprobantes')" v-bind:class="{'black': $route.name == 'Comprobantes'}"><router-link to="/Comprobantes">Comprobantes</router-link></li>
                <li v-if="$store.state.isAuthenticated && showMenu('Reportes')" v-bind:class="{'black': $route.name == 'Reportes'}"><router-link to="/Reportes">Reportes</router-link></li>
                <li v-if="$store.state.isAuthenticated && showMenu('EnlacePago')" v-bind:class="{'black': $route.name == 'EnlacePago'}"><router-link to="/EnlacePago">Pagos</router-link></li>
                <li v-if="$store.state.isAuthenticated && showMenu('PlanesPago')"><a class="dropdown-trigger" href="#!" data-target="dd_planes_pago">Planes de Pago<i class="material-icons right">arrow_drop_down</i></a></li>
                <li v-if="$store.state.isAuthenticated && showMenu('Configuracion')"><a class="dropdown-trigger" href="#!" data-target="dd_configuraciones">Configuración<i class="material-icons right">arrow_drop_down</i></a></li>
                <li v-if="!$store.state.isAuthenticated"><router-link to="/Login">Login</router-link></li>
                <li v-if="$store.state.isAuthenticated"><a href="#!" @click="Logout">Salir</a></li>
            </ul>
        </div>
    </nav>

    <!-- <nav class="hide-on-large-only">
        <div class="nav-wrapper blue-ugb" style="padding-left:10px; padding-right:10px">
            <a href="/" class="brand-logo"><img class="responsive-img img-responsive" src="https://repositorio.ugb.edu.sv/resources/img/ugb/logos_nuevos/UGB_300x100_blanco.png"></a>
            <a href="#" data-target="nav-mobile" class="sidenav-trigger hide-on-large-only"><i class="material-icons" style="font-size: 30px">menu</i></a>
        </div>
    </nav> -->
</template>

<script>
import M from 'materialize-css'
    export default{
        name: 'Header',
        methods:{
            Logout() {
                this.$store.commit('logout');
            },
            showMenu(menu)
            {
                return this.$store.state.menus.find(x=>x.mec_menu == menu) != undefined;
            }
        },
        mounted()
        {
            var dropdowns = document.querySelectorAll('.dropdown-trigger')
            for (var i = 0; i < dropdowns.length; i++){
                M.Dropdown.init(dropdowns[i]);
            }
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, null);
            console.log(instances);
        }
    }
</script>