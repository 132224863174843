<template>
    <div class="row card-panel red darken-4 white-text">
        <blockquote>Debido al proceso de implementación de factura electrónica, el formulario de actualización de clientes ha sido modificado para cumplir con lo requerido en dicho proceso.
            <i class="material-icons right">warning</i>
        </blockquote>
    </div>
    <div class="row card-panel">
        <form method="post" @submit.prevent="setCliente()">
            <div class="row">
                <div class="col m1">
                    <label>ID</label>
                    <input type="text" v-model="cliente.cli_codigo" disabled>
                    <span class="helper-text">{{$validation(validations, "cli_codigo")}}</span>
                </div>
                <div class="col m3">
                    <label>Nombres*</label>
                    <input type="text" v-model="cliente.cli_nombres" maxlength="150">
                    <span class="helper-text">{{$validation(validations, "cli_nombres")}}</span>
                </div>
                <div class="col m3">
                    <label>Apellidos*</label>
                    <input type="text" v-model="cliente.cli_apellidos" maxlength="150">
                    <span class="helper-text">{{$validation(validations, "cli_apellidos")}}</span>
                </div>

                <div class="col m5">
                    <label>Nombre Comercial</label>
                    <input type="text" v-model="cliente.cli_mh_nombre_comercial" maxlength="200">
                    <span class="helper-text">{{$validation(validations, "cli_mh_nombre_comercial")}}</span>
                </div>
            
            </div>

            <div class="row">
                <div class="col m2">
                    <label>Teléfono*</label>
                    <input type="text" v-if="!isSalvadorean()" v-model="cliente.cli_mh_telefono" maxlength="20">
                    <InputMask type="text" v-if="isSalvadorean()" maxlength="10" minlength="10" v-model="cliente.cli_mh_telefono" mask="9999-9999"/>
                    <span class="helper-text">{{$validation(validations, "cli_mh_telefono")}}</span>
                </div>
                <div class="col m2">
                    <label>Correo*</label>
                    <input type="text" v-model="cliente.cli_mh_correo" maxlength="100">
                    <span class="helper-text">{{$validation(validations, "cli_mh_correo")}}</span>
                </div>
                <div v-show="!cliente.cli_no_documento_local">
                    <div class="col m2">
                        <label>DUI*</label>
                        <InputMask type="text" maxlength="10" minlength="10" v-model="cliente.cli_mh_dui" mask="99999999-9"/>
                        <span class="helper-text">{{$validation(validations, "cli_mh_dui")}}</span>
                    </div>
                    <div class="col m2">
                        <label><input type="checkbox" v-model="cliente.cli_homologado" class="filled-in" @click="setHomologado($event.target.checked)"><span>Homologado</span></label>
                    </div>
                    <div class="col m2">
                        <label>NIT*</label>
                        <InputMask type="text" maxlength="17" minlength="17" v-model="cliente.cli_mh_nit" mask="9999-999999-999-9" :disabled="cliente.cli_homologado"/>
                        <span class="helper-text">{{$validation(validations, "cli_mh_nit")}}</span>
                    </div>
                </div>   
                <div class="col m2">
                    <label><input type="checkbox" class="filled-in" v-model="cliente.cli_mh_domiciliado"><span>Domiciliado</span></label>
                </div>      
            </div>

            <div class="row">
                <div class="col m2">
                    <label>NRC</label>
                    <input type="text" v-model="cliente.cli_mh_nrc">
                    <span class="helper-text">{{$validation(validations, "cli_mh_nrc")}}</span>
                </div>
                <div class="col m3">
                    <label>Giro</label>
                    <input type="text" v-model="cliente.cli_giro">
                    <span class="helper-text">{{$validation(validations, "cli_giro")}}</span>
                </div>
                <div class="col m2">
                    <label><input type="checkbox" class="filled-in" v-model="cliente.cli_contribuyente_status" value="S"><span>Gran Contribuyente</span></label>
                </div>
                <div class="col m2">
                    <label>Actividad Económica*</label>
                    <select class="browser-default" v-model="cliente.cli_mh_actividad_economica">
                        <option value="">Seleccione</option>
                        <option v-for="actividad in actividades_economicas" :key="actividad" :value="actividad.codigo">{{ actividad.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "cli_mh_actividad_economica")}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col m2">
                    <label>Pais*</label>
                    <select class="browser-default" v-model="cliente.cli_mh_pais" @change="setPaisName()">
                        <option value="">Seleccione</option>
                        <option v-for="pais in paises" :value="pais.codigo" :key="pais.codigo">{{ pais.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "cli_mh_pais")}}</span>
                </div>
                <div class="col m2">
                    <label>Departamento*</label>
                    <select class="browser-default" v-bind:disabled="!isSalvadorean()" @change="getMunicipios(); setDepartamentoName()" v-model="cliente.cli_mh_departamento">
                        <option value="00">NO APLICA</option>
                        <option v-for="departamento in departamentos" :value="departamento.codigo" :key="departamento.codigo">{{ departamento.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "cli_mh_departamento")}}</span>
                </div>
                <div class="col m2">
                    <label>Distrito*</label>
                    <select class="browser-default" v-bind:disabled="!isSalvadorean()" v-model="cliente.cli_mh_municipio" @change="setMunicipioName()">
                        <option value="00">NO APLICA</option>
                        <option v-for="municipio in municipios" :value="municipio.codigo" :key="municipio.codigo">{{ municipio.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "cli_mh_municipio")}}</span>
                </div>
                <div class="col m6">
                    <label>Dirección*</label>
                    <input type="text" v-model="cliente.cli_direccion">
                    <span class="helper-text">{{$validation(validations, "cli_direccion")}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col m2">
                    <label><input type="checkbox" class="filled-in" v-model="cliente.cli_no_documento_local" value="S"><span>No posee DUI/NIT</span></label>
                </div>
                <div class="col m2" v-show="cliente.cli_no_documento_local">
                    <label># de Identificación</label>
                    <input type="text" v-model="cliente.cli_id_extranjero">
                </div>
            </div>
            
            <div class="row" v-show="error_message != ''">
                <div class="col m12 card-panel red darken-2 white-text">
                    {{ error_message }}
                </div>
            </div>

            <div class="row">
                <div class="col m12">
                    <button class="btn blue-ugb" v-if="cliente.cli_codigo == 0" type="submit">Guardar</button>
                    <button class="btn blue-ugb" v-if="cliente.cli_codigo != 0" type="submit">Actualizar</button>
                    <button class="btn red darken-3" type="button" @click="resetCliente()">Cancelar</button>
                </div>
            </div>
        </form>
    </div>

    <div class="row card-panel">
        <div class="row">
            <form method="post" @submit.prevent="buscar()">
                <div class="col m4">
                    <label>Buscar</label>
                    <input type="text" id="txt_search" />
                </div>
                <div class="col m4">
                    <button class="btn blue-ugb" type="submit">Buscar</button>
                </div>
            </form>
        </div>
        <div class="row">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; width: 100%">
                <thead>
                    <tr>
                        <th style="width: 3%">ID</th>
                        <th style="width: 25%">Nombre</th>
                        <th style="width: 6%">NRC</th>
                        <th style="width: 8%">DUI</th>
                        <th style="width: 10%">NIT</th>
                        <th style="width: 10%">Otro ID</th>
                        <th style="width: 7%">Teléfono</th>                    
                        <th style="width: 15%">Correo</th>                    
                        <th style="width: 5%">Pais</th>
                        <th style="width: 5%">Depto.</th>
                        <!-- <th style="width: 5%">Muni.</th> -->
                        <th style="width: 5%">Contri.</th>
                        <th style="width: 5%"></th>
                        <th style="width: 5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(cliente, index) in clientes" :key="cliente" v-bind:class="{'grey lighten-2': index == index_cliente}">
                        <td class="center">{{ cliente.cli_codigo }}</td>
                        <td>{{ cliente.cli_nombres.trim() }} {{ cliente.cli_apellidos.trim() }}</td>
                        <td class="center">{{ cliente.cli_mh_nrc }}</td>
                        <td class="center">{{ cliente.cli_mh_dui }}</td>                        
                        <td class="center"><span v-show="cliente.cli_homologado">Homologado</span><span v-show="!cliente.cli_homologado">{{ cliente.cli_mh_nit }}</span></td>  
                        <td>{{ cliente.cli_id_extranjero }}</td>                  
                        <td>{{ cliente.cli_mh_telefono }}</td>
                        <td>{{ cliente.cli_mh_correo }}</td>
                        <td>{{ cliente.cli_mh_pais }} - {{ cliente.cli_mh_pais_nombre }}</td>
                        <td>{{ cliente.cli_mh_departamento }} - {{ cliente.cli_mh_departamento_nombre }}</td>
                        <!-- <td>{{ cliente.cli_mh_municipio }} - {{ cliente.cli_mh_municipio_nombre }}</td> -->
                        <td class="center"><label><input type="checkbox" @click.prevent="" :checked="cliente.cli_contribuyente == 'S'" /><span></span></label></td>
                        <td class="center"><a href="#!" @click="editarCliente(cliente.cli_codigo)"><i class="material-icons">edit</i></a></td>
                        <td class="center"><a href="#!" @click="eliminarCliente(cliente.cli_codigo, index)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="12" style="text-align: right;">{{ total_records }} registros.</td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="row">
            <div class="col m12 l12 s12" style="border: solid 1px black">
                <ul class="pagination">
                    <li v-if="current_page > 1"><a href="#!" @click="getClientes(1)"><i class="material-icons">first_page</i></a></li>
                    <li v-if="current_page > 1"><a href="#!" @click="getClientes(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
                    <template v-for="page in total_pages" v-bind:key="page">
                        <li v-if="page < current_page + 4 && page > current_page - 4" v-bind:class="{active: page == current_page}"><a @click="getClientes(page)" href="#!">{{page}}</a></li>
                    </template>
                    <li v-if="current_page < total_pages"><a href="#!" @click="getClientes(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
                    <li v-if="current_page < total_pages"><a href="#!" @click="getClientes(total_pages)"><i class="material-icons">last_page</i></a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
import InputMask from 'primevue/inputmask'
    export default
    {
        components: {
            InputMask
        },
        data(){
            return {
                clientes: [],
                cliente: {},
                paises: [],
                actividades_economicas: [],
                departamentos: [],
                municipios: [],
                validations: [],
                search: '',
                current_page: 1,
                total_pages: 0,
                total_records: 0,
                page: 1,
                index_cliente: -1,
                codcli: 0,
                error_message: ''
            }
        },
        async mounted()
        {
            await this.getPaises();
            await this.getDepartamentos();
            await this.getActividadesEconomicas();
            await this.getClientes();
            await this.resetCliente();
            var id = parseInt(this.$route.params.id);
            if(isNaN(id))
            {
                this.codcli = 0;
            }
            else
            {
                this.codcli = parseInt(this.$route.params.id);
            }
            await this.getCliente(this.codcli);
        },
        methods: {
            async getClientes(page)
            {
                this.$root.startLoading();
                await this.axios.get('/Clientes/UOnline?page=' + (page || 1) + '&search=' + this.search)
                .then(response => {
                    this.clientes = response.data.records;
                    this.current_page = response.data.current_page;
                    this.total_pages = response.data.total_pages;
                    this.total_records = response.data.total_records;
                });
                this.$root.stopLoading();
            },
            resetCliente()
            {
                this.cliente = {};
                this.cliente.cli_codigo = 0;
                this.cliente.cli_mh_actividad_economica = "";
                this.cliente.cli_mh_pais = "";
                this.cliente.cli_mh_departamento = "00";
                this.cliente.cli_mh_municipio = "00";
                this.cliente.cli_mh_nombre_comercial = "";
                this.cliente.cli_mh_nrc = "";
                this.cliente.cli_codpai = 1;
                this.cliente.cli_no_documento_local = false;
                this.cliente.cli_id_extranjero = '';
                this.validations = [];
            },
            async getPaises()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Paises')
                .then(response => {
                    this.paises = response.data;
                });
                this.$root.stopLoading();
            },
            setPaisName()
            {
                if(!this.isSalvadorean())
                {
                    this.cliente.cli_mh_departamento = "00";
                    this.cliente.cli_mh_municipio = "00";
                }
                var pais = this.paises.find(x=>x.codigo == this.cliente.cli_mh_pais);
                this.cliente.cli_mh_pais_nombre = pais == undefined ? "":pais.nombre;
            },
            setDepartamentoName()
            {
                var departamento = this.departamentos.find(x=>x.codigo == this.cliente.cli_mh_departamento);
                
                this.cliente.cli_mh_departamento_nombre = departamento == undefined ? "":departamento.nombre;
            },
            setMunicipioName()
            {
                var municipio = this.municipios.find(x=>x.codigo == this.cliente.cli_mh_municipio);
                
                this.cliente.cli_mh_municipio_nombre = municipio == undefined ? "":municipio.nombre;
            }
            ,
            async getDepartamentos()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Departamentos')
                .then(response => {
                    this.departamentos = response.data;
                });
                this.$root.stopLoading();
            },
            async getMunicipios()
            {
                if(this.cliente.cli_mh_departamento == '')
                {
                    this.municipios = [];
                    this.cliente.cli_mh_municipio = '00';
                    return;
                }
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Departamentos/' + this.cliente.cli_mh_departamento + '/Municipios')
                .then(response => {
                    this.municipios = response.data;
                    this.cliente.cli_mh_municipio = '00';
                });
                this.$root.stopLoading();
            },
            async getActividadesEconomicas()
            {
                this.$root.startLoading();
                this.axios.get('/FacturacionElectronicaAPI/ActividadEconomica')
                .then(response => {
                    this.actividades_economicas = response.data;
                });
                this.$root.stopLoading();
            },
            async setCliente()
            {
                this.$root.startLoading();
                this.validations = [];
                this.error_message = '';
                this.cliente.cli_contribuyente = this.cliente.cli_contribuyente_status ? "S":"N";
                await this.axios.post('/Clientes', this.cliente)
                .then(response => {
                    if(response.status == 201)
                    {
                        this.cliente.cli_codigo = response.data.cli_codigo;
                        this.clientes.unshift(response.data);
                        M.toast({html: 'El cliente ha sido creado.'});
                    }
                    if(response.status == 200)
                    {
                        var index = this.clientes.findIndex(x=>x.cli_codigo == response.data.cli_codigo);
                        this.clientes[index] = response.data;
                        M.toast({html: 'El cliente ha sido actualizado.'});
                    }
                    /* this.index_cliente = -1;
                    this.resetCliente();
                    history.pushState(null, "", "/ClientesAdmin"); */
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                        M.toast({html: 'Complete los campos.'});
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                        this.error_message = error.response.data.message;
                    }
                });
                this.$root.stopLoading();
            },
            async getCliente(codcli)
            {
                if(codcli == 0) return;
                await this.axios.get('/Clientes/UOnline/' + codcli)
                .then(async response => {
                    this.cliente.cli_mh_departamento = response.data.cli_mh_departamento;
                    await this.getMunicipios();
                    this.cliente = response.data;
                    var index = this.clientes.findIndex(x=>x.cli_codigo == codcli);
                    if(index != -1)
                    {
                        this.index_cliente = index;
                    }
                });
            },
            async editarCliente(codcli)
            {
                history.pushState(null, "", "/ClientesAdmin/" + codcli);
                this.validations = [];
                var index = this.clientes.findIndex(x=>x.cli_codigo == codcli);
                if(index == -1)
                {
                    M.toast({html: 'El cliente no existe.'});
                    this.codcli = 0;
                    this.resetCliente();
                    return false;
                }
                if((this.clientes[index].cli_mh_departamento || '') != '')
                {
                    this.cliente.cli_mh_departamento = this.clientes[index].cli_mh_departamento;
                    await this.getMunicipios();
                }
                this.cliente = JSON.parse(JSON.stringify(this.clientes[index]));                
                this.index_cliente = index;                
            },
            async eliminarCliente(codcli, index)
            {
                if(!confirm('¿Desea eliminar este cliente?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Clientes/' + codcli)
                .then(() => {
                    this.clientes.splice(index, 1);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            async buscar()
            {
                this.search = document.querySelector('#txt_search').value;
                await this.getClientes();
            },
            setHomologado(checked)
            {
                if(checked)
                {
                    this.cliente.cli_mh_nit = '';
                }
            },
            isSalvadorean()
            {
                return this.cliente.cli_mh_pais == '9300' || this.cliente.cli_mh_pais == 'SV';
            }
        }
    }
</script>