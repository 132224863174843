import {createStore} from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '../router'

const storage = createStore({
    state: {
        isAuthenticated: false,
        userData: {},
        menus: [],
        roles: []
    },
    mutations: {
        setAuthenticated(state, value)
        {
            state.isAuthenticated = value;
        },
        setUserData(state, userData)
        {
            state.userData = userData;
        },
        setMenus(state, menus)
        {
            state.menus = menus;
        },
        setRoles(state, roles)
        {
            state.roles = roles;
        },
        logout(state)
        {
            router.push('/Login');
            state.isAuthenticated = false;
            localStorage.removeItem('token');
            state.userData = {};
            state.menus = [];
        }
    },
    plugins: [
        new VuexPersistence({
            storage: window.localStorage
        }).plugin
    ]
});

export default storage