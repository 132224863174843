<template>
    <div class="row card-panel">
        <div class="col m12">
            <div class="col m6">
                <label><input type="checkbox" class="filled-in" @change="filtrarFacultades()" checked v-model="solo_facultades_pendientes"><span>Solo Pendientes</span></label>
                <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                    <thead>
                        <tr>
                            <th>Ciclo</th>
                            <th>Facultad</th>
                            <th>Pendientes</th>
                            <th>Actualizadas</th>
                            <th>Rechazadas</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(facultad, index) in facultades_view" :key="facultad" v-bind:class="{'grey lighten-1': (index == facultad_index)}">
                            <td class="center">{{facultad.ciclo}}</td>
                            <td>{{facultad.facultad}}</td>
                            <td class="center">{{facultad.pendientes}}</td>
                            <td class="center">{{facultad.actualizadas}}</td>
                            <td class="center">{{facultad.rechazadas}}</td>
                            <td class="center"><a href="#!" @click="getCarreras(facultad.codcil, facultad.codigo)"><i class="material-icons">list</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col m6">
                <label v-show="carreras.length > 0"><input type="checkbox" class="filled-in" @change="filtrarCarreras()" checked v-model="solo_carreras_pendientes"><span>Solo Pendientes</span></label>
                <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px" v-show="carreras.length > 0">
                    <thead>
                        <tr>
                            <th>Carrera</th>
                            <th>Pendientes</th>
                            <th>Actualizadas</th>
                            <th>Rechazadas</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(carrera, index) in carreras_view" :key="carrera" v-bind:class="{'grey lighten-1': (index == carrera_index)}">
                            <td>{{carrera.carrera}}</td>
                            <td class="center">{{carrera.pendientes}}</td>
                            <td class="center">{{carrera.actualizadas}}</td>
                            <td class="center">{{carrera.rechazadas}}</td>
                            <td class="center"><a href="#!" @click="getEstudiantes(carrera.codcil, carrera.codigo)"><i class="material-icons">list</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-comprobantes" style="width:85%">
        <div class="modal-content">
            <div class="row">
                <a href="#!" class="btn red darken-3 modal-close right"><i class="material-icons">close</i></a>
                <label v-show="estudiantes.length > 0"><input type="checkbox" class="filled-in" @change="filtrarEstudiantes()" checked v-model="solo_estudiantes_pendientes"><span>Solo Pendientes</span></label>
                <div class="col m12" style="height:150px; overflow:auto; border: dashed 1px black; padding:10px">
                    <table class="table tbl-condensed striped" style="font-size:12px" v-show="estudiantes.length > 0">
                        <thead>
                            <tr>
                                <th>Carnet</th>
                                <th>Estudiante</th>
                                <th>Pendientes</th>
                                <th>Actualizadas</th>
                                <th>Rechazadas</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(_estudiante, index) in estudiantes_view" :key="_estudiante" v-bind:class="{'grey lighten-1': (estudiante_index == index)}">
                                <td class="center">{{_estudiante.carnet}}</td>
                                <td><a v-bind:href="('/Estudiante/' + _estudiante.codigo)" target="_blank">{{_estudiante.estudiante}}</a></td>
                                <td class="center">{{_estudiante.pendientes}}</td>
                                <td class="center">{{_estudiante.actualizadas}}</td>
                                <td class="center">{{_estudiante.rechazadas}}</td>
                                <td class="center"><a @click="getEstudiante(_estudiante.codigo)" href="#!"><i class="material-icons">list</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <ul class="pagination">
                            <li v-for="page in estudiantes_total_pages" :key="page" v-bind:class="{active: (page == estudiantes_current_page)}">                                
                               <a href="#!" @click="getEstudiantes(this.carreras_view[carrera_index].codcil, this.carreras_view[carrera_index].codigo, page)">
                                   {{page}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col m12">
                    <div class="col m8" style="padding:20px" v-show="estudiante.length > 0">
                        <table class="tbl-condensed table striped" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Comentario</th>
                                    <th>Observaciones</th>
                                    <th>Fecha Aprob.</th>
                                    <th>Estado</th>
                                    <th>Usuario</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(_estudiante, index) in estudiante" :key="_estudiante" v-bind:class="{'grey lighten-1': (index == comprobante_index)}">
                                    <td>{{_estudiante.fecha}}</td>
                                    <td>{{_estudiante.comentario}}</td>
                                    <td>{{_estudiante.observaciones}}</td>
                                    <td>{{_estudiante.fechaAprobacion}}</td>
                                    <td>{{_estudiante.estado}}</td>
                                    <td>{{_estudiante.usuario}}</td>
                                    <td><a href="#!" @click="getComprobante(_estudiante.codigo, index)"><i class="material-icons">folder_open</i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col m4" style="padding:20px">
                        <div class="row" v-show="aranceles.length > 0">
                            <table class="table tbl-condensed" style="font-size:12px">
                                <thead>
                                    <tr>
                                        <th style="width:70%">Cuota</th>
                                        <th style="width:20%">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="arancel in aranceles" :key="arancel">
                                        <td>{{arancel.ara_descripcion}}</td>
                                        <td class="center">${{arancel.arp_monto}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row" v-show="adjuntos.length > 0">
                            <table class="table tbl-condensed" style="font-size:12px">
                                <thead>
                                    <tr>
                                        <th style="width:70%">Archivo</th>
                                        <th style="width:20%">Tipo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="adjunto in adjuntos" :key="adjunto">
                                        <td><a href="#!" @click="getArchivo(adjunto.adp_codigo)">{{$spliceString(adjunto.adp_archivo, 0, 40)}}</a></td>
                                        <td class="center">{{(adjunto.adp_tipo == 'I' ? 'Imagen':'Pdf')}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row" v-show="adjuntos.length > 0 && aranceles.length > 0 && estudiante.length > 0 && estudiante[comprobante_index].estado == 'PENDIENTE'">
                            <div class="col m12">
                                <label>Observaciones</label>
                                <textarea rows="10" v-model="acp_observaciones"></textarea>
                            </div>
                            <div class="col m6 center">
                                <button class="btn blue-ugb" @click="aprobar()">Aprobar</button>
                            </div>
                             <div class="col m6 center">
                                <button class="btn red darken-3" @click="rechazar()">Rechazar</button>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                facultades: [],
                facultades_view: [],
                carreras: [],
                carreras_view: [],
                estudiantes: [],
                estudiantes_view: [],
                estudiantes_current_page: 1,
                estudiantes_total_pages: 0,
                estudiante: [],
                facultad_index: -1,
                carrera_index: -1,
                estudiante_index: -1,
                comprobante_index: -1,
                solo_facultades_pendientes: true,
                solo_carreras_pendientes: true,
                solo_estudiantes_pendientes: true,

                modal_instances: [],
                modal_comprobantes: {},

                aranceles: [],
                adjuntos: [],

                acp_observaciones: ''
            }
        },
        async mounted()
        {
            await this.getFacultades();
            this.modal_instances = M.Modal.init(document.querySelectorAll('.modal'), {
                dismissible: false
            });
        },
        methods: {
            async getFacultades()
            {
                this.$root.startLoading();
                await this.axios.get('/Comprobantes/Facultades')
                .then(response => {
                    this.facultades = response.data;
                    this.facultades_view = response.data;
                    this.filtrarFacultades();
                    this.filtrarCarreras();
                });
                this.$root.stopLoading();
            },
            async getCarreras(codcil, codfac)
            {
                this.$root.startLoading();
                this.facultad_index = this.facultades_view.findIndex(x=>x.codigo == codfac && x.codcil == codcil);
                this.carrera_index = -1;
                this.estudiante_index = -1;
                await this.axios.get('/Comprobantes/Carreras/' + codcil + '/' + codfac)
                .then(response => {
                    this.carreras = response.data;
                    this.carreras_view = response.data;
                    this.filtrarCarreras();
                });
                this.$root.stopLoading();
            },
            async getEstudiantes(codcil, codcar, page)
            {
                this.$root.startLoading();
                //this.estudiantes = [];
                this.estudiante = [];
                this.aranceles = [];
                this.adjuntos = [];
                this.estudiantes_current_page = page || this.estudiantes_current_page;
                this.carrera_index = this.carreras_view.findIndex(x=>x.codigo == codcar && x.codcil == codcil);
                this.estudiante_index = -1;
                this.axios.get('/Comprobantes/Estudiantes/' + codcil + '/' + this.facultades_view[this.facultad_index].codigo + '/' + codcar + '?page=' + this.estudiantes_current_page + '&pendings=' + this.solo_estudiantes_pendientes)
                .then(response => {
                    this.estudiantes = response.data;
                    this.estudiantes_view = response.data;
                    /* this.estudiantes_current_page = response.data.current_page;
                    this.estudiantes_total_pages = response.data.pages; */
                    this.filtrarEstudiantes();
                });
                this.modal_comprobantes = M.Modal.getInstance(document.querySelector('#modal-comprobantes'));
                this.modal_comprobantes.open();
                this.$root.stopLoading();
            },
            async getEstudiante(codper)
            {
                this.$root.startLoading();
                this.comprobante_index = -1;
                this.adjuntos = [];
                this.aranceles = [];
                this.estudiante_index = this.estudiantes_view.findIndex(x=>x.codigo == codper);
                this.axios.get('/Comprobantes/Estudiante/' + this.facultades_view[this.facultad_index].codcil + '/' + this.facultades_view[this.facultad_index].codigo + '/' + this.carreras_view[this.carrera_index].codigo + '/' + codper)
                .then(response => {
                    this.estudiante = response.data;
                });
                this.$root.stopLoading();
            },
            filtrarFacultades(){
                this.facultad_index = -1;
                this.carrera_index = -1;
                this.carreras = [];
                this.estudiantes = [],
                this.estudiante = [];
                if(this.solo_facultades_pendientes)
                {
                    this.facultades_view = this.facultades.filter(x=>x.pendientes > 0)
                }
                else
                {
                    this.facultades_view = this.facultades;
                }
            },
            filtrarCarreras()
            {
                this.estudiante = [];
                if(this.solo_carreras_pendientes)
                {
                    this.carreras_view = this.carreras.filter(x=>x.pendientes > 0)
                }
                else
                {
                    this.carreras_view = this.carreras;
                }
            },
            async filtrarEstudiantes()
            {
                this.estudiante_index = -1;
                this.estudiante = [];
                this.adjuntos = [];
                this.aranceles = [];
                if(this.solo_estudiantes_pendientes)
                {
                    this.estudiantes_view = this.estudiantes.filter(x=>x.pendientes > 0)
                }
                else
                {
                    this.estudiantes_view = this.estudiantes;
                }
            },
            async getComprobante(codacp, index)
            {
                this.$root.startLoading();
                this.comprobante_index = index;
                await this.axios.get('/Comprobantes/Aranceles/' + codacp)
                .then(response => {
                    this.aranceles = response.data;
                });

                await this.axios.get('/Comprobantes/Adjuntos/' + codacp)
                .then(response => {
                    this.adjuntos = response.data;
                });
                this.$root.stopLoading();
            },
            async getArchivo(codadp)
            {
                this.$root.startLoading();
                await this.axios.get('/Comprobantes/Archivo/' + codadp, {}, {
                    responseType: 'blob'
                })
                .then(response => {
                    var binary = atob(response.data.base64.replace(/\s/g, ''));
                    var len = binary.length;
                    var buffer = new ArrayBuffer(len);
                    var view = new Uint8Array(buffer);
                    for (var i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i);
                    }
                    const objectURL = URL.createObjectURL(new Blob( [view], { type: response.data.contentType }));
                    window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes')
                })
                .catch(() => {
                    M.toast({html: 'Archivo no disponible.'});
                });
                this.$root.stopLoading();
            },
            async aprobar()
            {
                if(!confirm('¿Desea aprobar este comprobante?')) return false;
                this.$root.startLoading();
                await this.axios.patch('/Comprobantes/Aprobar/' + this.estudiante[this.comprobante_index].codigo)
                .then(() => {
                    this.estudiante[this.comprobante_index].estado = 'APROBADO';
                    this.estudiante[this.comprobante_index].usuario = this.$store.state.userData.usa_usuario;
                    this.estudiante[this.comprobante_index].fechaAprobacion = this.$dateFormat(new Date(), 'dd/MM/yyyy');

                    this.estudiantes_view[this.estudiante_index].pendientes -= 1;
                    this.estudiantes_view[this.estudiante_index].actualizadas += 1;

                    this.carreras_view[this.carrera_index].pendientes -= 1;
                    this.carreras_view[this.carrera_index].actualizadas += 1;

                    this.facultades_view[this.facultad_index].pendientes -= 1;
                    this.facultades_view[this.facultad_index].actualizadas += 1;

                });
                this.$root.stopLoading();
            },
            async rechazar()
            {
                if(this.$isNullOrWhitespace(this.acp_observaciones))
                {
                    M.toast({html: 'Agrega una observación.'});
                    return false;
                }

                if(!confirm('¿Desea rechazar este comprobante?')) return false;

                this.$root.startLoading();
                await this.axios.patch('/Comprobantes/Rechazar/' + this.estudiante[this.comprobante_index].codigo,                    
                    {
                        acp_observaciones: this.acp_observaciones
                    }
                )
                .then(() => {
                    this.estudiante[this.comprobante_index].estado = 'RECHAZADO';
                    this.estudiante[this.comprobante_index].observaciones = this.acp_observaciones;
                    this.estudiante[this.comprobante_index].usuario = this.$store.state.userData.usa_usuario;
                    this.estudiante[this.comprobante_index].fechaAprobacion = this.$dateFormat(new Date(), 'dd/MM/yyyy');

                    this.estudiantes_view[this.estudiante_index].pendientes -= 1;
                    this.estudiantes_view[this.estudiante_index].rechazadas += 1;

                    this.carreras_view[this.carrera_index].pendientes -= 1;
                    this.carreras_view[this.carrera_index].rechazadas += 1;

                    this.facultades_view[this.facultad_index].pendientes -= 1;
                    this.facultades_view[this.facultad_index].rechazadas += 1;

                    this.acp_observaciones = '';
                });
                this.$root.stopLoading();
            },
            getFecha()
            {
                return this.$dateFormat(new Date(), 'dd/MM/yyyy');
            }
        }
    }
</script>