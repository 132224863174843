import { createApp } from 'vue'
import App from './App.vue'
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
import './assets/css/style.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import M from 'materialize-css'
import store from './store'
import validation from './plugins/validations'
import dateHelper from './plugins/dateHelper'
import stringHelper from './plugins/stringHelper'

axios.defaults.baseURL = process.env.VUE_APP_ENV_VARIABLE

var token = localStorage.getItem('token');
if(token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

axios.interceptors.response.use(function (response) {
    return response
    }, function (error) {
    if (error.response.status === 401) {
        store.commit('logout');
    }
    if(error.response.status === 500)
    {
        M.toast({html: 'Ha ocurrido un error de conexión al servidor.'});
    }
    return Promise.reject(error)
});

createApp(App).use(router).use(validation).use(dateHelper).use(stringHelper).use(store).use(VueAxios, axios).mount('#app')