/*
cutString(word, size)
        {
            if(word.length > (size + 3))
            {
                return word.substring(0, size) + '...';
            }
            else return word;
        }
*/
export default {
    install: (app) =>
    {
        app.config.globalProperties.$spliceString = function(string, start, end)
        {
            var result = string.substring(start || 0, end || string.length);
            if(result == '') return string;
            if(result.length + 3  > (end - start))
            {
                return result + '...';
            }
            else
            {
                return result;
            }
        },
        app.config.globalProperties.$isNullOrWhitespace = function(input)
        {
            if (typeof input === 'undefined' || input == null) return true;
        
            return input.replace(/\s/g, '').length < 1;
        }
    }
}