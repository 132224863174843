<template>
    <div class="row">
        <form method="get" @submit.prevent="getErns()">
            <div class="col m12">
                <div class="col m4">
                    <label>ERN</label>
                    <input type="text" v-model="ern">
                </div>
                <div class="col m4">
                    <label>Término de Búsqueda</label>
                    <input type="text" v-model="search">
                </div>
                <div class="col m4">
                    <button class="btn blue-ugb" type="submit"><i class="material-icons">search</i></button>
                </div>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col m12">
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Regional</th>
                        <th>Ciclo</th>
                        <th>ERN</th>
                        <th>Carnet</th>
                        <th>Nombre</th>
                        <th>Creado</th>
                        <th>Actualizado</th>                        
                        <th>STATUS</th>
                        <th>Factura</th>
                        <th>Registrado</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(ern, index) in erns" :key="ern" v-bind:class="{'green lighten-4': index == selected_index}">
                        <td class="center">{{ern.reg_nombre}}</td>
                        <td class="center">{{ern.cic_nombre}} - {{ern.cil_anio}}</td>
                        <td class="center">
                            {{ern.ern_codigo}} <br>
                        </td>
                        <td class="center">{{ern.per_carnet}}</td>
                        <td>{{ern.per_nombres_apellidos}}</td>
                        <td class="center">{{ern.ern_fecha_creacion_string}}</td>
                        <td class="center">{{ern.ern_fecha_actualizacion}}</td>
                        <td class="center">{{ern.ern_status}}</td>
                        <td class="center"><a v-if="ern.ern_codmov > 0" href="#!"><i class="material-icons">open_in_browser</i></a></td>
                        <td class="center">{{ern.ern_fecha_registro_pago}}</td>
                        <td class="center"><a href="#!" @click="check(ern.ern_token, ern.ern_codigo)"><i class="material-icons">check_circle</i></a></td>
                        <td class="center"><a href="#!" @click="details(index)"><i class="material-icons">list</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div id="modal-detalle" class="modal bottom-sheet">
        <div class="modal-content">
            <div class="row">
                <div class="col m6">
                    <table class="table tbl-condensed tbl-bordered striped" v-if="detalle != null">
                        <thead>
                            <tr>
                                <th>Arancel</th>
                                <th>Valor</th>
                                <th>Mes</th>
                                <th>Código de Materia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in detalle.details" :key="detail">
                                <td>{{detail.description}}</td>
                                <td class="center">${{detail.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td class="center">{{detail.month}}</td>
                                <td class="center">{{detail.codmat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="btn modal-close waves-effect blue-ugb">Agree</a>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                erns: [],
                search: '',
                ern: '',
                selected_index: -1,
                collapsibles: [],
                ciclos: [],
                modals: [],
                detalle: null
            }
        },
        async mounted()
        {
            await this.getCiclos();
            var elems = document.querySelectorAll('.modal');
            this.modals = M.Modal.init(elems, null);
        },
        methods: {
            details(index)
            {
                this.selected_index = index;
                var instance = M.Modal.getInstance(document.querySelector('#modal-detalle'));
                this.detalle = this.erns[index].data;
                instance.open();
            },
            async getErns()
            {
                this.selected_index = -1;
                this.$root.startLoading();
                await this.axios.get('/Pagadito?ern=' + this.ern + '&search=' + this.search)
                .then(response => {
                    this.erns = response.data;
                    for(let i = 0; i <= this.erns.length - 1; i++)
                    {
                        this.erns[i].data = JSON.parse(this.erns[i].ern_detalle);
                        this.erns[i].reg_nombre = this.ciclos.find(x=>x.cil_codigo == this.erns[i].data.term).reg_nombre;
                        this.erns[i].cic_nombre = this.ciclos.find(x=>x.cil_codigo == this.erns[i].data.term).cic_nombre;
                        this.erns[i].cil_anio = this.ciclos.find(x=>x.cil_codigo == this.erns[i].data.term).cil_anio;
                    }
                });
                this.$root.stopLoading();
            },
            async check(token, ern)
            {
                var index = this.erns.findIndex(x=>x.ern_token == token);
                this.selected_index = index;
                this.$root.startLoading();
                await this.axios.get('/Pagadito/Check/'+token+'/' + ern)
                .then(response => {
                    if(response.data.message != this.erns[index].ern_status)
                    {
                        this.erns[index].ern_status = response.data.message;
                    }
                    else
                    {
                        M.toast({html: 'Sin cambios en el estado.'});
                    }
                });
                this.$root.stopLoading();
            },
            async getCiclos()
            {
                this.$root.startLoading();
                await this.axios.get('/Ciclos/All')
                .then(response => {
                    this.ciclos = response.data;
                });
                this.$root.stopLoading();
            }
        }
    }
</script>