<template>
    <div class="row card-panel blue-ugb">
        <div class="col m3 card-panel">
            <div class="row">
                <div class="col m12">
                    <a href="#!" @click="reset()"><i class="material-icons">add_circle</i></a>
                </div>
            </div>
            <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(reporte) in reportes" v-bind:key="reporte" style="cursor:pointer" @click="getReporte(reporte.rep_codigo)" v-bind:class="{'grey lighten-2': (rep_codigo == reporte.rep_codigo)}">
                        <td>{{reporte.rep_nombre}}</td>
                        <td class="center"><label><input @click.prevent="" type="checkbox" class="filled-in" :checked="reporte.rep_estado"><span></span></label></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col m9">
            <div class="col m12 card-panel">
                <div class="row" v-show="reporte.rep_codigo > 0">
                    <div class="col m12">
                        <a href="#!" class="right" @click="accesosReporte()"><i class="material-icons">security</i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col m12">
                        <div class="col m5">
                            <label>Nombre</label>
                            <input type="text" v-model="reporte.rep_nombre">
                            <small class="helper-text">{{$validation(validations, "rep_nombre")}}</small>
                        </div>
                        <div class="col m5">
                            <label>Procedimiento/Reporte</label>
                            <input type="text" v-model="reporte.rep_reporte">
                            <small class="helper-text">{{$validation(validations, "rep_reporte")}}</small>
                        </div>
                        <div class="col m2 input-field">
                            <label><input type="checkbox" v-model="reporte.rep_estado" :checked="reporte.rep_estado"><span>Activo</span></label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col m12 card-panel" >
                <div style="padding:10px">

                    <div class="row">
                         <div class="col m4">
                            <label>Descripción</label>
                            <input type="text" v-model="detalle.der_descripcion">
                            <small class="helper-text">{{$validation(validations, "der_descripcion")}}</small>
                        </div>
                        <div class="col m4">
                            <label>Parámetro</label>
                            <input type="text" v-model="detalle.der_campo">
                            <small class="helper-text">{{$validation(validations, "der_campo")}}</small>
                        </div>
                        <div class="col m4">
                            <label>Valor por Defecto</label>
                            <input type="text" v-model="detalle.der_valor">
                            <small class="helper-text">{{$validation(validations, "der_valor")}}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m2">
                            <label><input type="checkbox" class="filled-in" v-model="detalle.der_obligatorio" :checked="detalle.der_obligatorio"><span>Obligatorio</span></label>
                        </div> 
                        <div class="col m2" v-show="!detalle.der_select">
                            <label><input type="checkbox" class="filled-in" v-model="detalle.der_fecha" :checked="detalle.der_fecha"><span>Fecha</span></label>
                        </div>
                        <div class="col m2" v-show="!detalle.der_fecha">
                            <label><input type="checkbox" class="filled-in" v-model="detalle.der_select" :checked="detalle.der_select"><span>Lista</span></label>
                        </div>                        
                    </div>

                    <div class="row" v-show="detalle.der_select && !detalle.der_fecha">
                        <div class="col m12">
                            <label>Consulta</label>
                            <textarea class="materialize-textarea" :required="detalle.der_select" v-model="detalle.der_consulta"></textarea>
                            <small class="helper-text">{{$validation(validations, "der_consulta")}}</small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col m12">
                            <button type="button" class="btn blue-ugb " @click="setDetalle()" v-if="detalle.der_codigo == 0">Agregar</button>
                            <button type="button" class="btn blue-ugb " @click="actualizarDetalle()" v-if="detalle.der_codigo > 0">Actualizar</button>
                            <button type="button" class="btn red darken-4 " @click="resetDetalle()" v-if="detalle.der_codigo > 0">Cancelar</button>
                        </div>
                    </div>
                    
                    <div class="row" v-show="reporte.sap_der_detalle_reporte.length > 0">
                        <div class="col m12">
                        <table class="table tbl-condensed" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th style="width:3%"></th>
                                    <th style="width:3%">#</th>
                                    <th style="width:15%">Descripción</th>
                                    <th style="width:15%">Parámetro</th>
                                    <th style="width:15%">Valor</th>
                                    <th style="width:5%">Obl.</th>
                                    <th style="width:5%">Fecha</th>
                                    <th style="width:5%">Lista</th>
                                    <th style="width:43%">Consulta</th>
                                    <th style="width:3%"></th>
                                    <th style="width:3%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr draggable="true" v-for="(detalle, index) in reporte.sap_der_detalle_reporte" v-bind:key="detalle" v-bind:class="{'grey lighten-3': (der_codigo == detalle.der_codigo)}">
                                    <td>
                                        <a href="#!" title="Subir" @click="moverFila(index, -1)" v-if="index != 0"><i class="material-icons">arrow_drop_up</i></a>
                                        <a href="#!" title="Bajar" @click="moverFila(index, 1)" v-if="reporte.sap_der_detalle_reporte.length != index + 1"><i class="material-icons">arrow_drop_down</i></a>
                                    </td>
                                    <td class="center">{{detalle.der_codigo}}</td>
                                    <td>{{detalle.der_descripcion}}</td>
                                    <td class="center">{{detalle.der_campo}}</td>
                                    <td class="center">{{detalle.der_valor}}</td>
                                    <td class="center"><label><input @click.prevent="" type="checkbox" class="filled-in" :checked="detalle.der_obligatorio"><span></span></label></td>
                                    <td class="center"><label><input @click.prevent="" type="checkbox" class="filled-in" :checked="detalle.der_fecha"><span></span></label></td>
                                    <td class="center"><label><input @click.prevent="" type="checkbox" class="filled-in" :checked="detalle.der_select"><span></span></label></td>
                                    <td style="overflow:auto;text-overflow: ellipsis;">
                                        <div v-html="formatText(detalle.der_consulta)" style="width: 150px"></div>
                                    </td>
                                    <td class="center"><a href="#!" @click="editarDetalle(detalle.der_codigo)"><i class="material-icons">edit</i></a></td>
                                    <td class="center"><a href="#!" @click="eliminarDetalle(detalle.der_codigo)"><i class="material-icons">delete</i></a></td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>

                    <div class="row" v-show="reporte.sap_der_detalle_reporte.length > 0">
                        <div class="col m12">
                            <button type="button" class="btn blue-ugb " @click="guardar()">GUARDAR</button>
                            <button type="button" class="btn red darken-4 " @click="eliminar()" v-if="reporte.rep_codigo > 0">ELIMINAR</button>
                        </div>
                    </div>
                </div>               

            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                reportes: [],
                reporte: {},
                detalles: [],
                detalle: {},
                rep_codigo: 0,
                der_codigo: 0,
                keywords: ["SELECT","FROM","WHERE","LIKE","BETWEEN","NOT LIKE","FALSE","NULL","FROM","TRUE","NOT IN", "DISTINCT", "JOIN", "ON", "AND", "OR", "ORDER", "BY", "CAST", "VARCHAR", "AS", "GROUP", "BY", "UNION", "ALL", "NOT", "IN"],
                keywords_br: ['FROM', 'WHERE', 'JOIN', 'ORDER', 'GROUP', 'UNION'],
                validations: []
            }
        },
        async created()
        {
            this.reporte.sap_der_detalle_reporte = [];
            this.reset();
        },
        async mounted()
        {            
            await this.getReportes();
        },
        methods: {
            async getReportes()
            {
                 await this.axios.get('/Reportes/All')
                .then(response => {
                    this.reportes = response.data;
                });
            },
            async getReporte(rep_codigo)
            {
                this.reporte = this.reportes.find(x=>x.rep_codigo == rep_codigo);
                this.rep_codigo = rep_codigo;
                this.$root.startLoading();
                await this.axios.get('/Reportes/' + rep_codigo)
                .then(response => {
                    this.reporte = response.data;                    
                });
                this.$root.stopLoading();
            },
            formatText(der_consulta)
            {
                der_consulta = der_consulta || '';
                var newHTML = "";
                der_consulta.replace(/[\s]+/g, " ").trim().split(" ").forEach((val) => {
                    if (this.keywords.indexOf(val.trim().toUpperCase()) > -1)
                    {
                        if(this.keywords_br.findIndex(x=> x == val.trim().toUpperCase()) > -1)
                        {
                            newHTML += '<BR>';
                        }
                        newHTML += "<span style='font-weight: bold; color:#BA4A00'>" + val + "&nbsp;</span>";
                    
                    }
                    else
                    {
                        if (this.parametros.indexOf(val) > -1)
                        {
                            newHTML += "<span style='font-weight: bold; color:blue'>" + val + "&nbsp;</span>";
                        }
                        else
                            newHTML += "<span>" + val + "&nbsp;</span>";
                    }
                });
                return newHTML;                
            },
            formatParameters(der_consulta)
            {
                der_consulta = der_consulta || '';
                var newHTML = "";
                der_consulta.replace(/[\s]+/g, " ").trim().split(" ").forEach((val) => {
                if (this.parametros.indexOf(val) > -1)
                {
                    newHTML += "<span style='font-weight: bold; color:blue'>" + val.toUpperCase() + "&nbsp;</span>";
                }
                else
                    newHTML += "<span>" + val.toUpperCase() + "&nbsp;</span>"; 
                });
                return newHTML;                
            },
            reset()
            {
                this.reporte = {};
                this.reporte.rep_estado = true;
                this.reporte.sap_der_detalle_reporte = [];
                this.resetDetalle();
            },
            resetDetalle()
            {
                this.validations = [];
                this.der_codigo = 0;
                this.detalle = {};
                this.detalle.der_codigo = 0;
                this.detalle.der_campo = '';
                this.detalle.der_descripcion = '';
                this.detalle.der_consulta = '';
                this.detalle.der_valor = '';
                this.detalle.der_select = false;
                this.detalle.der_obligatorio = false;
                this.detalle.der_fecha = false;
            },
            async setDetalle()
            {
                this.detalle.der_campo = this.detalle.der_campo.replace(/\s/g,'');

                if(this.detalle.der_campo.substring(0, 1) == '@')
                    this.detalle.der_campo = this.detalle.der_campo.substring(1, this.detalle.der_campo.length);

                if(this.reporte.sap_der_detalle_reporte.find(x=>x.der_campo == this.detalle.der_campo) != undefined)
                {
                    M.toast({html: 'Ya existe un parámetro con este nombre.'});
                    return;
                }
                var data = {
                    der_codigo: this.reporte.sap_der_detalle_reporte.length + 1,
                    der_codrep: this.reporte.rep_codigo,
                    der_campo: this.detalle.der_campo,
                    der_select: this.detalle.der_select,
                    der_consulta: this.detalle.der_consulta,
                    der_descripcion: this.detalle.der_descripcion.trim(),
                    der_fecha: this.detalle.der_fecha,
                    der_obligatorio: this.detalle.der_obligatorio,
                    der_valor: this.detalle.der_valor
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/Reportes/Detalle/', data)
                .then(() => {
                    this.reporte.sap_der_detalle_reporte.push(data);
                    this.detalle_reorder();
                    M.toast({html: 'El campo ha sido agregado.'});
                    this.resetDetalle();
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }

                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                });
                this.$root.stopLoading();
            },
            async actualizarDetalle()
            {
                this.detalle.der_campo = this.detalle.der_campo.replace(/\s/g,'');

                if(this.detalle.der_campo.substring(0, 1) == '@')
                    this.detalle.der_campo = this.detalle.der_campo.substring(1, this.detalle.der_campo.length);

                if(this.reporte.sap_der_detalle_reporte.find(x=>x.der_campo == this.detalle.der_campo && x.der_codigo != this.detalle.der_codigo) != undefined)
                {
                    M.toast({html: 'Ya existe un parámetro con este nombre.'});
                    return;
                }
                var data = {
                    der_codigo: this.detalle.der_codigo,
                    der_codrep: this.detalle.der_codrep,
                    der_campo: this.detalle.der_campo,
                    der_select: this.detalle.der_select,
                    der_consulta: this.detalle.der_consulta,
                    der_descripcion: this.detalle.der_descripcion.trim(),
                    der_fecha: this.detalle.der_fecha,
                    der_obligatorio: this.detalle.der_obligatorio,
                    der_valor: this.detalle.der_valor
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/Reportes/Detalle/', data)
                .then(() => {
                    var index = this.reporte.sap_der_detalle_reporte.findIndex(x=>x.der_codigo == this.detalle.der_codigo);
                    this.reporte.sap_der_detalle_reporte[index] = data;
                    M.toast({html: 'El campo ha sido actualizado.'});
                    this.resetDetalle();
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }

                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                });
                this.$root.stopLoading();
            },
            eliminarDetalle(der_codigo)
            {
                var index = this.reporte.sap_der_detalle_reporte.findIndex(x=>x.der_codigo == der_codigo);
                this.reporte.sap_der_detalle_reporte.splice(index, 1);
                this.detalle_reorder();
                if(this.detalle.der_codigo == der_codigo)
                {
                    this.resetDetalle();
                }
            },
            editarDetalle(der_codigo)
            {
                this.der_codigo = der_codigo;
                this.detalle = this.reporte.sap_der_detalle_reporte.find(x=>x.der_codigo == der_codigo);
            },
            detalle_reorder()
            {
                for(let i = 0; i <= this.reporte.sap_der_detalle_reporte.length - 1; i++)
                {
                    this.reporte.sap_der_detalle_reporte[i].der_codigo = i + 1;
                }
            },
            moverFila(index, movement)
            {
                if(movement == 1)
                {
                    this.reporte.sap_der_detalle_reporte[index + 1].der_codigo += -1;
                    this.reporte.sap_der_detalle_reporte[index].der_codigo += 1;
                }
                else
                {
                    this.reporte.sap_der_detalle_reporte[index - 1].der_codigo += 1;
                    this.reporte.sap_der_detalle_reporte[index].der_codigo -= 1;
                }
                this.reporte.sap_der_detalle_reporte = this.reporte.sap_der_detalle_reporte.sort(
                    (a, b) => {
                    if (a.der_codigo > b.der_codigo) {
                        return 1;
                    }
                    if (a.der_codigo < b.der_codigo) {
                        return -1;
                    }
                    return 0;
                });
            },
            async guardar()
            {
                this.validations = [];
                await this.axios.post('/Reportes', this.reporte)
                .then(response => {
                    this.reporte = response.data;
                    var index = this.reportes.findIndex(x=>x.rep_codigo == response.data.rep_codigo);
                    if(index == -1)
                    {
                        this.reportes.push(response.data);
                    }
                    else
                    {
                        this.reportes[index] = response.data;
                    }
                    M.toast({html: 'El reporte ha sido guardado.'});
                })
                .catch(error => {
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }

                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                    }
                });
            },
            async eliminar()
            {
                if(!confirm('¿Desea eliminar este reporte?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Reportes/' + this.reporte.rep_codigo)
                .then(() => {
                    var index = this.reportes.findIndex(x=>x.rep_codigo == this.reporte.rep_codigo);
                    this.reportes.splice(index, 1);
                    this.reset();
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            parametros(){
                var parametros = this.reporte.sap_der_detalle_reporte.map(x=>{
                    return '@' + x.der_campo;
                });
                var variables = ['@regional', '@_regional', '@_usuario', '@_codemp'];
                return parametros.concat(variables);
            }            
        }
    }
</script>