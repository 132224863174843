<template>
    <div class="row">
        <div class="col m8 l8 s12 offset-m2 offset-l2" style="border: solid 1px grey">
            <form method="post" @submit.prevent="setCliente()">

                <div class="row">
                    <div class="col m12 center">
                        <h5>Clientes</h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col m6">
                        <label>Nombres</label>
                        <input type="text" v-model="cliente.cli_nombres" />
                        <span class="helper-text">{{$validation(validations, "cli_nombres")}}</span>
                    </div>
                    <div class="col m6">
                        <label>Apellidos</label>
                        <input type="text" v-model="cliente.cli_apellidos" />
                        <span class="helper-text">{{$validation(validations, "cli_apellidos")}}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col m12">
                        <label>Dirección</label>
                        <input type="text" v-model="cliente.cli_direccion" />
                        <span class="helper-text">{{$validation(validations, "cli_direccion")}}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col m3">
                        <label>Gran Contribuyente</label>
                        <br>
                        <label><input type="radio" v-model="cliente.cli_contribuyente" name="cli_contribuyente" value="S"/><span>Si</span></label>
                        <label><input type="radio" v-model="cliente.cli_contribuyente" name="cli_contribuyente" value="N" /><span>No</span></label>
                        <span class="helper-text">{{$validation(validations, "cli_contribuyente")}}</span>
                    </div>
                    <div class="col m3">
                        <label>Pais</label>
                        <select class="browser-default" v-model="cliente.cli_codpai">
                            <option v-for="pais in paises" v-bind:value="pais.pai_codigo" :key="pais">{{ pais.pai_nombre }}</option>
                        </select>
                        <span class="helper-text">{{$validation(validations, "cli_codpai")}}</span>
                    </div>
                    <div class="col m2">
                        <label>Teléfono</label>
                        <input type="text" v-model="cliente.cli_telefonos" />
                        <span class="helper-text">{{$validation(validations, "cli_telefonos")}}</span>
                    </div>
                    <div class="col m2">
                        <label>Fax</label>
                        <input type="text" v-model="cliente.cli_fax" />
                        <span class="helper-text">{{$validation(validations, "cli_fax")}}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col m2">
                        <label>Registro</label>
                        <input type="text" v-model="cliente.cli_registro" />
                        <span class="helper-text">{{$validation(validations, "cli_registro")}}</span>
                    </div>
                    <div class="col m2">
                        <label>Actividad Económica</label>
                        <select class="browser-default" v-model="cliente.cli_actividad_economica">
                            <option v-for="actividad in actividades_economicas" :value="actividad.codigo" :key="actividad">{{ actividad.nombre }}</option>
                        </select>
                        <span class="helper-text">{{$validation(validations, "cli_actividad_economica")}}</span>
                    </div>
                    <div class="col m6">
                        <label>Giro</label>
                        <input type="text" v-model="cliente.cli_giro" />
                        <span class="helper-text">{{$validation(validations, "cli_giro")}}</span>
                    </div>
                    <div class="col m2">
                        <label>NIT/DUI</label>
                        <input type="text" v-model="cliente.cli_nit" />
                        <span class="helper-text">{{$validation(validations, "cli_nit")}}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col m12">
                        <button class="btn blue-ugb" v-show="index_cliente == -1" type="submit">Guardar</button>
                        <button class="btn blue-ugb" v-show="index_cliente != -1" type="submit">Actualizar</button>
                        <button class="btn red darken-2" v-show="index_cliente != -1" @click="cancelarEdicion()" type="button">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row m0 p0">
        <div class="col m8 l8 s12 offset-m2 offset-l2" style="border: solid 1px black" >
            <div class="row">
                <form method="post" @submit.prevent="buscar()">
                    <div class="col m8">
                        <label>Buscar</label>
                        <input type="text" id="txt_search" />
                    </div>
                    <div class="col m4">
                        <button class="btn blue-ugb" type="submit">Buscar</button>
                    </div>
                </form>
            </div>
            <div class="row">
                <div style="overflow: auto;">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px; width: 100%">
                    <thead>
                        <tr>
                            <th style="width: 45%">Nombre</th>
                            <th style="width: 10%">Registro</th>
                            <th style="width: 15%">NIT/DUI</th>
                            <th style="width: 10%">Teléfono/Fax</th>
                            <th style="width: 10%">Contribuyente</th>
                            <th style="width: 5%"></th>
                            <th style="width: 5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(cliente, index) in clientes" :key="cliente" v-bind:class="{'grey lighten-2': index == index_cliente}">
                            <td>{{ cliente.cli_nombres.trim() }} {{ cliente.cli_apellidos.trim() }}</td>
                            <td>{{ cliente.cli_registro }}</td>
                            <td>{{ cliente.cli_nit }}</td>
                            <td class="center">{{ cliente.cli_telefonos }} / {{ cliente.cli_fax }}</td>
                            <td class="center"><label><input type="checkbox" @click.prevent="" :checked="cliente.cli_contribuyente == 'S'" /><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarCliente(cliente.cli_codigo)"><i class="material-icons">edit</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarCliente(cliente.cli_codigo, index)"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="7" style="text-align: right;">{{ total_records }} registros.</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
         </div>
        </div>
    </div>

    <div class="row m0 p0">
        <div class="col m8 l8 s12 offset-m2 offset-l2" style="border: solid 1px black">
            <ul class="pagination">
                <li v-if="current_page > 1"><a href="#!" @click="getClientes(1)"><i class="material-icons">first_page</i></a></li>
                <li v-if="current_page > 1"><a href="#!" @click="getClientes(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
                <!-- <li v-bind:class="{active: page == current_page}"><a href="#!" @click="getClientes(page)">{{ page }}</a></li> -->
                <template v-for="page in total_pages" v-bind:key="page">
                    <li v-if="page < current_page + 4 && page > current_page - 4" v-bind:class="{active: page == current_page}"><a @click="getClientes(page)" href="#!">{{page}}</a></li>
                </template>
                <li v-if="current_page < total_pages"><a href="#!" @click="getClientes(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
                <li v-if="current_page < total_pages"><a href="#!" @click="getClientes(total_pages)"><i class="material-icons">last_page</i></a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                clientes: [],
                cliente: {},
                cliente_original: {},
                paises: [],
                actividades_economicas: [],
                validations: [],
                index_cliente: -1,
                total_pages: 0,
                current_page: 1,
                search: '',
                total_records: 0
            }
        },
        async mounted()
        {
            await this.getPaises();
            await this.getClientes();
            await this.getActividadesEconomicas();
            this.resetCliente();
        },
        methods: {
            async setCliente()
            {
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/Clientes', this.cliente)
                .then(response => {
                    if(response.status == 201)
                    {
                        this.clientes.unshift(response.data);
                        M.toast({html: 'El cliente ha sido creado.'});
                    }
                    if(response.status == 200)
                    {
                        var index = this.clientes.findIndex(x=>x.cli_codigo == response.data.cli_codigo);
                        this.clientes[index] = response.data;
                        M.toast({html: 'El cliente ha sido actualizado.'});
                    }
                    this.index_cliente = -1;
                    this.resetCliente();
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                        M.toast({html: 'Complete los campos.'});
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async getClientes(page)
            {
                this.$root.startLoading();
                await this.axios.get('/Clientes/UOnline?page=' + (page || 1) + '&search=' + this.search)
                .then(response => {
                    this.clientes = response.data.records;
                    this.current_page = response.data.current_page;
                    this.total_pages = response.data.total_pages;
                    this.total_records = response.data.total_records;
                });
                this.$root.stopLoading();
            },
            async getPaises()
            {
                this.$root.startLoading();
                await this.axios.get('/Paises')
                .then(response => {
                    this.paises = response.data;
                });
                this.$root.stopLoading();
            },
            resetCliente()
            {
                this.cliente = {};
                this.cliente.cli_codpai = 1;
                this.cliente.cli_giro = '';
                this.cliente.cli_nit = '';
                this.cliente.cli_registro = '';
                this.cliente.cli_telefonos = '';
                this.cliente.cli_fax = '';
            },
            editarCliente(codcli)
            {
                var index = this.clientes.findIndex(x=>x.cli_codigo == codcli);
                this.cliente = JSON.parse(JSON.stringify(this.clientes[index]));                
                this.index_cliente = index;
            },
            async eliminarCliente(codcli, index)
            {
                if(!confirm('¿Desea eliminar este cliente?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Clientes/' + codcli)
                .then(() => {
                    this.clientes.splice(index, 1);
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            cancelarEdicion()
            {
                this.index_cliente = -1;
                this.resetCliente();
            },
            async buscar()
            {
                this.search = document.querySelector('#txt_search').value;
                await this.getClientes();
            },
            async getActividadesEconomicas()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/ActividadEconomica')
                .then(response => {
                    this.actividades_economicas = response.data;
                });
                this.$root.stopLoading();
            }
        }
    }
</script>