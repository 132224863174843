<template>
    <div class="row m0 p0">
        <div class="col m12 card-panel">
            <form @submit.prevent="guardar">
                <div class="row">
                    <div class="col m1">kia
                        <label>ID</label>
                        <input type="text" disabled v-model="tmo_codigo">
                    </div>
                    <div class="col m2">
                        <label>Tipo*</label>
                        <select class="browser-default" v-model="tmo_tipo">
                            <option value="">Seleccione</option>
                            <option value="F">Estudiantes</option>
                            <option value="C">Clientes</option>
                        </select>
                        <span class="helper-text">{{$validation(validations, 'tmo_tipo')}}</span>
                    </div>
                    <div class="col m4">
                        <label>Descripción*</label>
                        <input type="text" v-model="tmo_descripcion">
                        <span class="helper-text">{{$validation(validations, 'tmo_descripcion')}}</span>
                    </div>
                    <div class="col m1">
                        <label>Valor Base</label>
                        <input type="text" v-model="tmo_valor">
                        <span class="helper-text">{{$validation(validations, 'tmo_valor')}}</span>
                    </div>
                    <div class="col m2">
                        <label>Cuenta</label>
                        <input type="text" v-model="tmo_cuenta">
                        <span class="helper-text">{{$validation(validations, 'tmo_cuenta')}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col m2">
                        <label>Aplica IVA</label>
                        <select class="browser-default" v-model="tmo_exento">
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Afecta Materia</label>
                        <select class="browser-default" v-model="tmo_afecta_materia">
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Es Colegiatura <a href="#!"><i class="material-icons" style="font-size:14px">help</i></a></label>
                        <select class="browser-default" v-model="tmo_talonarios">
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Aplica Beca</label>
                        <select class="browser-default" v-model="tmo_beca">
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Arancel Virtual</label>
                        <select class="browser-default" v-model="tmo_virtual">
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Genera Mora</label>
                        <select class="browser-default" v-model="tmo_genera_mora" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col m2">
                        <label>Único Por Ciclo</label>
                        <select class="browser-default" v-model="tmo_unico" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Tipo</label>
                        <select class="browser-default" v-model="tmo_tipo_mov" disabled>
                            <option value="N">Normal</option>
                            <option value="M">Matricula</option>
                            <option value="C">Cuota</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Arancel Req.</label>
                        <select class="browser-default" v-model="tmo_codtmo_req" disabled>
                            <option value="0">Ninguno</option>
                            <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Arancel Req. Op.</label>
                        <select class="browser-default" v-model="tmo_codtmo_req_v" disabled>
                            <option value="0">Ninguno</option>
                            <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Es Comp. de Cuota</label>
                        <select class="browser-default" v-model="tmo_comp_cuota" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Es Comp. de Matrícula</label>
                        <select class="browser-default" v-model="tmo_comp_matricula" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col m2">
                        <label>Cantidad Seleccionable</label>
                        <select class="browser-default" v-model="tmo_cantidad" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Es Recargo</label>
                        <select class="browser-default" v-model="tmo_recargo" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Es Diplomado</label>
                        <select class="browser-default" v-model="tmo_diplomado" disabled>
                            <option value="N">No</option>
                            <option value="S">Si</option>
                        </select>
                    </div>
                    <div class="col m3" style="display:none">
                        <label>Grupo de Artículos</label>
                        <select class="browser-default" v-model="itmsGrpCod">
                            <option v-for="grupo in grupos" v-bind:key="grupo" v-bind:value="grupo.itmsGrpCod">{{grupo.itmsGrpnam}}</option>
                        </select>                        
                        <span class="helper-text">{{$validation(validations, 'ItmsGrpCod')}}</span>
                    </div>
                </div>
                <div class="row card-panel blue lighten-4">
                    <div class="col m12">
                        <div class="col m2">
                            <label class="black-text">Eventos/Cursos</label>
                            <select class="browser-default" v-model="ecar_tipo">
                                <option value="N">Sin Asignar</option>
                                <option value="C">Cursos</option>
                                <option value="E">Eventos</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'ecar_tipo')}}</span>
                        </div>
                        <div class="col m4" v-show="ecar_tipo != 'N'">
                            <label>Unidad</label>
                            <select class="browser-default" v-model="ecar_coduni">
                                <option v-for="unidad in unidades" :key="unidad" :value="unidad.uni_codigo">{{ unidad.uni_nombre }}</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'ecar_coduni')}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col m12">
                        <button class="btn blue-ugb" type="submit">{{(tmo_codigo > 0 ? 'ACTUALIZAR':'CREAR')}}<i class="material-icons right">{{(tmo_codigo > 0 ? 'update':'save')}}</i></button>
                        <button class="btn teal darken-4" v-show="tmo_codigo > 0" type="button" @click="eliminar">ELIMINAR <i class="material-icons right">delete</i></button>
                        <button class="btn red darken-4 modal-close" type="button">CERRAR <i class="material-icons right">close</i></button>
                    </div>
                </div>
            </form>
        </div>        
    </div>
</template>

<script>
import M from 'materialize-css'
    export default{
        name: 'EditarArancel',
        props: {
            aranceles: Array
        },
        data(){
            return {
                arancel: {},
                tmo_codigo: 0,
                tmo_descripcion: '',
                tmo_valor: 0,
                tmo_exento: 'N',
                tmo_afecta_materia: 'N',
                tmo_talonarios: 'N',
                tmo_beca: 'N',
                tmo_virtual: 'N',
                tmo_genera_mora: 'N',
                tmo_unico: 'N',
                tmo_tipo_mov: 'N',
                tmo_codtmo_req: 0,
                tmo_codtmo_req_v: 0,
                tmo_comp_matricula: 'N',
                tmo_comp_cuota: 'N',
                tmo_cantidad: 'N',
                tmo_recargo: 'N',
                tmo_diplomado: 'N',
                tmo_tipo: '',
                tmo_cuenta: '',
                itmsGrpCod: 0,
                grupos: [],
                validations: [],
                unidades: [],
                ecar_tipo: 'N',
                ecar_coduni: 0
            }
        },
        methods: {
            async init(codtmo)
            {
                this.reset();
                await this.getUnidades();
                if(this.grupos.length == 0)
                {
                    await this.axios.get('/GruposArticulos')
                    .then(response => {
                        this.grupos = response.data;
                    });
                }
                if(codtmo > 0)
                {
                    await this.axios.get('/Aranceles/' + codtmo)
                    .then(response => {
                        this.tmo_codigo = response.data.tmo_codigo;
                        this.tmo_descripcion = response.data.tmo_descripcion;
                        this.tmo_valor = response.data.tmo_valor;
                        this.tmo_exento = response.data.tmo_exento;
                        this.tmo_afecta_materia = response.data.tmo_afecta_materia;
                        this.tmo_talonarios = response.data.tmo_talonarios;
                        this.tmo_beca = response.data.tmo_beca;
                        this.tmo_virtual = response.data.tmo_virtual;
                        this.tmo_genera_mora = response.data.tmo_genera_mora;
                        this.tmo_unico = response.data.tmo_unico;
                        this.tmo_tipo_mov = response.data.tmo_tipo_mov;
                        this.tmo_codtmo_req = response.data.tmo_codtmo_req;
                        this.tmo_codtmo_req_v = response.data.tmo_codtmo_req_v;
                        this.tmo_comp_matricula = response.data.tmo_comp_matricula;
                        this.tmo_comp_cuota = response.data.tmo_comp_cuota;
                        this.tmo_cantidad = response.data.tmo_cantidad;
                        this.tmo_recargo = response.data.tmo_recargo;
                        this.tmo_diplomado = response.data.tmo_diplomado;
                        this.itmsGrpCod = response.data.itmsGrpCod;
                        this.tmo_cuenta = response.data.tmo_cuenta;
                        this.tmo_tipo = response.data.tmo_tipo;
                        this.ecar_coduni = response.data.ecar_coduni;
                        this.ecar_tipo = response.data.ecar_tipo;
                    })
                    .catch(error => {
                        if(error.response.status == 404)
                        {
                            M.toast({html: 'El arancel no ha sido encontrado.'});
                            this.reset();
                        }
                    });
                }             
            },
            async getUnidades()
            {
                await this.axios.get('/Unidades')
                .then(response => {
                    this.unidades = response.data;
                });
            },
            reset()
            {
                this.tmo_codigo = 0;
                this.tmo_descripcion = '';
                this.tmo_valor = 0;
                this.tmo_exento = 'N';
                this.tmo_afecta_materia = 'N';
                this.tmo_talonarios = 'N';
                this.tmo_beca = 'N';
                this.tmo_virtual = 'N';
                this.tmo_genera_mora = 'N';
                this.tmo_unico = 'N';
                this.tmo_tipo_mov = 'N';
                this.tmo_codtmo_req = 0;
                this.tmo_codtmo_req_v = 0;
                this.tmo_comp_matricula = 'N';
                this.tmo_comp_cuota = 'N';
                this.tmo_cantidad = 'N';
                this.tmo_recargo = 'N';
                this.tmo_diplomado = 'N';
                this.itmsGrpCod = 0;
                this.tmo_cuenta = '';
                this.tmo_tipo = '';
                this.validations = [];
            },
            async guardar()
            {
                var data = {
                    tmo_codigo: this.tmo_codigo,
                    tmo_descripcion: this.tmo_descripcion,
                    tmo_valor: this.tmo_valor,
                    tmo_exento: this.tmo_exento,
                    tmo_afecta_materia: this.tmo_afecta_materia,
                    tmo_talonarios: this.tmo_talonarios,
                    tmo_beca: this.tmo_beca,
                    tmo_virtual: this.tmo_virtual,
                    tmo_genera_mora: this.tmo_genera_mora,
                    tmo_unico: this.tmo_unico,
                    tmo_tipo_mov: this.tmo_tipo_mov,
                    tmo_codtmo_req: this.tmo_codtmo_req,
                    tmo_codtmo_req_v: this.tmo_codtmo_req_v,
                    tmo_comp_matricula: this.tmo_comp_matricula,
                    tmo_comp_cuota: this.tmo_comp_cuota,
                    tmo_cantidad: this.tmo_cantidad,
                    tmo_recargo: this.tmo_recargo,
                    tmo_diplomado: this.tmo_diplomado,
                    itmsGrpCod: this.itmsGrpCod,
                    tmo_estado: 'A',
                    tmo_cuenta: this.tmo_cuenta,
                    tmo_tipo: this.tmo_tipo,
                    ecar_tipo: this.ecar_tipo,
                    ecar_coduni: this.ecar_coduni
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/Aranceles', data)
                .then(response => {
                    this.tmo_codigo = response.data.tmo_codigo;
                    this.$parent.pushArancel(response.data);
                    M.toast({html: 'El arancel ha sido '+(response.status == 200 ? 'actualizado':'registrado')+'.'});
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }

                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async eliminar()
            {
                if(confirm('¿Desea eliminar este arancel?'))
                {
                    this.$root.startLoading();
                    await this.axios.delete('/Aranceles/' + this.tmo_codigo)
                    .then(() => {
                        this.$parent.eliminar(this.tmo_codigo);
                    })
                    .catch(error => {
                        var data = error.response.data;
                        M.toast({html: data.message});
                    });
                    this.$root.stopLoading();
                }
            }
        }
    }
</script>