<template>
    <div class="col m12 card-panel">
        <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px" v-show="aranceles.length > 0">
            <thead>
                <tr>
                    <th>Ciclo</th>
                    <th>Arancel</th>
                    <th>Matrícula</th>
                    <th>Fijo</th>
                    <th>Fecha</th>
                    <th>Comentario</th>
                    <th>Modificar</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="arancel in aranceles" v-bind:key="arancel">
                    <td class="center">Ciclo {{arancel.are_codcic}} - {{arancel.are_anio}}</td>
                    <td>{{arancel.arancel}}</td>
                    <td class="center">${{arancel.are_matricula.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                    <td class="center"><label><input type="checkbox" class="filled-in" :checked="arancel.are_fijo"><span></span></label></td>
                    <td class="center">{{$dateFormat(new Date(arancel.are_fecha), 'dd/MM/yyyy')}}</td>
                    <td>{{arancel.are_comentario}}</td>
                    <td class="center"><a href="#!" v-if="arancel.pca_editable"><i class="material-icons">edit</i></a></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default{
   data(){
       return {
           estudiante: {},
           aranceles: []
       }
   },
   methods: {
       async getAranceles()
       {
           this.$root.startLoading();
           await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/Aranceles')
            .then(response => {
                this.aranceles = response.data;
            });
            this.$root.stopLoading();
       },
       async init(estudiante)
       {
           this.estudiante = estudiante;
           await this.getAranceles(estudiante);
       }
   }
}
</script>