import {createWebHistory, createRouter} from 'vue-router'
import Home from '../views/Home.vue'
import Facturacion from '../views/Facturacion.vue'
import Login from '../views/Login.vue'
import Aranceles from '../views/Aranceles.vue'
import Tirajes from '../views/Tirajes.vue'
import Usuarios from '../views/Usuarios.vue'
import ArancelesCarrera from '../views/ArancelesCarrera.vue'
import FechasLimites from '../views/FechasLimites.vue'
import Exoneraciones from '../views/Exoneraciones.vue'
import DescuentoFijo from '../views/DescuentoFijo.vue'
import FacturaDiaria from '../views/FacturaDiaria.vue'
import Estudiantes from '../views/Estudiantes.vue'
import ModificarArancel from '../views/ModificarArancel.vue'
import Estudiante from '../views/Estudiante.vue'
import FacturacionClientes from '../views/FacturacionClientes.vue'
import Reportes from '../views/Reportes.vue'
import ConfigurarReportes from '../views/ConfigurarReportes'
import Comprobantes from '../views/Comprobantes'
import Pagos from '../views/Pagos'
import Pagadito from '../views/Pagadito'
import Clientes from'../components/ClientesComponent';
import ValorCuota from'../views/ValorCuota';
import ClientesAdmin from'../views/ClientesAdmin';
import EstudiantesAdmin from'../views/EstudiantesAdmin';
import PagosCuotas from'../views/PagosCuotas';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Facturacion',
        name: 'Facturacion',
        component: Facturacion
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },
    {
        path: '/Aranceles',
        name: 'Aranceles',
        component: Aranceles
    },
    {
        path: '/Tirajes',
        name: 'Tirajes',
        component: Tirajes
    },
    {
        path: '/Usuarios',
        name: 'Usuarios',
        component: Usuarios
    },
    {
        path: '/ArancelesCarrera',
        name: 'ArancelesCarrera',
        component: ArancelesCarrera
    },
    {
        path: '/FechasLimites',
        name: 'FechasLimites',
        component: FechasLimites
    },
    {
        path: '/Exoneraciones',
        name: 'Exoneraciones',
        component: Exoneraciones
    },
    {
        path: '/DescuentoFijo',
        name: 'DescuentoFijo',
        component: DescuentoFijo
    },
    {
        path: '/FacturaDiaria',
        name: 'FacturaDiaria',
        component: FacturaDiaria
    },
    {
        path: '/Estudiantes',
        name: 'Estudiantes',
        component: Estudiantes
    },
    {
        path: '/Modificar',
        name: 'Modificar',
        component: ModificarArancel
    },
    {
        path: '/Estudiante/:id',
        name: 'Estudiante',
        component: Estudiante
    },
    {
        path: '/FacturacionClientes',
        name: 'FacturacionClientes',
        component: FacturacionClientes
    },
    {
        path: '/Reportes',
        name: 'Reportes',
        component: Reportes
    },
    {
        path: '/ConfigurarReportes',
        name: 'ConfigurarReportes',
        component: ConfigurarReportes
    },
    {
        path: '/Comprobantes',
        name: 'Comprobantes',
        component: Comprobantes
    },
    {
        path: '/EnlacePago',
        name: 'Pagos',
        component: Pagos
    }    ,
    {
        path: '/Pagadito',
        name: 'Pagadito',
        component: Pagadito
    },
    {
        path: '/Clientes',
        name: 'Clientes',
        component: Clientes
    },
    {
        path: '/ValorCuota',
        name: 'ValorCuota',
        component: ValorCuota
    },
    {
        path: '/ClientesAdmin/:id?',
        name: 'ClientesAdmin',
        component: ClientesAdmin
    },
    {
        path: '/EstudiantesAdmin/:id',
        name: 'EstudiantesAdmin',
        component: EstudiantesAdmin
    },
    {
        path: '/PagosCuotas',
        name: 'PagosCuotas',
        component: PagosCuotas
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;