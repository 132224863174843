<template>
    <div class="row m0 p0">
        <div class="col m12 card-panel">
            <div class="row">
                <form @submit.prevent="buscarEstudiante">
                    <div class="col m4">
                            <label>Buscar</label>
                            <input type="text" id="txt_search_estudiante" tabindex="1" autofocus>
                    </div>
                    <div class="col m1">
                        <button type="submit" class="btn blue-ugb"><i class="material-icons">search</i></button>
                    </div>
                    <div class="col m1">
                        <button type="button" class="btn red darken-4 modal-close"><i class="material-icons">close</i></button>
                    </div>
                </form>
            </div>
            <table class="table tbl-condensed bordered striped" style="font-size:12px">
                <thead>
                    <tr>
                        <th style="width:10%">Carnet</th>
                        <th style="width:30%">Estudiante</th>
                        <th style="width:40%">Carrera</th>
                        <th style="width:5%">Estado</th>
                        <th style="width:5%">FE</th>
                        <th style="width:5%"></th>
                        <th style="width:5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="estudiante in estudiantes" v-bind:key="estudiante">
                        <td class="center">{{estudiante.per_carnet}}</td>
                        <td>{{estudiante.per_apellidos_nombres}}</td>
                        <td>{{estudiante.car_nombre}}</td>
                        <td class="center">{{estudiante.tie_descripcion}}</td>
                        <td class="center" style="position: relative;">
                            <a target="_blank" v-bind:href="'/EstudiantesAdmin/' + estudiante.per_codigo"><i class="material-icons">phonelink_setup</i></a>
                            <i class="material-icons green-text" title="El estudiante se encuentra actualizado para facturación electrónica." v-show="estudiante.per_mh_actualizado" style="position: absolute; top:0px; right: 0px; font-size: 12px">check_circle</i>
                        </td>
                        <th class="center"><a href="#!" @click="seleccionar(estudiante)"><i class="material-icons">playlist_add_check</i></a></th>
                        <td class="center"><a target="_blank" v-bind:href="'/Estudiante/' + estudiante.per_codigo"><i class="material-icons">open_in_new</i></a></td>
                    </tr>
                </tbody>
            </table>
            <div class="row m0 p0">
                <ul class="pagination p0" style="font-size:8px">
                    <li><a href="#!" v-if="current_page != 1" @click="getEstudiantes(1)"><i class="material-icons">first_page</i></a></li>
                    <li><a v-if="current_page > 1" href="#!" @click="getEstudiantes(current_page - 1)"><i class="material-icons">chevron_left</i></a></li>
                    <template v-for="page in total_pages" v-bind:key="page">
                        <li v-bind:class="{active: page == current_page}" v-if="page > (current_page - 5) && page < (current_page + 5)"><a href="#!" @click="getEstudiantes(page)">{{page}}</a></li>
                    </template>
                    <li><a v-if="current_page < total_pages" href="#!" @click="getEstudiantes(current_page + 1)"><i class="material-icons">chevron_right</i></a></li>
                    <li><a v-if="current_page != total_pages" href="#!" @click="getEstudiantes(total_pages)"><i class="material-icons">last_page</i></a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default{
        name: 'BuscarEstudiante',
        data(){
            return {
                estudiantes: [],
                search: '',
                total_pages: 0,
                current_page: 1,
            }
        },
        methods: {
            async getEstudiantes(page)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Estudiantes?page=' + page + '&search=' + this.search)
                    .then(response => {
                        this.estudiantes = response.data.records;
                        this.total_pages = response.data.total_pages;
                        this.current_page = response.data.current_page;
                    });
                    this.$root.stopLoading();
                },
            async init()
            {
                if(this.estudiantes.length == 0)
                {
                    await this.getEstudiantes();
                }
            },
            async buscarEstudiante()
            {
                this.current_page = 1;
                this.search = document.querySelector('#txt_search_estudiante').value;
                await this.getEstudiantes(1);
            },
            async seleccionar(estudiante)
            {
                if(estudiante.per_estado == 'I')
                {
                    M.toast({html: 'No puede seleccionar un estudiante inactivo.'});
                    return;
                }
                if(!await this.isUpdated(estudiante.per_codigo))
                {
                    M.toast({html: 'Es necesario que actualice los datos del estudiante.'});
                    return;
                }
                this.$parent.postBackSeleccionarEstudiante(estudiante);
            },
            async isUpdated(codper)
            {
                var updated = false;
                await this.axios.get('/Estudiantes/' + codper + '/ActualizadoFE')
                .then(() => {
                    updated = true;
                })
                .catch(()=>{
                    updated = false;
                });
                return updated;
            }
        }
    }
</script>