<template>
    <div class="row card-panel">
        <!-- <div class="row m0 p0">
            <div class="col m5 m0 p0">
                <span class="badge new red darken-4 left" data-badge-caption="">El enlace tiene una validez de 24 horas</span>
            </div>
        </div> -->
    <div class="col m5 card-panel"> 
        <div class="col m12">
                <div class="row">
                    <blockquote>Generar Enlace de Pago</blockquote>                    
                </div>
                <div class="row">
                    <div class="col m4">
                        <label>Ciclo</label>
                        <select v-model="codcil" class="browser-default">
                            <option value="0">Seleccione</option>
                            <option v-for="ciclo in ciclos" :key="ciclo" :value="ciclo.cil_codigo">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}}</option>
                        </select>
                    </div>
                    <div class="col m8">
                        <label>Estudiante</label>
                        <small class="right"><a @click="buscarEstudiante()" href="#!"><i class="material-icons" style="font-size:18px">search</i></a></small> 
                        <input type="text" v-model="cliente.nombre" disabled>
                    </div>
                </div>
            </div>

            <div class="col m12">
                <div class="row">
                    <div class="col m5">
                        <label>Arancel</label>
                        <select v-model="codtmo" class="browser-default" :disabled="noStudent">
                            <option value="0">Seleccione</option>
                            <option v-for="arancel in aranceles" :key="arancel" :value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                        </select>
                    </div>            
                    <div class="col m3">
                        <label>Mes</label>
                        <select v-model="mes" class="browser-default" :disabled="noStudent">
                            <option value="0">Ninguno</option>
                            <option v-for="mes in meses" :key="mes" :value="mes.mes_codigo">{{mes.mes_nombre}}</option>
                        </select>
                    </div>
                    <div class="col m2" v-show="afectaMateria">
                        <label>Materia</label>
                        <select v-model="codmat" class="browser-default" :disabled="noStudent">
                            <option value="">Ninguno</option>
                            <option v-for="materia in materias" :key="materia" :value="materia.mat_codigo">{{materia.mat_codigo}} - {{materia.mat_nombre}}</option>
                        </select>
                    </div>
                    <div class="col m2">
                        <label>Monto</label>
                        <input type="text" v-model="valor" style="text-align:right" :disabled="noStudent">
                    </div>
                    <div class="col m12">
                        <button class="btn blue-ugb" style="margin-top:15px; width:100%" v-bind:disabled="codtmo == 0 || isNaN(valor) || valor <= 0" @click="setDetalle()">Agregar <i class="material-icons right">add_circle</i></button>
                    </div>
                </div>               
            </div>

            <div class="col m12" v-show="detalles.length > 0">
                <div class="row">
                    <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                        <thead>
                            <tr>
                                <th style="width:65%">Arancel</th>
                                <th style="width:15%">Mes</th>
                                <th style="width:15%">Monto</th>
                                <th style="width:5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(detalle, index) in detalles" :key="detalle">
                                <td>{{detalle.tmo_descripcion}}</td>
                                <td class="center">{{detalle.mes_nombre}}</td>
                                <td style="text-align:right">${{detalle.dep_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td class="center"><a href="#!" @click="detalles.splice(index, 1)"><i class="material-icons">delete</i></a></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="black white-text">
                                <th colspan="2" class="center">TOTAL</th>
                                <th style="text-align:right">${{dmo_total.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="col m12">
                <div class="row">
                    <button class="btn blue-ugb" @click="generar()" v-bind:disabled="detalles.length == 0 || (cliente.codigo_cli || 0) == 0 || codcil == 0">{{epa_codigo == 0 ? 'Generar':'Actualizar'}} <i class="material-icons right">sync</i></button>
                    &nbsp;
                    <button class="btn red darken-4" v-show="detalles.length > 0" @click="reset()">Cancelar <i class="material-icons right">cancel</i></button>
                </div>
                
                <div class="row" v-show="epa_id != ''" style="border: dotted 1px black; padding: 10px; background-color:#f1f1f1">
                    <blockquote>
                        <a v-bind:href="(url + epa_id)" target="_blank">{{(url + epa_id)}}</a> &nbsp;<a href="#!" @click="copyLink()" title="Copiar enlace"><i class="material-icons">content_copy</i></a>
                        <input type="text" style="display:none" id="epa_id_url" v-bind:value="(url + epa_id)">
                    </blockquote>
                    <button class="btn blue-ugb btn-small" @click="enviarCorreo(cliente.email || cliente.email_alt)">Enviar al Correo {{cliente.email}}<i class="material-icons right">mail</i></button>
                    
                </div>
                <div class="row">
                   <span class="badge new red darken-4 left" data-badge-caption="">*El enlace tiene una validez de 24 horas</span>
                </div>
            </div>
        </div>

        <div class="col m7">
            <div class="row m0 p0">
                <div class="col m9">
                    <label>Buscar</label>
                    <input type="text" v-model="search">
                </div>
                <div class="col m3 right">
                    <label><input type="checkbox" @change="getEnlaces()" class="filled-in" v-model="all"><span>Mostrar Todos</span></label>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="card-panel" style="max-height:400px; overflow:auto">
                    <table class="table tbl-condensed tbl-bordered striped white" style="font-size:12px">
                        <thead>
                            <tr>
                                <th style="width:12%">Carnet</th>
                                <th style="width:38%">Nombre</th>
                                <th style="width:12%">Ciclo</th>
                                <th style="width:10%">Fecha</th>
                                <th style="width:8%">Estado</th>
                                <th style="width:10%">Inactivado</th>
                                <th colspan="2" style="width:10%"></th>
                                <th>Usuario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="enlace in enlaces_filter" :key="enlace" v-bind:class="{'grey lighten-1':enlace.epa_codigo == epa_codigo}">
                                <td class="center">{{enlace.per_carnet}}</td>
                                <td>{{enlace.per_nombres_apellidos}}</td>
                                <td class="center">{{enlace.cic_nombre}} - {{enlace.cil_anio}}</td>
                                <td>{{enlace.epa_fecha}}</td>
                                <td class="center"><label><input type="checkbox" class="filled-in" :checked="enlace.epa_estado" @click.prevent=""><span></span></label></td>
                                <td class="center">{{enlace.epa_fecha_inactivacion}}</td>
                                <td class="center"><a href="#!" v-if="enlace.epa_codmov == 0 && enlace.epa_estado" @click="getEnlace(enlace.epa_codigo)"><i class="material-icons">edit</i></a></td>
                                <td class="center"><a href="#!" v-if="enlace.epa_codmov == 0 && enlace.epa_estado" @click="eliminarEnlace(enlace.epa_codigo)"><i class="material-icons">delete</i></a></td>
                                <td>{{ enlace.epa_usuario }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="modal-buscar-estudiante-pagos">
        <div class="modal-content">
            <BuscarEstudiante ref="buscarEstudiante" />
        </div>
    </div>
</template>

<script>
import BuscarEstudiante from '../components/BuscarEstudiante.vue'
import M from 'materialize-css'
    export default {
        components: {
            BuscarEstudiante
        },
        data(){
            return {
                epa_codigo: 0,
                cliente: {},
                transaccion: {},
                detalles: [],
                epa_id: '',
                codpla: 0,

                ciclos: [],
                codcil: 0,
                modales: [],
                modal_estudiante: {},

                aranceles: [],
                meses: [],

                codtmo: 0,
                mes: 0,
                valor: 0,

                url: 'https://estudiantes.ugb.edu.sv/PagoUGB/Enlace/',

                enlaces: [],

                materias: [],
                codmat: '0',
                all: true,
                search: ''
            }
        },
        async mounted()
        {
            this.modales = M.Modal.init(document.querySelectorAll('.modal'), null);
            await this.getCiclos();
            await this.getMeses();
            await this.getAranceles();
            await this.getEnlaces();
        },
        methods: {
            async postBackSeleccionarEstudiante(estudiante)
            {
                this.cliente.codigo_cli = estudiante.per_codigo;
                this.cliente.nombre = estudiante.per_nombres_apellidos;
                this.cliente.alias = estudiante.per_carnet;
                this.cliente.email = estudiante.per_email;
                this.cliente.email_alt = estudiante.per_email2;
                this.codpla = estudiante.pla_codigo;
                this.modal_estudiante.close();
                this.codmat = '';
                await this.getMaterias();
            },
            async getCiclos()
            {
                this.$root.startLoading();
                await this.axios.get('/Ciclos')
                .then(response => {
                    this.ciclos = response.data;
                });
                this.$root.stopLoading();
            },
            async buscarEstudiante()
            {
                this.$root.startLoading();
                await this.$refs.buscarEstudiante.init();
                this.$root.stopLoading();
                this.modal_estudiante =  M.Modal.getInstance(document.querySelector('#modal-buscar-estudiante-pagos'));
                this.modal_estudiante.open();
            },
            async getAranceles()
            {
                this.$root.startLoading();
                await this.axios.get('/Aranceles/Enlace')
                .then(response => {
                    this.aranceles = response.data;//.filter(x=>x.tmo_afecta_materia != 'S' && x.tmo_tipo_mov == 'N');
                });
                this.$root.stopLoading();
            },
            async getMeses()
            {
                this.$root.startLoading();
                await this.axios.get('/Meses')
                .then(response => {
                    this.meses = response.data;
                });
                this.$root.stopLoading();
            },
            setDetalle()
            {
                if(isNaN(parseFloat(this.valor)))
                {
                    M.toast({html: 'Ingrese un monto válido.'});
                    return;
                }
                if(this.afectaMateria && this.codmat == '')
                {
                    M.toast({html: 'Seleccione una materia.'});
                    return false;
                }

                var materia = this.afectaMateria ? this.materias.find(x=>x.mat_codigo == this.codmat):{};
                var data = {
                    dep_codigo: 0,
                    dep_codepa: 0,
                    dep_codtmo: this.codtmo,
                    tmo_descripcion: this.aranceles.find(x=>x.tmo_codigo == this.codtmo).tmo_descripcion + (this.afectaMateria ? ' (' +materia.mat_codigo + ' - ' + materia.mat_nombre + ')':''),
                    dep_valor: parseFloat(this.valor),
                    dep_mes: this.mes,
                    dep_codmat: this.afectaMateria ? this.codmat:'',
                    mes_nombre: this.mes == 0 ? '':this.meses.find(x=>x.mes_codigo == this.mes).mes_nombre,
                    dep_materia: this.afectaMateria ? materia.mat_codigo + ' - ' + materia.mat_nombre:''
                };

                this.detalles.push(data);
                this.codtmo = 0;
                this.mes = 0;
                this.valor = 0;
            },
            reset()
            {
                this.codtmo = 0;
                this.mes = 0;
                this.valor = 0;
                this.detalles = [];
                this.cliente = {};
                this.codcil = 0;
                this.epa_codigo = 0;
                this.epa_id = '';
            },
            async generar()
            {
                var detalle = [];
                for(let i = 0; i <= this.detalles.length - 1; i++)
                {
                    detalle.push({
                        dep_codigo: 0,
                        dep_codepa: this.epa_codigo,
                        dep_codtmo: this.detalles[i].dep_codtmo,
                        dep_mes: this.detalles[i].dep_mes,
                        dep_valor: this.detalles[i].dep_valor,
                        dep_codmat: this.detalles[i].dep_codmat,
                        dep_materia: this.detalles[i].dep_materia
                    })
                }
                var payload = {
                    epa_codigo: this.epa_codigo,
                    epa_codper: this.cliente.codigo_cli,
                    epa_codcil: this.codcil,
                    col_dep_detalle_enlace_pago: detalle,
                    epa_id: this.epa_id,
                    epa_fecha: null,
                    epa_usuario: ''
                };
                this.$root.startLoading();
                await this.axios.post('/Pagos/Generar', payload)
                .then(async (response) => {
                    if(response.status == 201)
                    {
                        this.epa_codigo = response.data.epa_codigo;
                        this.epa_id = response.data.epa_id;
                        M.toast({html: 'El enlace ha sido creado.'});
                        await this.getEnlaces();
                    }
                    if(response.status == 204)
                    {
                        M.toast({html: 'Los datos han sido actualizados.'});
                    }
                })
                .catch(error => {
                    M.toast({html: error.response.data.message});
                });
                this.$root.stopLoading();
            },
            copyLink()
            {
                var copyText = document.getElementById("epa_id_url");

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices */

                /* Copy the text inside the text field */
                navigator.clipboard.writeText(copyText.value);

                /* Alert the copied text */
                M.toast({html: 'El enlace ha sido copiado.'});
            },
            async getEnlace(codepa)
            {
                await this.axios.get('/Pagos/' + codepa)
                .then(response => {
                    this.cliente = response.data;
                    this.epa_id = response.data.epa_id;
                    this.codcil = response.data.epa_codcil;
                    this.epa_codigo = response.data.epa_codigo;
                    this.epa_id = response.data.epa_id;
                    this.detalles = response.data.detalle;
                });
            },
            async getEnlaces()
            {
                this.$root.startLoading();
                await this.axios.get('/Pagos?all=' + this.all)
                .then(response => {
                    this.enlaces = response.data;
                });
                this.$root.stopLoading();
            },
            async eliminarEnlace(codepa)
            {
                if(!confirm('¿Desea eliminar este enlace?')) return false;
                await this.axios.delete('/Pagos/' + codepa)
                .then(() => {
                    var index = this.enlaces.findIndex(x=>x.epa_codigo == codepa);
                    if(index != -1)
                    {
                        this.enlaces.splice(index, 1);
                    }
                    if(codepa == this.epa_codigo)
                    {
                        this.reset();
                    }
                });
            },
            async enviarCorreo()
            {
                if(!confirm('¿Desea compartir por correo el enlace de pago?')) return false;
                var payload = {
                    id: this.epa_id,
                    email: this.cliente.email,
                    //email: "jose.jairo.fuentes@gmail.com",
                    email_alt: this.cliente.email_alt,
                    name: this.cliente.nombre
                };
                this.$root.startLoading();
                await this.axios.post('/Pagos/Email/', payload)
                .then(() => {
                    M.toast({html: 'El correo ha sido enviado.'});
                });
                this.$root.stopLoading();
            },
            async getMaterias()
            {
                this.$root.startLoading();
                await this.axios.get('/Planes/' + this.codpla + '/Materias')
                .then(response => {
                    this.materias = response.data;
                });
                this.$root.stopLoading();
            }
        },
        computed: {
            dmo_total(){
                return this.detalles.reduce((a, b) => a + b.dep_valor, 0);
            },
            noStudent()
            {
                return (this.cliente.codigo_cli || 0) == 0 || this.codcil == 0;
            },
            afectaMateria()
            {
                var result = this.aranceles.find(x=>x.tmo_codigo == this.codtmo);
                if(result == undefined) return false;
                return result.tmo_afecta_materia == 'S';
            },
            enlaces_filter()
            {
                return this.enlaces.filter(x=> 
                                                x.per_nombres_apellidos.toLowerCase().indexOf(this.search.toLowerCase())  != -1 ||
                                                x.per_carnet.toLowerCase().indexOf(this.search.toLowerCase())  != -1 ||
                                                x.epa_usuario.toLowerCase().indexOf(this.search.toLowerCase())  != -1
                                         );
            }
        }
    }
</script>