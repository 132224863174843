<template>
    <div class="row m0 p0">
        <div class="col m12 card-panel m0 p0" v-if="estudiante != null">
            <div class="col m4">
                <table class="table white striped" style="font-size:12px; width: 100%">
                    <tbody>
                        <tr>
                            <th colspan="2" class="center white ">
                                <img style="height:200px; border: solid 1px white" class="responsive-img" v-bind:src="('data:image/png;base64, ' + estudiante.per_foto)">
                                <hr>
                                {{estudiante.per_nombres_apellidos.toUpperCase()}}
                            </th>
                        </tr>
                        <tr>
                            <th style="width:35%">Carnet</th>
                            <td>{{estudiante.per_carnet}}</td>
                        </tr>
                        <tr>
                            <th style="width:25%">Fecha de Nac.</th>
                            <td>{{estudiante.per_fecha_nac}}</td>
                        </tr>
                        <tr>
                            <th style="width:25%">Estado</th>
                            <td>{{estudiante.tie_descripcion}}</td>
                        </tr>
                        <tr>
                            <th style="width:25%">Tipo</th>
                            <td>{{estudiante.per_tipo}}</td>
                        </tr>
                        <tr>
                            <th style="width:25%">Facultad</th>
                            <td>{{estudiante.fac_nombre}}</td>
                        </tr>
                        <tr>
                            <th style="width:25%">Carrera</th>
                            <td>{{estudiante.car_nombre}}</td>
                        </tr>
                        <tr>
                            <th style="width:25%">Plan</th>
                            <td>{{estudiante.pla_nombre}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col m8">
                <div class="row">
                    <div id="div_top_hypers">
                        <ul id="ul_top_hypers">
                            <li v-bind:class="{active: current_tab == 1}" @click="setActiveTab(1)"><a href="#!" class="a_top_hypers white-text">PAGOS REALIZADOS</a></li>
                            <li v-bind:class="{active: current_tab == 7}" @click="setActiveTab(7)"><a href="#!" class="a_top_hypers white-text">COMPROBANTES</a></li>
                            <li v-if="estudiante.car_codigo > 0" v-bind:class="{active: current_tab == 2}" @click="setActiveTab(2)"><a href="#!" class="a_top_hypers white-text">RÉCORD ACADÉMICO</a></li>
                            <li v-bind:class="{active: current_tab == 3}" @click="setActiveTab(3)"><a href="#!" class="a_top_hypers white-text">EXONERACIONES</a></li>
                            <li v-bind:class="{active: current_tab == 4}" @click="setActiveTab(4)"><a href="#!" class="a_top_hypers white-text">DESCUENTOS</a></li>
                            <li v-bind:class="{active: current_tab == 5}" @click="setActiveTab(5)"><a href="#!" class="a_top_hypers white-text">PLANES DE PAGO</a></li>
                            <li v-bind:class="{active: current_tab == 6}" @click="setActiveTab(6)"><a href="#!" class="a_top_hypers white-text">AUTOFINANCIADAS</a></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                     <div class="col m12" id="historial-pagos-container" v-show="current_tab == 1">
                        <table class="table striped" style="font-size:12px">
                            <tbody>
                                <template v-for="historial in historial_pagos" v-bind:key="historial">
                                    <tr>
                                        <th colspan="6" class="center">{{historial.cic_nombre}}</th>
                                    </tr>
                                    <tr>
                                        <th class="center">TIPO</th>
                                        <th class="center">MEDIO</th>
                                        <th class="center">DOC.</th>
                                        <th class="center">DESCRIPCIÓN</th>
                                        <th class="center">VALOR/DESC.</th>
                                        <th class="center">FECHA</th>
                                    </tr>
                                    <template v-for="pago in historial.detalle" v-bind:key="pago">
                                        <tr>
                                            <td>{{pago.tmo_tipo}}</td>
                                            <td>{{pago.mov_tipo}}</td>
                                            <td>{{pago.mov_recibo}}</td>
                                            <td>{{pago.tmo_descripcion}}</td>
                                            <td class="center">{{pago.dmo_valor}}</td>
                                            <td class="center">{{pago.mov_fecha}}</td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <div class="col m12" id="historial-comprobantes" v-show="current_tab == 7">
                        <table class="table tbl-condensed striped" style="font-size:12px">
                            <thead>
                                <tr>
                                    <th>Ciclo</th>
                                    <!-- <th>Descripcion</th> -->
                                    <th>Fecha de Envío</th>
                                    <th>Fecha de Revisión</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="comprobante in comprobantes" :key="comprobante">
                                    <td class="center">{{comprobante.cic_nombre}} - {{comprobante.cil_anio}}</td>
                                    <!-- <td>{{comprobante.acp_descripcion}}</td> -->
                                    <td class="center">{{comprobante.acp_fecha}}</td>
                                    <td class="center">{{comprobante.acp_fecha_aprobacion}}</td>
                                    <td class="center">{{comprobante.eap_descripcion}}</td>
                                    <td class="center"><a href="#!" @click="getDetalleComprobante(comprobante.acp_codigo)"><i class="material-icons">search</i></a></td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="modal" id="modal-comprobantes-perfil">
                            <div class="modal-dialog">
                                <div class="row m0">
                                    <a href="#!" class="btn btn-small red darken-3 modal-close right"><i class="material-icons">close</i></a>
                                    <br>
                                    <br>
                                    <div class="col m12" style="border:dashed 1px black; margin-bottom:10px; font-size:14px">
                                        <div class="col m2">
                                            <label>Ciclo</label>
                                            <p>{{comprobante.cic_nombre}} - {{comprobante.cil_anio}}</p>
                                        </div>
                                        <div class="col m10">
                                            <label>Descripcion</label>
                                            <p>{{comprobante.acp_descripcion}}</p>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col m3">
                                            <label>Fecha</label>
                                            <p>{{comprobante.acp_fecha}}</p>
                                        </div>
                                        <div class="col m3">
                                            <label>Fecha de Revisión</label>
                                            <p>{{comprobante.acp_fecha_aprobacion}}</p>
                                        </div>
                                        <div class="col m3">
                                            <label>Usuario</label>
                                            <p>{{comprobante.acp_usuario}}</p>
                                        </div>
                                        <div class="col m3">
                                            <label>Estado</label>
                                            <p>{{comprobante.eap_descripcion}}</p>
                                        </div>
                                        <div class="clearfix"></div>                                        
                                        <div class="col m12">
                                            <label>Observaciones</label>
                                            <p>{{comprobante.acp_observaciones}}</p>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="col m12 card-panel">
                                        <div class="col m6">
                                            <table class="table tbl-condensed" style="font-size:12px">
                                                <thead>
                                                    <tr>
                                                        <th style="width:70%">Cuota</th>
                                                        <th style="width:20%">Monto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="arancel in aranceles" :key="arancel">
                                                        <td>{{arancel.ara_descripcion}}</td>
                                                        <td class="center">${{arancel.arp_monto}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col m6">
                                            <table class="table tbl-condensed" style="font-size:12px">
                                                <thead>
                                                    <tr>
                                                        <th style="width:70%">Archivo</th>
                                                        <th style="width:20%">Tipo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="adjunto in adjuntos" :key="adjunto">
                                                        <td><a href="#!" @click="getArchivo(adjunto.adp_codigo)">{{$spliceString(adjunto.adp_archivo, 0, 40)}}</a></td>
                                                        <td class="center">{{(adjunto.adp_tipo == 'I' ? 'Imagen':'Pdf')}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col m12" id="record-academico-container" v-show="current_tab == 2">
                        <a href="#!" v-show="estudiante.per_tipo != 'M'" class="right btn" @click="boletaNotas()" title="Boleta de notas">BOLETA DE NOTAS <i class="material-icons right">print</i></a>
                        <a href="#!" v-show="estudiante.per_tipo != 'M'" class="right btn green darken-1" @click="materiasCursadas()" title="Boleta de notas">MATERIAS CURSADAS <i class="material-icons right">list</i></a>
                        <table class="table striped" style="font-size:12px">
                            <tbody>
                                <template v-for="record in record_academico" v-bind:key="record">
                                    <tr>
                                        <th colspan="4" class="center">{{record.cic_nombre}}</th>
                                    </tr>
                                    <tr v-for="materia in record.materias" v-bind:key="materia">
                                        <td>{{materia.mat_codigo}} - {{materia.mat_nombre}}</td>
                                        <td class="center">{{materia.mai_matricula}}</td>
                                        <td class="center">{{materia.estado}}</td>
                                        <td class="center">{{materia.nota}}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>                      
                    </div>                   

                    <div class="col m12" style="display:none" v-show="current_tab == 3">
                        <ExoneracionesComponent ref="ExoneracionesComponent" />                        
                    </div>

                    <div class="col m12" style="display:none" v-show="current_tab == 4">
                        <DescuentosComponent ref="DescuentosComponent" />
                    </div>

                    <div class="col m12" style="display:none" v-show="current_tab == 5">
                        <table class="table striped tbl-condensed tbl-bordered" style="font-size:12px">
                            <caption>PLANES DE PAGO</caption>
                            <tbody>
                                <template v-for="plan in planes_pago" v-bind:key="plan">
                                    <tr>
                                        <th colspan="5" class="center">{{plan.ciclo}} - {{plan.anio}}</th>
                                    </tr>
                                    <tr>
                                        <th class="center" style="width:40%">Arancel</th>
                                        <th class="center" style="width:15%">Con Recargo</th>                                
                                        <th class="center" style="width:20%">Valor</th>
                                        <th class="center" style="width:15%">Mora Incluida</th>
                                        <th class="center" style="width:10%">Cancelado</th>
                                    </tr>
                                    <tr v-for="cuota in plan.plan" v-bind:key="cuota">
                                        <td>{{cuota.arancel}}</td>
                                        <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.conRecargo"><span></span></label></td>                                
                                        <td class="center">${{cuota.valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                        <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.moraIncluida"><span></span></label></td>
                                        <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.cancelado"><span></span></label></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <div class="col m12" style="display:none" v-show="current_tab == 6">
                        <table class="table striped tbl-bordered tbl-condensed" style="font-size:12px">
                        <caption>MATERIAS AUTOFINANCIADAS</caption>
                        <tbody>
                            <template v-for="autofinanciada in autofinanciadas" v-bind:key="autofinanciada">
                                <tr>
                                    <th colspan="4" class="center">{{autofinanciada.ciclo}} - {{autofinanciada.anio}}</th>
                                </tr>
                                <tr>
                                    <th class="center">Arancel/Materia</th>
                                    <th class="center">Con Recargo</th>                               
                                    <th class="center">Valor</th>
                                    <th class="center">Cancelado</th>
                                </tr>
                                <tr v-for="cuota in autofinanciada.cuotas" v-bind:key="cuota">
                                    <td>{{cuota.arancel}}</td>                              
                                    <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.conRecargo"><span></span></label></td>
                                    <td class="center">${{cuota.valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                    <td class="center"><label><input @click.prevent="" class="filled-in" type="checkbox" :checked="cuota.cancelado"><span></span></label></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-materias-cursadas">
        <a href="#!" class="btn btn-small red darken-3 modal-close right"><i class="material-icons">close</i></a>
        <div class="modal-content">
            <div class="row card-panel">
                <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                    <tbody>
                        <template v-for="materia in materias_cursadas" :key="materia">
                            <tr class="black white-text">
                                <th colspan="2">{{materia.fac_nombre.toUpperCase()}}</th>
                            </tr>
                            <template v-for="carrera in materia.carreras" :key="carrera">
                                <tr class="grey darken-4 white-text">
                                    <th colspan="2">{{carrera.car_nombre.toUpperCase()}}</th>
                                </tr>

                                <template v-for="ciclo in carrera.ciclos" :key="ciclo">
                                    <tr class="blue-ugb white-text">
                                        <th colspan="2">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}}</th>
                                    </tr>
                                    <template v-for="materia in ciclo.materias" :key="materia">
                                        <tr>
                                            <td style="text-align:right">{{materia.mat_codigo}}</td>
                                            <td>{{materia.mat_nombre}}</td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import ExoneracionesComponent from '../components/ExoneracionesComponent.vue'
import DescuentosComponent from '../components/DescuentosComponent.vue'
import M from 'materialize-css'
    export default
    {
        components: {
            ExoneracionesComponent,
            DescuentosComponent
        },
        data(){
            return {
                codper: 0,
                estudiante: null,
                current_tab: 1,
                historial_pagos: [],
                record_academico: [],
                planes_pago: [],
                planes_pago_loaded: false,
                autofinanciadas: [],
                autofinanciadas_loaded: false,
                comprobantes: [],
                comprobante: {},
                adjuntos: [],
                aranceles: [],
                modal_instances: [],
                modal_comprobantes: {},
                modal_cursadas: {},
                materias_cursadas: []
            }
        },
        async mounted()
        {
            this.codper = parseInt(this.$route.params.id);
            this.$root.startLoading();
            await this.getEstudiante();
            await this.getHistorialPagos();
            this.$root.stopLoading();
            this.modal_instances = M.Modal.init(document.querySelectorAll('.modal'), {
                dismissible: true
            });
        },
        methods: {
            async getEstudiante()
            {
                await this.axios.get('/Estudiantes/' + this.codper)
                .then(response => {
                    this.estudiante = response.data;
                });
            },
            async getHistorialPagos()
            {
                if(this.historial_pagos.length == 0)
                {
                    await this.axios.get('/Estudiantes/' + this.codper + '/HistorialPagos')
                    .then(response => {
                        this.historial_pagos = response.data;
                    });
                }
            },
            async getRecordAcademico()
            {
                if(this.record_academico.length == 0)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Estudiantes/' + this.estudiante.per_carnet + '/RecordAcademico')
                    .then(response => {
                        this.record_academico = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async setActiveTab(tab)
            {
                this.current_tab = tab;
                switch(tab)
                {
                    case 1:
                        await this.getHistorialPagos;
                        break;
                    case 2:
                        await this.getRecordAcademico();
                        break;
                    case 3:
                        await this.$refs.ExoneracionesComponent.init(this.estudiante);
                        break;
                    case 4:
                        await this.$refs.DescuentosComponent.init(this.estudiante);
                        break;
                    case 5:
                        await this.getPlanesPago();
                        break;
                    case 6:
                        await this.getAutofinanciadas();
                        break;
                    case 7:
                        await this.getComprobantes();
                        break;
                }
            },
            async boletaNotas()
            {
                this.$root.startLoading();
                await this.axios.get('/Reportes/Boleta/' + this.estudiante.per_carnet +'/' + this.estudiante.car_virtual + '/' + this.estudiante.car_codigo, {
                    responseType: 'blob'
                })
                .then(async response => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const objectURL = URL.createObjectURL(blob);
                    window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');                  
                })
                .catch(() => {
                    M.toast({html: 'Ha ocurrido un error, intente nuevamente.'});
                });
                this.$root.stopLoading();
            },
            async getPlanesPago()
            {
                if(!this.planes_pago_loaded)
                {
                    this.$root.startLoading();
                    await this.axios.get('/PlanPagos/Estudiante/' + this.estudiante.per_codigo + '?only_pending=false')
                    .then(response => {
                        this.planes_pago = response.data
                    });
                    this.planes_pago_loaded = true;
                    this.$root.stopLoading();
                }
            },
            async getAutofinanciadas()
            {
                if(!this.autofinanciadas_loaded)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Autofinanciadas/Estudiante/' + this.estudiante.per_codigo + '?only_pending=false')
                    .then(response => {
                        this.autofinanciadas = response.data
                    });
                    this.autofinanciadas_loaded = true;
                    this.$root.stopLoading();
                }
            },
            async getComprobantes()
            {
                if(this.comprobantes.length == 0)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/Comprobantes')
                    .then(response => {
                        this.comprobantes = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async getDetalleComprobante(codacp)
            {
                this.$root.startLoading();

                this.comprobante = this.comprobantes.find(x=>x.acp_codigo == codacp);

                await this.axios.get('/Comprobantes/Adjuntos/' + codacp)
                .then(response => {
                    this.adjuntos = response.data;
                });

                await this.axios.get('/Comprobantes/Aranceles/' + codacp)
                .then(response => {
                    this.aranceles = response.data;
                });
                
                this.modal_comprobantes = M.Modal.getInstance(document.querySelector('#modal-comprobantes-perfil'));
                this.modal_comprobantes.open();
                this.$root.stopLoading();                
            },
            async getArchivo(codadp)
            {
                this.$root.startLoading();
                await this.axios.get('/Comprobantes/Archivo/' + codadp, {}, {
                    responseType: 'blob'
                })
                .then(response => {
                    var binary = atob(response.data.base64.replace(/\s/g, ''));
                    var len = binary.length;
                    var buffer = new ArrayBuffer(len);
                    var view = new Uint8Array(buffer);
                    for (var i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i);
                    }
                    const objectURL = URL.createObjectURL(new Blob( [view], { type: response.data.contentType }));
                    window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes')
                })
                .catch(() => {
                    M.toast({html: 'Archivo no disponible.'});
                });
                this.$root.stopLoading();
            },
            async materiasCursadas()
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/MateriasCursadas')
                .then(response => {
                    this.materias_cursadas = response.data;
                    this.modal_cursadas = M.Modal.getInstance(document.querySelector('#modal-materias-cursadas'));
                    this.modal_cursadas.open();
                });
                this.$root.stopLoading();
            }
        }
    }
</script>

<style scoped>
    #div_top_hypers {
        background-color:#eeeeee;
        display:inline;      
    }

    #ul_top_hypers li{
        display: inline;
        padding: 10px;
        background-color: #aaa;
        cursor: pointer;
        margin-right: 5px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        font-size: 10px;
    }

     #ul_top_hypers li.active{
        background-color:#263680;
    }
</style>