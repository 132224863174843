<template>
  <Header/>
  <div class="container white" style="width: 95%; margin-top:30px; padding:20px; margin-bottom:30px">
    <router-view></router-view>
  </div>
  <div class="loading" v-show="showLoader"></div>
  <div class="footer-data" v-show="$store.state.isAuthenticated">
    <div class="row m0 p0">
      <div class="col m6">
        {{$store.state.userData.emp_apellidos_nombres}}<!-- | {{$store.state.userData.reg_nombre}} -->
      </div>
      <div class="col m6 m0 p0">
        <select class="browser-default" id="select-set-regional" @change="setRegional()">
          <option value="1" :selected="$store.state.userData.reg_codigo == 1">San Miguel</option>
          <option value="2" :selected="$store.state.userData.reg_codigo == 2">Usulután</option>
          <option value="5" :selected="$store.state.userData.reg_codigo == 5">Salitre</option>
          <option value="6" :selected="$store.state.userData.reg_codigo == 6">UGBPlus/Ed. Continua</option>
        </select>
      </div>
   </div>
    <!-- <a href="#!" @click="setRegional($store.state.userData.reg_codigo)" title="Cambiar regional"><i style="font-size:18px" class="material-icons right">sync</i></a> -->
  </div>
</template>

<script>
import Header from './components/Header.vue'
export default {
  name: 'App',
  components:{
    Header
  },
  data(){
    return{
      showLoader: false,
      loadingPage: false
    }
  },
  methods: {
    startLoading()
    {
      this.loadingPage = true;
      this.showLoader = true;
    },
    stopLoading()
    {
      this.loadingPage = false;
      this.showLoader = false;
    },
    async setRegional()
    {
        //codreg = codreg == 1 ? 2:1
        var codreg = document.querySelector('#select-set-regional').value;
        var select = document.querySelector('#select-set-regional');
        var regional = select.options[select.selectedIndex].text;
        this.$root.startLoading();
        await this.axios.patch('/Login/Regional/' + codreg)
        .then(response => {
            this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;                    
            localStorage.setItem('token', response.data.token);
            var userData = this.$store.state.userData;
            userData.reg_codigo = codreg;
            userData.reg_nombre = regional;
            this.$store.commit('setUserData', userData);
            this.$router.go();
        });
    }
  }
}
</script>

<style scoped>
  .footer-data
  {
    position:fixed;
    bottom:0px;
    left: 0px;
    height: 30px;
    background-color: #011B40;
    color:white;
    padding:5px 20px 5px 20px;
    border-radius: 0px 5px 0px 0px;
    font-size:12px;
    border: solid 1px white
  }
</style>