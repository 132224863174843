<template>
    <div class="col m12">
        <div class="row">
            <div class="col m12">
            <form @submit.prevent="index_selected >= 0 ? actualizar():guardar()">
                <div class="col m2">
                    <label>Ciclo</label>
                    <select class="browser-default" v-model="codcil" :disabled="index_selected >= 0">
                        <option value="0">Seleccione</option>
                        <option v-for="ciclo in ciclos" v-bind:key="ciclo" v-bind:value="ciclo.cil_codigo">{{ciclo.cic_nombre}} - {{ciclo.cil_anio}}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "dfc_codcil")}}</span>
                </div>
                <div class="col m3">
                    <label>Arancel</label>
                    <select class="browser-default" v-model="codtmo" :disabled="index_selected >= 0">
                        <option value="0">Seleccione</option>
                        <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">{{arancel.tmo_descripcion}}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "dfc_codtmo")}}</span>
                </div>
                <div class="col m2">
                    <label>Monto</label>
                    <input type="text" v-model="monto">
                    <span class="helper-text">{{$validation(validations, "dfc_monto")}}</span>
                </div>
                <div class="col m3">
                    <label>Comentarios</label>
                    <input type="text" v-model="comentarios">
                    <span class="helper-text">{{$validation(validations, "dfc_comentario")}}</span>
                </div>
                <div class="col m1">
                    <button type="submit" style="width:100%" class="btn blue-ugb"><i class="material-icons">{{index_selected >= 0 ? 'update':'save'}}</i></button>
                </div>
                <div class="col m1" v-show="index_selected >= 0">
                    <button type="button" style="width:100%" class="btn red darken-4" @click="reset()"><i class="material-icons">cancel</i></button>
                </div>
            </form>
            </div>
        </div>
        <div class="row" v-show="descuentos.length > 0">
            <div class="col m12">
            <table class="table striped" style="font-size:12px">
                <tbody>
                    <tr>
                        <th class="center">Ciclo</th>
                        <th class="center">Arancel</th>
                        <th class="center">Monto</th>
                        <th class="center">Comentarios</th>
                        <th class="center">Fecha</th>
                        <th class="center">Usuario</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-for="(descuento, index) in descuentos" v-bind:key="descuento" v-bind:class="{'grey lighten-2': (index == index_selected)}">
                        <td class="center">{{descuento.cic_nombre}} - {{descuento.dfc_anio}}</td>
                        <td>{{descuento.tmo_descripcion}}</td>
                        <td class="center">${{descuento.dfc_monto.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                        <td>{{descuento.dfc_comentario}}</td>
                        <td class="center">{{$dateFormat(new Date(descuento.dfc_fecha), 'dd/MM/yyyy')}}</td>
                        <td class="center">{{descuento.dfc_usuario}}</td>
                        <td class="center"><a href="#!" @click="editar(index)"><i class="material-icons">edit</i></a></td>
                        <td class="center"><a href="#!" @click="eliminar(index)"><i class="material-icons">delete</i></a></td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){            
            return {
                descuentos: [],
                aranceles: [],
                codtmo: 0,
                codcil: 0,
                ciclos: [],
                estudiante: {},
                monto: 0,
                index_selected: -1,
                comentarios: '',
                validations: []
            }
        },
        methods: {
            async getCiclos()
            {
                if(this.ciclos.length == 0)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Ciclos')
                    .then(response => {
                        this.ciclos = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async getAranceles()
            {
                if(this.aranceles.length == 0)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Aranceles/Facturacion')
                    .then(response => {
                        this.aranceles = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async getDescuentos()
            {
                if(this.descuentos.length == 0)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Estudiantes/' + this.estudiante.per_codigo + '/DescuentoFijo')
                    .then(response => {
                        this.descuentos = response.data;
                    });
                    this.$root.stopLoading();
                }
            },
            async init(estudiante)
            {
                this.estudiante = estudiante;
                await this.getDescuentos()
                await this.getAranceles();
                await this.getCiclos();
            },
            async guardar()
            {
                var data = {
                    dfc_anio: this.anio,
                    dfc_codcic: this.codcic,
                    dfc_codper: this.estudiante.per_codigo,
                    dfc_codtmo: this.codtmo,
                    dfc_comentario: this.comentarios,
                    dfc_codcil: this.codcil,
                    dfc_monto: this.monto
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/DescuentoFijo', data)
                .then(async response => {
                    await this.getDescuento(response.data.dfc_codper, response.data.dfc_anio, response.data.dfc_codcic, response.data.dfc_codtmo);
                    this.reset();
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                });
                this.$root.stopLoading();
            },
            async actualizar()
            {
                var data = {
                    dfc_anio: this.anio,
                    dfc_codcic: this.codcic,
                    dfc_codper: this.estudiante.per_codigo,
                    dfc_codtmo: this.codtmo,
                    dfc_comentario: this.comentarios,
                    dfc_codcil: this.codcil,
                    dfc_monto: this.monto
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.put('/DescuentoFijo/' + this.estudiante.per_codigo + '/' + this.descuentos[this.index_selected].dfc_anio + '/' + this.descuentos[this.index_selected].dfc_codcic + '/' + this.descuentos[this.index_selected].dfc_codtmo, data)
                .then(async () => {
                    await this.getDescuento(this.estudiante.per_codigo, this.anio, this.codcic, this.codtmo);
                    this.reset();
                });
                this.$root.stopLoading();
            },
            async getDescuento(codper, anio, codcic, codtmo)
            {
                this.$root.startLoading();
                await this.axios.get('/DescuentoFijo/' + codper + '/' + anio + '/' + codcic + '/' + codtmo)
                .then(response => {
                    var index = this.descuentos.findIndex(x=>x.dfc_codper == codper && x.dfc_anio == anio && x.dfc_codcic == codcic && x.dfc_codtmo == codtmo);
                    if(index != -1)
                    {
                        this.descuentos[index] = response.data;
                    }
                    else
                    {
                        this.descuentos.unshift(response.data);
                    }
                });
            },
            async eliminar(index)
            {
                if(!confirm('¿Desea eliminar este descuento?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/DescuentoFijo/' + this.descuentos[index].dfc_codper + '/' + this.descuentos[index].dfc_anio + '/' + this.descuentos[index].dfc_codcic + '/' + this.descuentos[index].dfc_codtmo)
                .then(() => {
                    this.descuentos.splice(index, 1);
                });
                this.$root.stopLoading();
            },
            reset()
            {
                this.codcil = 0;
                this.codtmo = 0;
                this.monto = 0;
                this.comentarios = '';
                this.index_selected = -1;
                this.validations = [];
            },
            editar(index)
            {
                this.index_selected = index;
                var ciclo = this.ciclos.find(x=>x.cic_codigo_int == this.descuentos[index].dfc_codcic && x.cil_anio == this.descuentos[index].dfc_anio);
                this.codtmo = this.descuentos[index].dfc_codtmo;
                this.codcil = ciclo.cil_codigo;
                this.monto = this.descuentos[index].dfc_monto;
                this.comentarios = this.descuentos[index].dfc_comentario;
            }
        },
        computed: {
            anio()
            {
                var result = this.ciclos.find(x=>x.cil_codigo == this.codcil);
                if(result == undefined) return 0;
                return result.cil_anio;
            },
            codcic()
            {
                var result = this.ciclos.find(x=>x.cil_codigo == this.codcil);
                if(result == undefined) return 0;
                return result.cic_codigo_int;
            }
        }
    }
</script>