<template>
    <div class="row card-panel red darken-4 white-text">
        <blockquote>Debido al proceso de implementación de factura electrónica, se ha creado este formulario de actualización de estudiantes para cumplir con lo requerido en dicho proceso.
            <i class="material-icons right">warning</i>
        </blockquote>
    </div>
    <fieldset>
        <form method="post" @submit.prevent="actualizar()">
            <legend>Actualización de Datos</legend>
            <div class="row">
                <div class="col m2">
                    <label>Carnet</label>
                    <p>{{ estudiante.per_carnet }}</p>
                </div>
                <div class="col m3">
                    <label>Nombre</label>
                    <p>{{ estudiante.per_nombres_apellidos }}</p>
                </div>
                <div class="col m4">
                    <label>Carrera</label>
                    <p>{{ estudiante.car_nombre }}</p>
                </div>
                <div class="col m2">
                    <label>Plan</label>
                    <p>{{ estudiante.pla_nombre }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col m2">
                    <label>Teléfono</label>
                    <input type="text" v-model="estudiante.per_telefono" v-if="!isSalvadorean()">
                    <InputMask type="text" maxlength="9" minlength="9" v-if="isSalvadorean()" v-model="estudiante.per_telefono" mask="9999-9999"/>
                    <span class="helper-text">{{$validation(validations, "per_telefono")}}</span>
                </div>
                <div class="col m2">
                    <label>Correo</label>
                    <input type="text" v-model="estudiante.per_email">
                    <span class="helper-text">{{$validation(validations, "per_email")}}</span>
                </div>
                <div class="col m2" v-show="estudiante.per_mayor_edad">
                    <label>DUI</label>
                    <InputMask type="text" maxlength="10" minlength="10" v-model="estudiante.per_dui" mask="99999999-9"/>
                    <span class="helper-text">{{$validation(validations, "per_dui")}}</span>
                </div>
                <div class="col m2" v-show="!estudiante.per_mayor_edad">
                    <label>Carnet de Minoridad</label>
                    <input type="text" v-model="estudiante.per_carnet_minoridad">
                    <span class="helper-text">{{$validation(validations, "per_carnet_minoridad")}}</span>
                </div>
                <div class="col m2">
                    <label>NIT</label>
                    <InputMask type="text" maxlength="17" minlength="17" v-model="estudiante.per_nit" mask="9999-999999-999-9"/>
                    <span class="helper-text">{{$validation(validations, "per_nit")}}</span>
                </div>
                <div class="col m1">
                    <label>Domiciliado</label><br>
                    <label><input type="checkbox" v-model="estudiante.per_domiciliado" class="filled-in"><span></span></label>
                </div>
                <dov class="col m2">
                    <label>Actividad Económica</label>
                    <select class="browser-default" v-model="estudiante.per_mh_actividad_economica">
                        <option value="">Seleccione</option>
                        <option v-for="actividad in actividades_economicas" :key="actividad" :value="actividad.codigo">{{ actividad.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "per_mh_actividad_economica")}}</span>
                </dov>
            </div>

            <div class="row">
                <div class="col m2">
                    <label>Pais</label>
                    <select class="browser-default" v-model="estudiante.per_mh_pais" @change="setPais()">
                        <option value="">Seleccione</option>
                        <option v-for="pais in paises" :value="pais.codigo" :key="pais.codigo">{{ pais.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "per_mh_pais")}}</span>
                    <div v-if="!isSalvadorean()">
                        <label>Pasaporte u otro documento</label>
                        <input type="text" v-model="estudiante.per_pasaporte">                    
                    </div>
                </div>
                <div class="col m2">
                    <label>Departamento</label>
                    <select class="browser-default" v-model="estudiante.per_mh_departamento" @change="getMunicipios();" :disabled="!isSalvadorean()">
                        <option value="00">Seleccione</option>
                        <option v-for="departamento in departamentos" :value="departamento.codigo" :key="departamento.codigo">{{ departamento.nombre }}</option>
                    </select>
                    <span class="helper-text">{{$validation(validations, "per_mh_departamento")}}</span>
                </div>
                <div class="col m2">
                    <label>Distrito</label>
                    <select class="browser-default" v-model="estudiante.per_mh_municipio" :disabled="!isSalvadorean()">
                        <option value="00">Seleccione</option>
                        <option v-for="municipio in municipios" :value="municipio.codigo" :key="municipio.codigo">{{ municipio.nombre }}</option>
                    </select>               
                    <span class="helper-text">{{$validation(validations, "per_mh_municipio")}}</span>
                </div>
                <div class="col m6">
                    <label>Dirección</label>
                    <input type="text" v-model="estudiante.per_direccion">
                    <span class="helper-text">{{$validation(validations, "per_direccion")}}</span>
                </div>
            </div>
            
            <div class="row" v-show="error_message">
                <div class="card-panel red red lighten-2 white-text">
                    {{error_message_text}}
                </div>
            </div>

            <div class="row">
                <div class="col m4">
                    <button type="submit" class="btn blue-ugb">Actualizar</button> &nbsp;
                    <button type="button" class="btn red darken-3">Cancelar</button>
                </div>
            </div>
        </form>
    </fieldset>
</template>

<script>
    import M from 'materialize-css'
    import InputMask from 'primevue/inputmask'
    export default {
        components: {
            InputMask
        },
        data(){
            return {
                estudiante: {},
                codper: 0,
                validations: [],
                paises: [],
                actividades_economicas: [],
                departamentos: [],
                municipios: [],
                menor_edad: false,
                error_message: false,
                error_message_text: ''
            }
        },
        async mounted()
        {
            this.getPaises();
            this.getDepartamentos();
            this.getActividadesEconomicas();
            this.reset();
            var id = parseInt(this.$route.params.id);
            if(isNaN(id))
            {
                this.codper = 0;
            }
            else
            {
                this.codper = parseInt(this.$route.params.id);
            }
            this.getEstudiante(id);
        },
        methods: {
            async getEstudiante(id)
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes/' + id)
                .then(async response => {
                    this.estudiante.per_mh_departamento = response.data.per_mh_departamento;
                    await this.getMunicipios();
                    this.estudiante = response.data;
                });
                this.$root.stopLoading();
            },
            async actualizar()
            {
                this.validations = [];
                this.error_message = false;
                this.error_message_text = '';
                this.$root.startLoading();
                await this.axios.put('/Estudiantes/' + this.estudiante.per_codigo + '/FacturacionElectronica', this.estudiante)
                .then(() => {
                    M.toast({html: 'Los datos han sido actualizados.'});
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        this.validations = error.response.data.modelErrors;
                        M.toast({html: 'Complete los campos.'});
                    }
                    if(error.response.data.type == 'C')
                    {
                        M.toast({html: error.response.data.message});
                        this.error_message = true;
                        this.error_message_text = error.response.data.message;
                    }
                });
                this.$root.stopLoading();
            },
            async getPaises()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Paises')
                .then(response => {
                    this.paises = response.data;
                });
                this.$root.stopLoading();
            },
            async getDepartamentos()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Departamentos')
                .then(response => {
                    this.departamentos = response.data;
                });
                this.$root.stopLoading();
            },
            async getMunicipios()
            {
                if(this.estudiante.per_mh_departamento == '')
                {
                    this.municipios = [];
                    this.estudiante.per_mh_municipio = '';
                    return;
                }
                this.$root.startLoading();
                await this.axios.get('/FacturacionElectronicaAPI/Departamentos/' + this.estudiante.per_mh_departamento + '/Municipios')
                .then(response => {
                    this.municipios = response.data;
                    this.estudiante.per_mh_municipio = '00';
                });
                this.$root.stopLoading();
            },
            async getActividadesEconomicas()
            {
                this.$root.startLoading();
                this.axios.get('/FacturacionElectronicaAPI/ActividadEconomica')
                .then(response => {
                    this.actividades_economicas = response.data;
                });
                this.$root.stopLoading();
            },
            setPais()
            {
                if(!this.isSalvadorean())
                {
                    this.estudiante.per_mh_departamento = '00';
                    this.estudiante.per_mh_municipio = '00';
                }
            },
            reset()
            {
                this.estudiante.per_mh_pais = "";
                this.estudiante.per_mh_departamento = "";
                this.estudiante.per_mh_municipio = "";
                this.error_message_text = '';
                this.error_message = false;
            },
            isSalvadorean()
            {
                return this.estudiante.per_mh_pais == '9300' || this.estudiante.per_mh_pais == 'SV' || this.estudiante.per_mh_pais == '';
            }
        }
    }
</script>