<template>
    <div class="row">
        <h5 class="card-panel blue-ugb white-text">Usuarios y Roles</h5>
        <div class="col m12">
            <table class="table tbl-condensed bordered striped" style="font-size:12px">
                <thead>
                    <tr>
                        <th>ID SAP</th>
                        <th>Empleado</th>
                        <th>Usuario</th>
                        <th>Roles</th>
                        <th>Accesos</th>
                        <th>Contraseña</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="usuario in usuarios" v-bind:key="usuario">
                        <td class="center">{{usuario.internalKey}}</td>
                        <td>{{usuario.emp_apellidos_nombres}}</td>
                        <td>{{usuario.usa_usuario}}</td>
                        <td class="center"><a @click="getRoles(usuario.usa_codigo)" href="#!"><i class="material-icons">person</i></a></td>
                        <td class="center"><a @click="modalAccesos(usuario.usa_codigo)" href="#!"><i class="material-icons">playlist_add_check</i></a></td>
                        <td class="center"><a @click="modalClave(usuario.usa_codigo)" href="#!"><i class="material-icons">security</i></a></td>
                        <td class="center">
                            <select v-model="usuario.usa_estado" @change="cambiarEstado($event, usuario.usa_codigo)" class="browser-default">
                                <option value="A">Activo</option>
                                <option value="I">Inactivo</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal" id="modal-roles-usuario">
            <div class="modal-content">
                <div class="row card-panel">
                    <div class="col m12">
                        <div class="col m1">
                            <label>ID</label>
                            <p>{{usuario.internalKey}}</p>
                        </div>
                        <div class="col m3">
                            <label>Usuario</label>
                            <p>{{usuario.usa_usuario}}</p>
                        </div>
                        <div class="col m6">
                            <label>Nombre</label>
                            <p>{{usuario.emp_apellidos_nombres}}</p>
                        </div>
                        <div class="col m2">
                            <label>Estado</label>
                            <p>{{(usuario.usa_estado == 'A' ? 'Activo':'Inactivo')}}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col m8">
                        <label>Rol</label>
                        <select class="browser-default" v-model="rsa_codigo">
                            <option value="0">Seleccione</option>
                            <option v-for="rol in roles" v-bind:key="rol" v-bind:value="rol.rsa_codigo">{{rol.rsa_descripcion}}</option>
                        </select>
                    </div>
                    <div class="col m4">
                        <button type="button" @click="agregarRol" class="btn blue-ugb" style="margin-top:15px"><i class="material-icons">add_circle</i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col m12">
                        <table class="table tbl-condensed">
                            <thead>
                                <tr>
                                    <th>Rol</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="rol in rolesUsuario" v-bind:key="rol">
                                    <td>{{rol.rsa_descripcion}}</td>
                                    <td class="center"><a href="#!" @click="eliminarRol(rol.rsa_codigo)"><i class="material-icons">delete</i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal-usuario-clave">
            <div class="modal-content">
                <div class="row card-panel">
                    <div class="col m12">
                        <div class="col m1">
                            <label>ID</label>
                            <p>{{usuario.internalKey}}</p>
                        </div>
                        <div class="col m3">
                            <label>Usuario</label>
                            <p>{{usuario.usa_usuario}}</p>
                        </div>
                        <div class="col m6">
                            <label>Nombre</label>
                            <p>{{usuario.emp_apellidos_nombres}}</p>
                        </div>
                        <div class="col m2">
                            <label>Estado</label>
                            <p>{{(usuario.usa_estado == 'A' ? 'Activo':'Inactivo')}}</p>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="cambiarClave()">
                    <div class="row">
                        <div class="col m6">
                            <label>Contraseña nueva</label>
                            <input type="password" autocomplete="off" required v-model="clave">
                        </div>
                        <div class="col m6">
                            <label>Confirme contraseña</label>
                            <input type="password" autocomplete="off" required v-model="confirma_clave">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                            <button type="submit" class="btn blue-ugb">CAMBIAR CONTRASEÑA</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="modal" id="modal-accesos">
            <div class="modal-content">
                <div class="row m0 p0">
                    <div class="col m12 card-panel">
                        <div class="row">
                            <div class="col m12">
                                <div class="col m1">
                                    <label>ID</label>
                                    <p>{{usuario.internalKey}}</p>
                                </div>
                                <div class="col m3">
                                    <label>Usuario</label>
                                    <p>{{usuario.usa_usuario}}</p>
                                </div>
                                <div class="col m6">
                                    <label>Nombre</label>
                                    <p>{{usuario.emp_apellidos_nombres}}</p>
                                </div>
                                <div class="col m2">
                                    <label>Estado</label>
                                    <p>{{(usuario.usa_estado == 'A' ? 'Activo':'Inactivo')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col m12">
                                <template v-for="tipo in tipo_documento" v-bind:key="tipo">
                                    <label><input type="checkbox" :checked="tipo.atd_estado" class="filled-in" @click="setAccesoDocumento($event, tipo.tdo_sigla)"><span>{{tipo.tdo_nombre}}</span></label>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col m12 card-panel">
                        <div class="col m6">
                            <div class="row">
                                <form @submit.prevent="guardarAcceso()">
                                    <div class="col m8">
                                        <label>Opción</label>
                                        <select v-model="codmec" class="browser-default">
                                            <option value="0">Seleccione</option>
                                            <option v-for="menu in menus" v-bind:key="menu.mec_codigo" v-bind:value="menu.mec_codigo">{{menu.mec_descripcion}}</option>
                                        </select>
                                        <span class="helper-text">{{$validation(validations, 'muc_codmec')}}</span>
                                    </div>
                                    <div class="col m4">
                                        <button type="submit" class="btn blue-ugb" style="margin-top:15px">Asignar</button>
                                    </div>
                                </form>
                            </div>
                            <div class="row" v-show="accesos_usuario.length > 0">
                                <table class="table tbl-condensed stripped" style="font-size:12px">
                                    <thead>
                                        <tr>
                                            <th>Opción</th>
                                            <th>Fecha</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="acceso in accesos_usuario" v-bind:key="acceso">
                                            <td>{{acceso.mec_descripcion}}</td>
                                            <td class="center">{{$dateFormat(new Date(acceso.muc_fecha), 'dd/MM/yyyy')}}</td>
                                            <td class="center"><label><input type="checkbox" class="filled-in" checked="acceso.mec_estado"><span></span></label></td>
                                            <td class="center"><a href="#!" @click="eliminarAcceso(acceso.muc_codusa, acceso.muc_codmec)"><i class="material-icons">delete</i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col m6">
                            <div class="row">
                                <form @submit.prevent="setReporte()">
                                    <div class="col m8">
                                        <label>Reporte</label>
                                        <select class="browser-default" v-model="rep_codigo">
                                            <option value="0">Seleccione</option>
                                            <option v-for="reporte in reportes" :key="reporte" :value="reporte.rep_codigo">{{reporte.rep_nombre}}</option>
                                        </select>
                                    </div>
                                    <div class="col m4">
                                        <button class="btn blue-ugb" type="submit" :disabled="rep_codigo == 0" style="margin-top:15px">Asignar</button>
                                    </div>
                                </form>
                            </div>
                            <div class="row">
                                <table class="table tbl-condensed tbl-bordered" style="font-size:12px">
                                    <thead>
                                        <tr>
                                            <th>Reporte</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="reporte in reportes_usuario" :key="reporte">
                                            <td>{{reporte.rep_nombre}}</td>
                                            <th class="center"><label><input type="checkbox" class="filled-in" :checked="reporte.rep_estado"><span></span></label></th>
                                            <th class="center"><a href="#!" @click="eliminarReporteAcceso(reporte.arp_codrep, reporte.arp_codusa)"><i class="material-icons">delete</i></a></th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
export default {
    data(){
        return {
            usuarios: [],
            usuario: [],
            roles: [],
            rsa_codigo: 0,
            usa_codigo: [],
            estados: [],
            rolesUsuario: [],
            modales: [],
            modal_roles: {},
            modal_clave: {},
            clave: '',
            confirma_clave: '',
            reportes: [],
            reportes_usuario: [],
            rep_codigo: 0,

            //Accesos de usuario
            accesos_usuario: [],
            menus: [],
            codmec: 0,
            validations: [],

            tipo_documento: []
        }
    },
    async mounted()
    {
        this.$root.startLoading();
        await this.axios.get('/Usuarios')
        .then(response => {
            this.usuarios = response.data;
        });
        this.modales = M.Modal.init(document.querySelectorAll('.modal'));
        this.$root.stopLoading();
    },
    methods: {
        async cambiarEstado(event, codusa)
        {            
            await this.axios.patch('/Usuarios/' + codusa + '/Estado?activo=' + (event.target.value == 'A'))
            .then(() => {

            });
        },
        async getRoles(codusa)
        {
            this.$root.startLoading();
            this.usuario = this.usuarios.find(x=>x.usa_codigo == codusa);
            this.usa_codigo = codusa;
            if(this.roles.length == 0)
            {
                await this.axios.get('/Roles')
                .then(response => {
                    this.roles = response.data;
                });
            }

            await this.axios.get('/Usuarios/' + codusa + '/Roles')
            .then(response => {
                this.rolesUsuario = response.data;
            });

            this.modal_roles = M.Modal.getInstance(document.querySelector('#modal-roles-usuario'));
            this.modal_roles.open();
            this.$root.stopLoading();
        },
        async agregarRol()
        {
            if(this.rsa_codigo == 0) return false;
            var data = {
                usa_codigo: this.usa_codigo,
                rsa_codigo: this.rsa_codigo
            };
            this.$root.startLoading();
            await this.axios.post('/Usuarios/' + this.usa_codigo + '/Roles', data)
            .then(response => {
                this.rolesUsuario.push(response.data);
            })
            .catch(error => {
                M.toast({html: error.response.data.message});
            });
            this.$root.stopLoading();
        },
        async eliminarRol(codrsa)
        {
            if(!confirm('¿Desea eliminar este rol?')) return false;
            this.$root.startLoading();
            await this.axios.delete('/Usuarios/' + this.usa_codigo + '/Roles/' + codrsa)
            .then(() => {
                var index = this.rolesUsuario.findIndex(x=>x.rsa_codigo == codrsa);
                if(index != -1)
                {
                    this.rolesUsuario.splice(index, 1);
                }
            })
            .catch(error => {
                if(error.response.status == 404)
                {
                    M.toast({html: 'No encontrado.'});
                }
            });
            this.$root.stopLoading();
        },
        modalClave(codusa)
        {
            this.usuario = this.usuarios.find(x=>x.usa_codigo == codusa);
            this.usa_codigo = codusa;
            this.modal_clave = M.Modal.getInstance(document.querySelector('#modal-usuario-clave'));
            this.modal_clave.open();
        },
        async cambiarClave()
        {
            if(this.clave.length < 6)
            {
                M.toast({html: 'La contraseña debe tener al menos 6 caracteres..'});
                return false;
            }
            if(this.clave != this.confirma_clave)
            {
                M.toast({html: 'Las contraseñas no coinciden.'});
                return false;
            }
            var data = {
                InternalKey: this.usuario.internalKey,
                UserPassword: this.clave
            };

            this.$root.startLoading();
            await this.axios.patch('/Usuarios/' + this.usa_codigo + '/Clave', data)
            .then(() => {
                M.toast({html: 'La contraseña ha sido actualizada.'});
            })
            .catch(error => {
                M.toast({html: error.response.data.message});
            });
            this.$root.stopLoading();
        },
        async modalAccesos(codusa)
        {
            await this.getAccesos(codusa);
            await this.getMenus(codusa);
            await this.getAccesoTipoDocumento(codusa);
            await this.getReportes(codusa);
            await this.getReportesUsuario(codusa);
            this.usuario = this.usuarios.find(x=>x.usa_codigo == codusa);
            this.modal_clave = M.Modal.getInstance(document.querySelector('#modal-accesos'));
            this.modal_clave.open();
        },
        async getAccesos(codusa)
        {
            await this.axios.get('/AccesoMenu/Usuario/' + codusa)
            .then(response => {
                this.accesos_usuario = response.data;
            });
        },
        async getMenus(codusa)
        {
            await this.axios.get('/Menu?all=false&codusa=' + codusa)
            .then(response => {
                this.menus = response.data;
            });
        },
        async guardarAcceso()
        {
            var data = {
                muc_codmec: this.codmec,
                muc_codusa: this.usuario.usa_codigo
            };
            await this.axios.post('/AccesoMenu', data)
            .then(async () => {
               await this.getAcceso(this.usuario.usa_codigo, this.codmec);
               this.menus.splice(this.menus.findIndex(x=>x.mec_codigo == this.codmec), 1);
               this.codmec = 0;
            })
            .catch(error => {
                if(error.response.data.type == 'M')
                {
                    this.validations = error.response.data.modelErrors;
                }
            });
        },
        async getAcceso(codusa, codmec)
        {
             await this.axios.get('/AccesoMenu/' + codusa + '/' + codmec)
            .then(response => {
                var index = this.accesos_usuario.findIndex(x=>x.muc_codmec == codmec && x.muc_codusa == codusa);
                if(index == -1)
                {
                    this.accesos_usuario.unshift(response.data);
                }
                else
                {
                    this.accesos_usuario[index] = response.data;
                }
            });
        },
        async eliminarAcceso(codusa, codmec)
        {
            if(!confirm('¿Desea eliminar este acceso?')) return false;
            await this.axios.delete('/AccesoMenu/' + codusa + '/' + codmec)
            .then(() => {
                var index = this.accesos_usuario.findIndex(x=>x.muc_codmec == codmec && x.muc_codusa == codusa);
                if(index != -1)
                {
                    this.accesos_usuario.splice(index, 1);
                }
            });
        },
        async getAccesoTipoDocumento(codusa)
        {
            this.tipo_documento = [];
            await this.axios.get('/AccesoTipoDocumento/' + codusa)
            .then(response => {
                this.tipo_documento = response.data;
            });
        },
        async setAccesoDocumento(event, codtdo)
        {
            await this.axios.patch('/AccesoTipoDocumento/' + this.usuario.usa_codigo + '/' + codtdo + '?status=' + event.target.checked)
            .then(() => {

            });
        },
        async getReportes(codusa)
        {
            await this.axios.get('/ReportesAcceso/Reportes/' + codusa)
            .then(response => {
                this.reportes = response.data;
            });
        },
        async getReportesUsuario(codusa)
        {
            await this.axios.get('/ReportesAcceso/' + codusa)
            .then(response => {
                this.reportes_usuario = response.data;
            });
        },
        async setReporte()
        {
            var data = {
                arp_codusa: this.usuario.usa_codigo,
                arp_codrep: this.rep_codigo
            };
            await this.axios.post('/ReportesAcceso', data)
            .then(async () => {
                var index = this.reportes.findIndex(x=>x.rep_codigo == this.rep_codigo);
                this.reportes.splice(index, 1);
                this.rep_codigo = 0;
                await this.getReportesUsuario(this.usuario.usa_codigo);
            })
            .catch(error => {
                M.toast({html: error.response.data.message});
            });
        },
        async eliminarReporteAcceso(codrep, codusa)
        {
            await this.axios.delete('/ReportesAcceso/'+ codrep + '/' + codusa)
            .then(async () => {
                await this.getReportes(codusa);
                await this.getReportesUsuario(codusa);
            });
        }
    }
}
</script>
