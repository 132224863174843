<template>
  <div class="row">
    <h5 class="card-panel white-text" style="background-color: #1c21d1;">Tirajes por Usuario</h5>
    <div class="col m12 card-panel">
        <div class="col m3">
            <label>Tipo de Documento</label>
            <select class="browser-default" @change="paginar(1)" v-model="tid_codigo">
                <option value="">Todos</option>
                <option v-for="tipo in tipos_documento" v-bind:key="tipo" v-bind:value="tipo.tid_codigo">{{tipo.tid_descripcion}}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Usuario</label>
            <select class="browser-default" @change="paginar(1)" v-model="usa_codigo">
                <option value="0">Todos</option>
                <option v-for="usuario in usuarios" v-bind:key="usuario" v-bind:value="usuario.usa_codigo">{{usuario.usa_usuario}}</option>
            </select>
        </div>
        <div class="col m3">
            <label>Estado</label>
            <select class="browser-default" @change="paginar(1)" v-model="estado">
                <option value="">Todos</option>
                <option value="A">Activos</option>
                <option value="I">Inactivos</option>
            </select>
        </div>
    </div>
    <div class="col m12 card-panel">
        <a href="#!" title="Nuevo tiraje" @click="nuevo" class="right"><i class="material-icons" style="font-size:25px">add_circle</i></a>
        <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
            <thead>
                <tr>
                    <th>Tipo Documento</th>
                    <th>Resolución</th>
                    <th>Serie</th>
                    <th>Desde</th>
                    <th>Hasta</th>
                    <th>Usuario</th>
                    <th>Estado</th>
                    <th colspan="2">Opciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="tiraje in tirajes_view" v-bind:key="tiraje">
                    <td>{{tiraje.tid_descripcion}}</td>
                    <td class="center">{{tiraje.tit_resolucion}}</td>
                    <td class="center">{{tiraje.tit_serie}}</td>
                    <td class="center">{{tiraje.tit_desde}}</td>
                    <td class="center">{{tiraje.tit_hasta}}</td>
                    <td class="center">{{tiraje.usa_usuario}}</td>
                    <td class="center">{{(tiraje.tit_estado == 'A' ? 'Activo':'Inactivo')}}</td>
                    <td class="center"><a @click="editar(tiraje.tit_codigo)" href="#!"><i class="material-icons">create</i></a></td>
                    <td class="center"><a @click="eliminar(tiraje.tit_codigo)" href="#!"><i class="material-icons">delete</i></a></td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <ul class="pagination">
                <li v-for="page in total_pages" v-bind:key="page" v-bind:class="{active: page == current_page}"><a @click="paginar(page)" href="#!">{{page}}</a></li>
            </ul>
        </div>
    </div>
  </div>

  <div class="modal blue-ugb" id="modal-tirajes">
    <div class="modal-content">
        <div class="row m0 p0">
            <div class="col m12 card-panel">
                <form @submit.prevent="guardar">
                    <div class="row">
                        <div class="col m3">
                            <label>Tipo</label>
                            <select class="browser-default" v-model="tit_tpodoc">
                                <option value="">Seleccione</option>
                                <option v-for="tipo in tipos_documento" v-bind:key="tipo" v-bind:value="tipo.tid_codigo">{{tipo.tid_descripcion}}</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'tit_tpodoc')}}</span>
                        </div>
                        <div class="col m2">
                            <label>Serie</label>
                            <input type="text" v-model="tit_serie">
                            <span class="helper-text">{{$validation(validations, 'tit_serie')}}</span>
                        </div>
                        <div class="col m3">
                            <label>Resolución</label>
                            <input type="text" v-model="tit_resolucion">
                            <span class="helper-text">{{$validation(validations, 'tit_resolucion')}}</span>
                        </div>
                        <div class="col m2">
                            <label>Desde</label>
                            <input type="text" v-model="tit_desde">
                            <span class="helper-text">{{$validation(validations, 'tit_desde')}}</span>
                        </div>
                        <div class="col m2">
                            <label>Hasta</label>
                            <input type="text" v-model="tit_hasta">
                            <span class="helper-text">{{$validation(validations, 'tit_hasta')}}</span>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col m4">
                            <label>Usuario</label>
                            <select class="browser-default" @change="paginar(1)" v-model="tit_codusr">
                                <option value="0">Seleccione</option>
                                <option v-for="usuario in usuarios" v-bind:key="usuario" v-bind:value="usuario.usa_codusr">{{usuario.usa_usuario}}</option>
                            </select>
                            <span class="helper-text">{{$validation(validations, 'tit_codusr')}}</span>
                        </div>
                        <div class="col m4">
                            <label>Estado</label>
                            <select class="browser-default" @change="paginar(1)" v-model="tit_estado">
                                <option value="A">Activo</option>
                                <option value="I">Inactivo</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">                    
                        <div class="col m3">
                            <button type="submit" class="btn blue-ugb">{{(tit_codigo == 0 ? 'GUARDAR':'ACTUALIZAR')}}<i class="material-icons right"> {{tit_codigo == 0 ? 'save':'update'}}</i></button>
                        </div>
                        <div class="col m3">
                            <button type="button" class="btn red darken-4 modal-close">CERRAR <i class="material-icons right">close</i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        name: 'Tirajes',
        data(){
            return {
                tirajes: [],
                tirajes_view: [],
                total_pages: 0,
                current_page: 1,
                records: 15,
                modales: [],
                tit_codigo: 0,

                tid_codigo: '',
                usa_codigo: 0,
                estado: '',

                tipos_documento: [],
                usuarios: [],

                tit_tpodoc: '',
                tit_desde: '',
                tit_hasta: '',
                tit_codusr: 0,
                tit_serie: '',
                tit_estado: 'I',
                tit_resolucion: '',

                validations: []
            }
        },
        async created()
        {
            this.$root.startLoading();
            await this.axios.get('/Tirajes')
            .then(response => {
                this.tirajes = response.data;
                this.tirajes_view = response.data;
                this.paginar(1);
            });
            await this.axios.get('/Usuarios')
            .then(response => {
                this.usuarios = response.data;
            });
            await this.axios.get('/TipoDocumento/Tirajes')
            .then(response => {
                this.tipos_documento = response.data;
            });
            this.$root.stopLoading();
        },
        mounted()
        {
            var elems = document.querySelectorAll('.modal');
            this.modales = M.Modal.init(elems);
        },
        methods: {
            paginar(page)
            {
                this.current_page = page;
                var tirajes = this.tirajes
                                  .filter(x=>x.tid_codigo == (this.tid_codigo == '' ? x.tid_codigo:this.tid_codigo)
                                        && x.usa_codigo == (this.usa_codigo == 0 ? x.usa_codigo:this.usa_codigo)
                                        && x.tit_estado == (this.estado == '' ? x.tit_estado:this.estado));
                this.total_pages = Math.ceil(tirajes.length / this.records);
                var inicio = (this.current_page - 1) * this.records;
                this.tirajes_view = this.tirajes
                                        .filter(x=>x.tid_codigo == (this.tid_codigo == '' ? x.tid_codigo:this.tid_codigo)
                                             && x.usa_codigo == (this.usa_codigo == 0 ? x.usa_codigo:this.usa_codigo)
                                             && x.tit_estado == (this.estado == '' ? x.tit_estado:this.estado))
                                        .slice(inicio, inicio + this.records);
            },
            async editar(codtit)
            {
                this.tit_codigo = codtit;
                if(codtit > 0)
                {
                    this.$root.startLoading();
                    await this.axios.get('/Tirajes/' + codtit)
                    .then(response => {
                        this.tit_tpodoc = response.data.tid_codigo;
                        this.tit_desde = response.data.tit_desde;
                        this.tit_hasta = response.data.tit_hasta;
                        this.tit_codusr = response.data.usa_codusr;
                        this.tit_estado = response.data.tit_estado;
                        this.tit_serie = response.data.tit_serie;
                        this.tit_resolucion = response.data.tit_resolucion;
                    });
                    this.$root.stopLoading();
                }
                var instance = M.Modal.getInstance(document.querySelector('#modal-tirajes'));
                instance.open();
            },
            async guardar()
            {
                this.$root.startLoading();
                this.validations = [];
                var data = {
                    tit_codigo: this.tit_codigo,
                    tit_tpodoc: this.tit_tpodoc,
                    tit_desde: this.tit_desde,
                    tit_hasta: this.tit_hasta,
                    tit_codusr: this.tit_codusr,
                    tit_estado: this.tit_estado,
                    tit_serie: this.tit_serie,
                    tit_resolucion: this.tit_resolucion
                };
                await this.axios.put('/Tirajes', data)
                .then(async response => {
                    this.tit_codigo = response.data.tit_codigo;
                    await this.axios.get('/Tirajes/' + response.data.tit_codigo)
                    .then(response => {
                        var index_tiraje = this.tirajes.findIndex(x=>x.tit_codigo == this.tit_codigo);
                        if(index_tiraje == -1)
                        {
                            this.tirajes.push(response.data);
                            M.toast({html: 'El tiraje ha sido registrado.'});
                        }
                        else
                        {
                            this.tirajes[index_tiraje] = response.data;
                            M.toast({html: 'El tiraje ha sido actualizado.'});
                        }
                        this.paginar(1);
                    });
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'C')
                        M.toast({html: data.message});
                    
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                });
                this.$root.stopLoading();
            },
            nuevo()
            {
                this.tit_codigo = 0;
                this.tit_tpodoc = '';
                this.tit_serie = '';
                this.tit_desde = '';
                this.tit_hasta = '';
                this.tit_codusr = 0;
                this.tit_estado = 'I';
                this.tit_resolucion = '';
                this.validadations = [];
                var instance = M.Modal.getInstance(document.querySelector('#modal-tirajes'));
                instance.open();
            },
            async eliminar(codtit)
            {
                if(!confirm('¿Desea eliminar este tiraje?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Tirajes/' + codtit)
                .then(() => {
                    var index = this.tirajes.findIndex(x=>x.tit_codigo == codtit);
                    this.tirajes.splice(index, 1);
                    this.paginar(1);
                })
                .catch(error => {
                    var data = error.response.data;
                    if(error.response.status == 404)
                    {
                        M.toast({html: 'El tiraje no fue encontrado.'});
                    }
                    if(error.response.status == 400)
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            }
        }
    }
</script>