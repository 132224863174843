<template>
    <div class="row m0 p0">
        <h5 class="card-panel blue-ugb white-text">Exoneraciones</h5>
        <div class="col m12 card-panel">
            <form @submit.prevent="buscar()">
                <div class="col m4">
                    <label>Buscar</label>
                    <input type="text" v-model="txt_search">
                </div>
                <div class="col m2">
                    <button type="submit" class="btn blue-ugb"><i class="material-icons">search</i></button>
                </div>
            </form>
        </div>
    </div>
    <div class="row m0 p0">
        <div class="col m12 card-panel">
            <table class="table tbl-condensed tbl-bordered striped" style="font-size:12px">
                <thead>
                    <tr>
                        <th>Carnet</th>
                        <th>Nombre</th>
                        <th>Carrera</th>
                        <th>Estado</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="estudiante in estudiantes" v-bind:key="estudiante">
                        <td class="center">{{estudiante.per_carnet}}</td>
                        <td>{{estudiante.per_apellidos_nombres}}</td>
                        <td>{{estudiante.car_nombre}}</td>
                        <td class="center">{{estudiante.tie_descripcion}}</td>
                        <td class="center"><a href="#!" @click="openModal(estudiante.per_codigo)"><i class="material-icons">list</i></a></td>
                    </tr>
                </tbody>
            </table>
            <ul class="pagination">
                <li v-if="current_page > 1">
                    <a href="#!" @click="getEstudiantes(1)"><i class="material-icons">first_page</i></a>
                </li>
                <li v-if="current_page > 1">
                    <a href="#!" @click="getEstudiantes(current_page - 1)"><i class="material-icons">chevron_left</i></a>
                </li>
                <template v-for="page in total_pages" v-bind:key="page">
                    <li v-if="(page + 4) > current_page && (page - 4) < current_page" v-bind:class="{active: (page == current_page)}">
                        <a href="#!" @click="getEstudiantes(page)">{{page}}</a>
                    </li>
                </template>
                <li v-if="current_page < total_pages">
                    <a href="#!" @click="getEstudiantes(current_page + 1)"><i class="material-icons">chevron_right</i></a>
                </li>
                <li v-if="current_page < total_pages">
                    <a href="#!" @click="getEstudiantes(total_pages)"><i class="material-icons">last_page</i></a>
                </li>
            </ul>
        </div>
    </div>

    <div class="modal blue-ugb" id="modal-exoneraciones" style="min-width:80%">
        <div class="modal-content">
            <div class="row m0 p0">
                <div class="col m12 card-panel">
                    <div class="col m12 m0 p0">
                        <div class="col m2">
                            <label>Carnet</label>
                            <p>{{per_carnet}}</p>
                        </div>
                        <div class="col m3">
                            <label>Nombre</label>
                            <p>{{per_apellidos_nombres}}</p>
                        </div>
                        <div class="col m6">
                            <label>Carrera</label>
                            <p>{{car_nombre}}</p>
                        </div>
                        <div class="col m1">
                            <label>Estado</label>
                            <p>{{tie_descripcion}}</p>
                        </div>
                    </div>
                   
                    <div class="col m12 m0 p0">
                        <form @submit.prevent="codexo > 0 ? actualizar():guardar()">
                            <div class="col m2">
                                <label>Ciclo</label>
                                <select class="browser-default" v-model="codcil">
                                    <option value="0">Seleccione</option>
                                    <option v-for="ciclo in ciclos" v-bind:key="ciclo" v-bind:value="ciclo.cil_codigo">
                                        {{ciclo.cic_nombre}} - {{ciclo.cil_anio}}
                                    </option>
                                </select>
                                <span class="helper-text">{{$validation(validations, "exo_codcil")}}</span>
                            </div>
                            <div class="col m3">
                                <label>Arancel</label>
                                <select class="browser-default" v-model="codtmo">
                                    <option value="0">Seleccione</option>
                                    <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">
                                        {{arancel.tmo_descripcion}}
                                    </option>
                                </select>
                                <span class="helper-text">{{$validation(validations, "exo_codtmo")}}</span>
                            </div>
                            <div class="col m5">
                                <label>Acuerdo</label>
                                <input type="text" v-model="acuerdo">
                                <span class="helper-text">{{$validation(validations, "exo_acuerdo")}}</span>
                            </div>
                            <div class="col m1">
                                <button type="submit" style="width:100%" class="btn blue-ugb"><i class="material-icons">{{codexo > 0 ? 'update':'save'}}</i></button>
                            </div>
                            <div class="col m1" v-show="codexo > 0">
                                <button type="button" style="width:100%" class="btn red darken-4" @click="reset()"><i class="material-icons">cancel</i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row m0 p0" v-show="exoneraciones.length > 0">
                <div class="col m12 card-panel" style="max-height:210px; overflow:auto">
                    <table class="table tbl-thinner tbl-bordered striped" style="font-size:12px">
                        <thead>
                            <tr>
                                <th>Regional</th>
                                <th>Ciclo</th>
                                <th>Arancel</th>
                                <th>Fecha</th>
                                <th>Acuerdo</th>
                                <th>Usuario</th>
                                <th colspan="2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="exoneracion in exoneraciones" v-bind:key="exoneracion" v-bind:class="{'grey lighten-2': (exoneracion.exo_codigo == codexo)}">
                                <td class="center">{{exoneracion.reg_nombre}}</td>
                                <td class="center">{{exoneracion.cic_nombre}} - {{exoneracion.cil_anio}}</td>
                                <td class="center">{{exoneracion.tmo_descripcion}}</td>
                                <td class="center">{{$dateFormat(new Date(exoneracion.exo_fecha), 'dd/MM/yyyy')}}</td>
                                <td>{{exoneracion.exo_acuerdo}}</td>
                                <td>{{exoneracion.exo_usuario}}</td>
                                <td class="center"><a href="#!" @click="editar(exoneracion.exo_codigo)"><i class="material-icons">edit</i></a></td>
                                <td class="center"><a href="#!" @click="eliminar(exoneracion.exo_codigo)"><i class="material-icons">delete</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="col m12 card-panel">
                    <button type="button" class="btn red darken-4 modal-close">CERRAR <i class="material-icons right">close</i></button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                estudiantes: [],
                total_pages: 0,
                current_page: 1,
                records: 15,
                search: '',
                txt_search: '',
                exoneraciones: [],
                ciclos: [],
                codcil: 0,
                aranceles: [],
                codtmo: 0,
                modales: [],
                modal_exoneracion: {},
                fecha: '',
                codper: 0,
                acuerdo: '',
                validations: [],
                codexo: 0,

                per_carnet: '',
                per_apellidos_nombres: '',
                car_nombre: '',
                tie_descripcion: ''
            }
        },
        async mounted()
        {
            await this.getEstudiantes();
            this.modales = M.Modal.init(document.querySelector('.modal'));
        },
        methods: {
            async getEstudiantes(page)
            {
                this.$root.startLoading();
                await this.axios.get('/Estudiantes?page=' + (page || this.current_page) + '&records=' + this.records + '&search=' + this.search)
                .then(response => {
                    this.estudiantes = response.data.records;
                    this.total_pages = response.data.total_pages;
                    this.current_page = response.data.current_page;
                });
                this.$root.stopLoading();
            },
            async buscar()
            {
                this.search = this.txt_search;
                await this.getEstudiantes(1);
            },
            async getExoneraciones(codper)
            {
                await this.axios.get('/Exoneraciones/Estudiante/' + codper)
                .then(response => {
                    this.exoneraciones = response.data;
                });
            },
            async getCiclos()
            {
                if(this.ciclos.length == 0)
                {
                    await this.axios.get('/Ciclos')
                    .then(response => {
                        this.ciclos = response.data;
                    });
                }
            },
            async getAranceles()
            {
                if(this.aranceles.length == 0)
                {
                    await this.axios.get('/Aranceles/Facturacion')
                    .then(response => {
                        this.aranceles = response.data;
                    });
                }
            },
            async openModal(codper)
            {
                this.$root.startLoading();
                await this.getCiclos();
                await this.getAranceles();
                await this.getExoneraciones(codper);
                this.codper = codper;
                var estudiante = this.estudiantes.find(x=>x.per_codigo == codper);
                this.per_carnet = estudiante.per_carnet;
                this.per_apellidos_nombres = estudiante.per_apellidos_nombres;
                this.car_nombre = estudiante.car_nombre;
                this.tie_descripcion = estudiante.tie_descripcion;
                this.modal_exoneracion = M.Modal.getInstance(document.querySelector('#modal-exoneraciones'));
                this.modal_exoneracion.open();
                this.$root.stopLoading();
            },
            async guardar()
            {
                var data = {
                    exo_codigo: this.codexo,
                    exo_codper: this.codper,
                    exo_tipo: 'E',
                    exo_codcil: this.codcil,
                    exo_codtmo: this.codtmo,
                    exo_acuerdo: this.acuerdo,
                    exo_mes: 0,
                    exo_estado: 'R',
                    exo_usuario: ''
                };
                this.$root.startLoading();
                await this.axios.post('/Exoneraciones', data)
                .then(async response => {
                    await this.getExoneracion(response.data.exo_codigo);
                    this.reset();
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async actualizar()
            {
                var data = {
                    exo_codigo: this.codexo,
                    exo_codper: this.codper,
                    exo_tipo: 'E',
                    exo_codcil: this.codcil,
                    exo_codtmo: this.codtmo,
                    exo_acuerdo: this.acuerdo,
                    exo_mes: 0,
                    exo_estado: 'R',
                    exo_usuario: ''
                };
                this.$root.startLoading();
                await this.axios.put('/Exoneraciones/' + this.codexo, data)
                .then(async () => {
                    await this.getExoneracion(this.codexo);
                    this.reset();
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async getExoneracion(codexo)
            {
                this.$root.startLoading();
                await this.axios.get('/Exoneraciones/' + codexo)
                .then(response => {
                    var index = this.exoneraciones.findIndex(x=>x.exo_codigo == codexo);
                    if(index != -1)
                    {
                        this.exoneraciones[index] = response.data;
                    }
                    else
                    {
                        this.exoneraciones.unshift(response.data);
                    }
                });
                this.$root.stopLoading();
            },
            async eliminar(codexo)
            {
                if(!confirm('¿Desea eliminar esta exoneración?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Exoneraciones/' + codexo)
                .then(() => {
                    var index = this.exoneraciones.findIndex(x=>x.exo_codigo == codexo);
                    if(index != -1)
                    {
                        this.exoneraciones.splice(index, 1);
                    }
                });
                this.$root.stopLoading();
            },
            editar(codexo)
            {
                var result = this.exoneraciones.find(x=>x.exo_codigo == codexo);
                this.codexo = result.exo_codigo;
                this.codcil = result.exo_codcil;
                this.codtmo = result.exo_codtmo;
                this.acuerdo = result.exo_acuerdo;
            },
            reset()
            {
                this.codexo = 0;
                this.codcil = 0;
                this.codtmo = 0;
                this.acuerdo = '';
            }
        }
    }
</script>