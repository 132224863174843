<template>
<div class="row text-center" style="padding: 30px">
    <div class="form-signin" style="padding: 20px; background-color: #1c21d1;">
        <form v-on:submit.prevent="login" id="Login" method="post" style="padding: 0px;" autocomplete="false">         
            <div class="row" style="text-align:center">
                <img src="https://repositorio.ugb.edu.sv/resources/img/ugb/logos_nuevos/UGB_300x100_blanco.png">
            </div>
            <br>
            <div class="row">
                <div class="input-field col m12 s12">
                    <input class="white-text" v-model="usuario" type="text" placeholder="Usuario" :disabled="is_login" />
                    <span style="color:white">{{$validation(validations, 'Usuario')}}</span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col m12 s12">
                    <input class="white-text" v-model="clave" type="password" placeholder="Contraseña" :disabled="is_login" />
                    <span style="color:white">{{$validation(validations, 'Clave')}}</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col m12 s12">
                    <select v-model="codreg" v-on:change="getCiclos" v-bind:disabled="is_login" class="browser-default">
                        <option value="0">Seleccione Regional</option>
                        <option v-for="regional in regionales" v-bind:value="regional.reg_codigo" v-bind:key="regional">{{regional.reg_nombre}}</option>
                    </select>
                </div>
            </div>
            <br>
            <br>
            <div class="row" v-show="!is_login">
                <button type="submit" v-bind:disabled="(is_login || codreg == 0)" class="btn white black-text waves-effect s12 m12" id="btn-login" style="width: 100%; border-radius:50px">Iniciar Sesión <i class="material-icons right">security</i></button>
            </div>
            <div class="row" id="preloader-container" v-show="is_login">
                <div class="progress blue-ugb">
                    <div class="indeterminate blue lighten-4"></div>
                </div>
            </div>
        </form>
    </div>
    <div class="clearfix"></div>
</div>
</template>

<script>
    import M from 'materialize-css'
    export default
    {
        data(){
           return {
                usuario: '',
                clave: '',
                codreg: 0,
                regionales: [],
                is_login: false,
                validations: []
           }
        },
        async mounted()
        {
            this.$root.startLoading();
            await this.getRegionales();
            this.$root.stopLoading();
        },
        methods: {
            async getRegionales()
            {
                await this.axios.get('/Regionales')
                .then(response => {
                    this.regionales = response.data;
                });
            },           
            async login()
            {
                this.is_login = true;
                var payload = {
                    usuario: this.usuario,
                    clave: this.clave,
                    regional: this.codreg
                };
                await this.axios.post('/Login', payload)
                .then(response => {                            
                    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;                    
                    localStorage.setItem('token', response.data.token);
                    this.$store.commit('setAuthenticated', true);
                    this.$store.commit('setUserData', response.data.userData);
                    this.$store.commit('setMenus', response.data.menus);
                    this.$store.commit('setRoles', response.data.roles);
                    this.$router.push('/');
                    window.location = '/';
                })
                .catch(error => {
                    this.validations = error.response.data.modelErrors;
                    M.toast({html: 'Credenciales incorrectas.'});
                    this.is_login = false;
                });
            }
        }
    }
</script>

<style scoped>
    html,
    body {
        height: 100%;
    }

    body {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-align: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5
    }

    .form-signin {
        width: 100%;
        max-width: 350px;
        padding: 15px;
        margin: 0 auto;
    }

        .form-signin .form-control {
            position: relative;
            box-sizing: border-box;
            height: auto;
            padding: 10px;
            font-size: 16px;
        }

            .form-signin .form-control:focus {
                z-index: 2;
            }

    input[type=text], input[type=password], select, select option {
        padding: 5px !important;
        margin: 0px !important;
    }

    .row
    {
        padding:0px; margin:0px
    }

    .blue-ugb
    {
        background-color: #011B40 !important
    }

    .gray-ugb
    {
        background-color: #707070 !important
    }
    
    .red-ugb
    {
        background-color: #FF0000 !important
    }

    .light-gray-ugb
    {
        background-color: #F0F0F0 !important
    }
</style>