<template>
    <div class="row">
        <div class="col m12 card-panel">
            <form @submit.prevent="generar()">
                <div class="col m2">
                    <label>Fecha</label>
                    <InputMask v-model="inicio" mask="99/99/9999" />
                    <span class="helper-text">{{$validation(validations, "Inicio")}}</span>
                </div>
                <!-- <div class="col m2">
                    <label>Fecha Final</label>
                    <InputMask v-model="fin" mask="99/99/9999" />
                    <span class="helper-text">{{$validation(validations, "Fin")}}</span>
                </div> -->
                <div class="col m2">
                    <label>Serie</label>
                    <input type="text" v-model="numero_serie">
                    <span class="helper-text">{{$validation(validations, "Serie")}}</span>
                </div>
                <div class="col m2">
                    <label>Factura</label>
                    <input type="text" v-model="numero_factura">
                    <span class="helper-text">{{$validation(validations, "NumeroFactura")}}</span>
                </div>
                <div class="col m2">
                    <button type="submit" class="btn blue-ugb"><i class="material-icons left">low_priority</i> GENERAR</button>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col m12 card-panel">
            <table class="table tbl-condensed stripped tbl-bordered" style="font-size:12px" v-show="facturas.length">
                <thead>
                    <tr>
                        <th style="width:10%">Serie</th>
                        <th style="width:10%">Factura</th>
                        <th style="width:10%">Fecha de Emisión</th>
                        <th style="width:10%">Fecha de Registro</th>
                        <th style="width:10%">Estado</th>
                        <th style="width:10%">Impreso</th>
                        <th style="width:10%">Exportado</th>
                        <th style="width:10%">Creado Por</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="factura in facturas" v-bind:key="factura">
                        <td class="center">{{factura.fac_serie}}</td>
                        <td class="center"><a href="#!" @click="modalFactura(factura.fac_codigo)">{{factura.fac_factura}}</a></td>
                        <td class="center">{{factura.fac_fecha}}</td>
                        <td class="center">{{factura.fac_fecha_registro}}</td>
                        <td>
                            <select v-model="factura.fac_estado" class="browser-default" disabled>
                                <option value="A">Anulado</option>
                                <option value="G">Generado</option>
                                <option value="R">Registrado</option>
                                <option value="I">Inactivo</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="factura.fac_impreso" class="browser-default" disabled>
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="factura.fac_exportado_sap" class="browser-default" disabled>
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </td>
                        <td class="center">{{factura.fac_usuario}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" id="modal-factura-diaria" style="min-width:85%; max-height:575px; top:5%; border: solid 1px white">
        <div class="modal-content blue-ugb">
            <div class="row m0 p0">
                <div class="col m12 card-panel">
                    <form @submit.prevent="actualizarFactura()">
                        <div class="row">
                            <div class="col m2">
                                <label>Serie</label>
                                <input type="text" v-model="factura.fac_serie" :disabled="factura.fac_exportado_sap == 'S'">
                                <span class="helper-text">{{$validation(validations, "fac_serie")}}</span>
                            </div>
                            <div class="col m2">
                                <label>Factura</label>
                                <input type="text" v-model="factura.fac_factura" :disabled="factura.fac_exportado_sap == 'S'">
                                <span class="helper-text">{{$validation(validations, "fac_factura")}}</span>
                            </div>
                            <div class="col m2">
                                <label>Fecha</label>
                                <input type="text" v-model="factura.fac_fecha" :disabled="factura.fac_exportado_sap == 'S'">
                                <span class="helper-text">{{$validation(validations, "fac_fecha")}}</span>
                            </div>
                            <div class="col m2">
                                <label>Estado</label>
                                <select v-model="factura.fac_estado" class="browser-default" disabled>
                                    <option value="A">Anulado</option>
                                    <option value="G">Generado</option>
                                    <option value="R">Registrado</option>
                                    <option value="I">Inactivo</option>
                                </select>
                            </div>
                            <div class="col m2">
                                <label>Impreso</label>
                                <select v-model="factura.fac_impreso" class="browser-default" disabled>
                                    <option value="S">Si</option>
                                    <option value="N">No</option>
                                </select>
                            </div>
                            <div class="col m2">
                                <label>Exportado</label>
                                <select v-model="factura.fac_exportado_sap" class="browser-default" disabled>
                                    <option value="S">Si</option>
                                    <option value="N">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">                            
                            <div class="col m2">
                                <label>Fecha de Registro</label>
                                <input type="text" v-model="factura.fac_fecha_registro" disabled>
                            </div>
                            <div class="col m2">
                                <label>Usuario</label>
                                <input type="text" v-model="factura.fac_usuario" disabled>
                            </div>
                            <div class="col m2">
                                <button type="submit" class="btn blue-ugb" style="width:100%" :disabled="factura.fac_exportado_sap == 'S'">ACTUALIZAR <i class="material-icons right">update</i></button>
                            </div>
                            <div class="col m2">
                                <button type="button" class="btn red darken-4 modal-close" style="width:100%" :disabled="factura.fac_exportado_sap == 'S'">CERRAR <i class="material-icons right">close</i></button>
                            </div>
                            <div class="col m2">
                                <button type="button" @click="imprimir()" class="btn teal darken-2" style="width:100%" :disabled="factura.fac_impreso == 'S'">IMPRIMIR <i class="material-icons right">print</i></button>
                                <p class="p0 m0">
                                    <label class="p0 m0">
                                        <input name="group1" class="with-gap" type="radio" checked value="0" v-model="factura_version" />
                                        <span>V1</span>
                                    </label>
                                    <label class="p0 m0">
                                        <input name="group1" class="with-gap" type="radio" value="1" v-model="factura_version" />
                                        <span>V2</span>
                                    </label>
                                </p>
                            </div>
                            <div class="col m2" style="display:none">
                                <button type="button" class="btn orange darken-5" style="width:100%" :disabled="factura.fac_estado == 'R'" @click="anular()">ANULAR <i class="material-icons right">close</i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row m0 p0">
                <div class="col m12 card-panel" style="max-height:325px; overflow:auto">
                    <table class="table tbl-condensed striped" style="font-size:12px">
                        <thead>
                            <tr>
                                <th style="width:30%">Facultad</th>
                                <th style="width:30%">Carrera</th>
                                <th style="width:20%">Arancel</th>
                                <th style="width:6%">IVA</th>
                                <th style="width:6%">Valor</th>
                                <th style="width:8%">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detalle in factura.col_dfa_det_fac" v-bind:key="detalle">
                                <td>{{detalle.fac_nombre}}</td>
                                <td>{{detalle.car_nombre}}</td>
                                <td>{{detalle.tmo_descripcion}}</td>
                                <td style="text-align:right">${{detalle.dfa_iva.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td style="text-align:right">${{detalle.dfa_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                                <td style="text-align:right">${{(detalle.dfa_iva + detalle.dfa_valor).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="3" class="center">TOTAL</th>
                                <th style="text-align:right">${{dfa_iva.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                <th style="text-align:right">${{dfa_valor.toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                                <th style="text-align:right">${{(dfa_iva + dfa_valor).toLocaleString('en-US', { minimumFractionDigits: 2 })}}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import InputMask from 'primevue/inputmask'
    import M from 'materialize-css'
    export default {
        components:{
            InputMask
        },
        async mounted()
        {
            await this.getFacturas();
            this.modales = M.Modal.init(document.querySelectorAll('.modal'), null);
        },
        data(){
            return {
                facturas: [],
                factura: {},
                inicio: '',
                fin: '',
                numero_factura: '',
                numero_serie: '',
                validations: [],
                modales: [],
                modal_factura_diaria: {},
                factura_version: 0,
            }
        },
        methods: {
            async generar()
            {
                if(!confirm('¿Desea generar la factura #' + this.numero_factura + ' con fecha desde ' + this.inicio + ' hasta ' + this.fin + '?')) return false;
                var data = {
                    inicio: this.inicio,
                    //fin: this.inicio,
                    numeroFactura: this.numero_factura,
                    serie: this.numero_serie
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/FacturaDiaria', data)
                .then(async response => {
                    await this.getFactura(response.data.fac_codigo);
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async getFactura(id)
            {
                this.$root.startLoading();
                await this.axios.get('/FacturaDiaria/' + id)
                .then(response => {
                    var index = this.facturas.findIndex(x=>x.fac_codigo == id);
                    if(index != -1)
                    {
                        this.facturas[index] = response.data;
                    }
                    else
                    {
                        this.facturas.unshift(response.data);
                    }
                    this.factura = response.data;
                })
                .catch(() => {
                    M.toast({html: 'Registro no encontrado.'});
                });
                this.$root.stopLoading();
            },
            async getFacturas()
            {
                this.$root.startLoading();
                await this.axios.get('/FacturaDiaria')
                .then(response => {
                    this.facturas = response.data;
                });
                this.$root.stopLoading();
            },
            async modalFactura(id)
            {
                await this.getFactura(id);
                this.modal_factura_diaria = M.Modal.getInstance(document.querySelector('#modal-factura-diaria'));
                this.modal_factura_diaria.open();
            },
            async actualizarFactura()
            {
                if(!confirm('¿Desea actualizar los datos?')) return false;
                var data = {
                    fac_codigo: this.factura.fac_codigo,
                    fac_factura: this.factura.fac_factura,
                    fac_fecha: this.factura.fac_fecha,
                    fac_serie: this.factura.fac_serie
                };

                this.$root.startLoading();
                await this.axios.patch('/FacturaDiaria/' + this.factura.fac_codigo, data)
                .then(() => {
                    var index = this.facturas.findIndex(x=>x.fac_codigo == this.factura.fac_codigo);
                    if(index != -1)
                    {
                        this.facturas[index].fac_factura = this.factura.fac_factura;
                        this.facturas[index].fac_fecha = this.factura.fac_fecha;
                        this.facturas[index].fac_serie = this.factura.fac_serie;
                    }
                    M.toast({html: 'El documento ha sido actualizado.'});
                }).catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async imprimir()
            {
                this.$root.startLoading();
                var version = this.factura_version;
                await this.axios.get('/Reportes/FacturaCliente/' + this.factura.fac_codigo + '/' + version, {
                    responseType: 'blob'
                })
                .then(async response => {
                    var data_blob = response.data;
                    await this.axios.patch('/FacturaDiaria/Impreso/?codfac=' + this.factura.fac_codigo)
                    .then(() => {
                        const blob = new Blob([data_blob], {type: 'application/pdf'});
                        const objectURL = URL.createObjectURL(blob);
                        window.open(objectURL, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                        /* var index = this.facturas.findIndex(x=>x.fac_codigo == this.factura.fac_codigo);
                        if(index != -1)
                        {
                            this.facturas[index].fac_impreso = 'S';
                        }    */           
                    });                    
                });
                this.$root.stopLoading();
            },
            async anular()
            {
                if(!confirm('¿Desea anular esta factura?\nUna vez anulada no podrá revertir el cambio.')) return false;
                await this.axios.patch('/FacturDiaria/'+this.factura.fac_codigo+'/Anular')
                .then(() => {
                    this.factura.fac_estado = 'A';
                    var index = this.facturas.findIndex(x=>x.fac_codigo == this.factura.fac_codigo);
                    this.facturas[index].fac_estado = 'A';
                });
            }
        },
        computed: {
            dfa_iva()
            {
                if(this.factura.col_dfa_det_fac == undefined) return false;
                return this.factura.col_dfa_det_fac.reduce((a, b) => a + b.dfa_iva, 0);
            },
            dfa_valor()
            {
                if(this.factura.col_dfa_det_fac == undefined) return false;
                return this.factura.col_dfa_det_fac.reduce((a, b) => a + b.dfa_valor, 0);
            }
        }
    }
</script>

<style scoped>
    select:disabled
    {
        color:black
    }
</style>