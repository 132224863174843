<template>
    <div class="row">
        <div class="col m8">
            <table class="table tbl-condensed tbl-bordered" style="font-size: 12px;">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Modalidad</th>
                        <th>Cant. Mat.</th>
                        <th>Cuota</th>
                        <th>Matrícula</th>
                        <th>Tipo Carrera</th>
                        <th>Año Inicio</th>
                        <th>Año Final</th>
                        <th>Activo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(valor, index) in valores" :key="valor" v-bind:class="{'grey lighten-3': index == index_valor}">
                        <td class="center"><a href="#!" @click="getDetalle(valor.vam_codigo, index)">{{ valor.vam_codigo }}</a></td>
                        <td>
                            <select class="browser-default" v-model="valor.vam_modalidad" disabled style="color:black">
                                <option value="V">Virtual</option>
                                <option value="P">Presencial</option>
                            </select>
                        </td>
                        <td class="center">{{ valor.vam_cantidad_materias }}</td>
                        <td class="center">${{ valor.vam_valor }}</td>
                        <td class="center">${{ valor.vam_matricula }}</td>
                        <td>
                            <select class="browser-default" v-model="valor.vam_tipo_carrera" disabled style="color:black">
                                <option value="N">Normal</option>
                                <option value="E">Enfermería</option>
                                <option value="D">Doctorado</option>
                            </select>
                        </td>
                        <td class="center">{{ valor.vam_anio }}</td>
                        <td class="center">{{ valor.vam_anio_fin }}</td>
                        <td class="center"><label><input type="checkbox" :checked="valor.vam_activo" @click.prevent="" class="filled-in"><span></span></label></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col m4" v-show="index_valor != -1">
            <div class="row">
                <form method="post" v-on:submit.prevent="setDetalle()">
                    <div class="col m6">
                        <label>Facultad</label>
                        <select class="browser-default" v-model="detalle.dvm_codfac">
                            <option value="0">Seleccione</option>
                            <option v-for="facultad in facultades_filter" :key="facultad.fac_codigo" :value="facultad.fac_codigo">{{ facultad.fac_nombre }}</option>
                        </select>
                    </div>
                    <div class="col m3">
                        <label>Valor</label>
                        <input type="text" v-model="detalle.dvm_valor"/>
                    </div>
                    <div class="col m3">
                        <button class="btn blue-ugb">{{ index_detalle != -1 ? 'Actualizar':'Agregar' }}</button>
                    </div>
                </form>
            </div>
            <div class="row">
                <table class="table tbl-condensed tbl-bordered" style="font-size: 12px">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Facultad</th>
                            <th>Valor</th>
                            <th>Activo</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detalle, index) in detalles" :key="detalle" v-bind:class="{'grey lighten-3': index == index_detalle}">
                            <td class="center">{{ detalle.dvm_codigo }}</td>
                            <td>{{ detalle.fac_nombre }}</td>
                            <td class="center">{{ detalle.dvm_valor }}</td>
                            <td class="center"><label><input type="checkbox" :checked="detalle.dvm_activo" @click.prevent="" class="filled-in"><span></span></label></td>
                            <td class="center"><a href="#!" @click="editarDetalle(detalle.dvm_codigo)"><i class="material-icons">edit</i></a></td>
                            <td class="center"><a href="#!" @click="eliminarDetalle(detalle.dvm_codigo)"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data()
        {
            return {
                valores: [],
                detalles: [],
                detalle: {},
                index_valor: -1,
                index_detalle: -1,
                facultades: []
            }
        },
        async mounted()
        {
            await this.getValores();
            await this.getFacultades();
        },
        methods: {
            async getValores()
            {
                await this.axios.get('/ValorCuota')
                .then(response => {
                    this.valores = response.data.filter(x=>x.vam_activo);
                });
            },
            async getDetalle(codvam, index)
            {
                this.index_valor = index;
                this.detalle.dvm_codvam = codvam;
                this.detalle.dvm_codfac = 0;
                this.detalle.dvm_codigo = 0;
                this.index_detalle = -1;
                await this.axios.get('/ValorCuota/' + codvam)
                .then(response => {
                    this.detalles = response.data;
                });
            },
            async getFacultades()
            {
                await this.axios.get('/Facultades')
                .then(response => {
                    this.facultades = response.data;
                });
            },
            async setDetalle()
            {
                await this.axios.post('/ValorCuota/' + this.detalle.dvm_codvam, this.detalle)
                .then(async () => {
                    await this.getDetalle(this.detalle.dvm_codvam, this.index_valor);
                })
                .catch(error => {
                    if(error.response.data.type == 'M')
                    {
                        M.toast({html: 'Complete los campos.'});
                    }
                    else
                    {
                        M.toast({html: error.response.data.message});
                    }
                });
            },
            editarDetalle(coddvm)
            {
                var index = this.detalles.findIndex(x=>x.dvm_codigo == coddvm);
                this.detalle = this.detalles[index];
                this.index_detalle = index;
            },
            async eliminarDetalle(coddvm)
            {
                if(!confirm('¿Desea eliminar este detalle?')) return false;
                await this.axios.delete('/ValorCuota/' + this.detalle.dvm_codvam + '/' + coddvm)
                .then(async () => {
                    await this.getDetalle(this.valores[this.index_valor].vam_codigo, this.index_valor);
                });
            }
        },
        computed: {
            facultades_filter()
            {
                return this.facultades.filter(x=>this.detalles.findIndex(y=>y.dvm_codfac == x.fac_codigo) == -1);
            }
        }
    }
</script>