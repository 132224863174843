<template>
<div class="row m0 p0">
        <div class="col m12">
            <div class="row">
                <div class="col m12">
                    <form @submit.prevent="codexo > 0 ? actualizar():guardar()">
                        <div class="col m2">
                            <label>Ciclo</label>
                            <select class="browser-default" v-model="codcil">
                                <option value="0">Seleccione</option>
                                <option v-for="ciclo in ciclos" v-bind:key="ciclo" v-bind:value="ciclo.cil_codigo">
                                    {{ciclo.cic_nombre}} - {{ciclo.cil_anio}}
                                </option>
                            </select>
                            <span class="helper-text">{{$validation(validations, "exo_codcil")}}</span>
                        </div>
                        <div class="col m3">
                            <label>Arancel</label>
                            <select class="browser-default" v-model="codtmo">
                                <option value="0">Seleccione</option>
                                <option v-for="arancel in aranceles" v-bind:key="arancel" v-bind:value="arancel.tmo_codigo">
                                    {{arancel.tmo_descripcion}}
                                </option>
                            </select>
                            <span class="helper-text">{{$validation(validations, "exo_codtmo")}}</span>
                        </div>
                        <div class="col m5">
                            <label>Acuerdo</label>
                            <input type="text" v-model="acuerdo">
                            <span class="helper-text">{{$validation(validations, "exo_acuerdo")}}</span>
                        </div>
                        <div class="col m1">
                            <button type="submit" style="width:100%" class="btn blue-ugb"><i class="material-icons">{{codexo > 0 ? 'update':'save'}}</i></button>
                        </div>
                        <div class="col m1" v-show="codexo > 0">
                            <button type="button" style="width:100%" class="btn red darken-4" @click="reset()"><i class="material-icons">cancel</i></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <table class="table striped" style="font-size:12px" v-show="exoneraciones.length > 0">
                        <tbody>
                             <tr>
                                <th class="center">Regional</th>
                                <th class="center">Ciclo</th>
                                <th class="center">Arancel</th>
                                <th class="center">Fecha</th>
                                <th class="center">Acuerdo</th>
                                <th class="center">Usuario</th>
                                <th colspan="2"></th>
                            </tr>
                            <tr v-for="exoneracion in exoneraciones" v-bind:key="exoneracion" v-bind:class="{'grey lighten-2': (exoneracion.exo_codigo == codexo)}">
                                <td class="center">{{exoneracion.reg_nombre}}</td>
                                <td class="center">{{exoneracion.cic_nombre}} - {{exoneracion.cil_anio}}</td>
                                <td class="center">{{exoneracion.tmo_descripcion}}</td>
                                <td class="center">{{$dateFormat(new Date(exoneracion.exo_fecha), 'dd/MM/yyyy')}}</td>
                                <td>{{exoneracion.exo_acuerdo}}</td>
                                <td>{{exoneracion.exo_usuario}}</td>
                                <td class="center"><a href="#!" @click="editar(exoneracion.exo_codigo)"><i class="material-icons">edit</i></a></td>
                                <td class="center"><a href="#!" @click="eliminar(exoneracion.exo_codigo)"><i class="material-icons">delete</i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
    export default {
        data(){
            return {
                exoneraciones: [],
                ciclos: [],
                codcil: 0,
                aranceles: [],
                codtmo: 0,                
                fecha: '',
                acuerdo: '',
                validations: [],
                codexo: 0,
                estudiante: {}
            }
        },
        methods: {
            async init(estudiante)
            {
                this.$root.startLoading();
                await this.getCiclos();
                await this.getAranceles();
                await this.getExoneraciones(estudiante.per_codigo);
                this.$root.stopLoading();
                this.estudiante = estudiante;              
            },
            async getExoneraciones(codper)
            {
                if(this.exoneraciones.length == 0)
                {
                    await this.axios.get('/Exoneraciones/Estudiante/' + codper)
                    .then(response => {
                        this.exoneraciones = response.data;
                    });
                }
            },
            async getCiclos()
            {
                if(this.ciclos.length == 0)
                {
                    await this.axios.get('/Ciclos')
                    .then(response => {
                        this.ciclos = response.data;
                    });
                }
            },
            async getAranceles()
            {
                if(this.aranceles.length == 0)
                {
                    await this.axios.get('/Aranceles/Facturacion')
                    .then(response => {
                        this.aranceles = response.data;
                    });
                }
            },
            async guardar()
            {
                var data = {
                    exo_codigo: this.codexo,
                    exo_codper: this.estudiante.per_codigo,
                    exo_tipo: 'E',
                    exo_codcil: this.codcil,
                    exo_codtmo: this.codtmo,
                    exo_acuerdo: this.acuerdo,
                    exo_mes: 0,
                    exo_estado: 'R',
                    exo_usuario: ''
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.post('/Exoneraciones', data)
                .then(async response => {
                    await this.getExoneracion(response.data.exo_codigo);
                    this.reset();
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async actualizar()
            {
                var data = {
                    exo_codigo: this.codexo,
                    exo_codper: this.estudiante.per_codigo,
                    exo_tipo: 'E',
                    exo_codcil: this.codcil,
                    exo_codtmo: this.codtmo,
                    exo_acuerdo: this.acuerdo,
                    exo_mes: 0,
                    exo_estado: 'R',
                    exo_usuario: ''
                };
                this.validations = [];
                this.$root.startLoading();
                await this.axios.put('/Exoneraciones/' + this.codexo, data)
                .then(async () => {
                    await this.getExoneracion(this.codexo);
                    this.reset();
                })
                .catch(error => {
                    var data = error.response.data;
                    if(data.type == 'M')
                    {
                        this.validations = data.modelErrors;
                    }
                    if(data.type == 'C')
                    {
                        M.toast({html: data.message});
                    }
                });
                this.$root.stopLoading();
            },
            async getExoneracion(codexo)
            {
                this.$root.startLoading();
                await this.axios.get('/Exoneraciones/' + codexo)
                .then(response => {
                    var index = this.exoneraciones.findIndex(x=>x.exo_codigo == codexo);
                    if(index != -1)
                    {
                        this.exoneraciones[index] = response.data;
                    }
                    else
                    {
                        this.exoneraciones.unshift(response.data);
                    }
                });
                this.$root.stopLoading();
            },
            async eliminar(codexo)
            {
                if(!confirm('¿Desea eliminar esta exoneración?')) return false;
                this.$root.startLoading();
                await this.axios.delete('/Exoneraciones/' + codexo)
                .then(() => {
                    var index = this.exoneraciones.findIndex(x=>x.exo_codigo == codexo);
                    if(index != -1)
                    {
                        this.exoneraciones.splice(index, 1);
                    }
                });
                this.$root.stopLoading();
            },
            editar(codexo)
            {
                var result = this.exoneraciones.find(x=>x.exo_codigo == codexo);
                this.codexo = result.exo_codigo;
                this.codcil = result.exo_codcil;
                this.codtmo = result.exo_codtmo;
                this.acuerdo = result.exo_acuerdo;
            },
            reset()
            {
                this.codexo = 0;
                this.codcil = 0;
                this.codtmo = 0;
                this.acuerdo = '';
                this.validations = [];
            }
        }
    }
</script>